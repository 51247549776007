import React, {useCallback, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../redux/reduxHooks";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Loading from "../LoadingComponent";

import StandardPicker, {getTags, StandardPickerState} from "../standards/StandardPickerComponent";
import {Grid} from "@material-ui/core";
import Error from "../ErrorComponent";
import {searchQuestions, selectSearchQuestions} from "../../redux/questionSearchSlice";
import {MemoizedQuestionCards} from "./QuestionCardsComponent";
import {useTranslationLabel} from "../../hooks/useTranslation";

interface SearchParameters {
    keywords: string;
    tags: string[];
}

const QuestionsSearch: React.FC = () => {
    const isLoading = useAppSelector((state) => state.questionsSearch.isLoading);
    const errorMessage = useAppSelector((state) => state.questionsSearch.errorMessage);
    const questions = useAppSelector(selectSearchQuestions);
    const dispatch = useAppDispatch();
    const getLabel = useTranslationLabel();
    const [keywords, setKeywords] = useState('');
    const [pickerState, setPickerState] = useState<StandardPickerState | undefined>(undefined);
    const [lastSearch, setLastSearch] = useState<SearchParameters | undefined>(undefined);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (pickerState) {
            triggerQuestionSearch(pickerState, keywords);
        }
    };

    const onChangedStandardPicker = (state: StandardPickerState) => {
        setPickerState(state);
        triggerQuestionSearch(state, keywords);
    };

    const triggerQuestionSearch = (pickerState: StandardPickerState, keywords: string) => {
        const tags = getTags(pickerState);
        if (lastSearch?.keywords !== keywords || JSON.stringify(lastSearch?.tags) !== JSON.stringify(tags)) {
            setLastSearch({keywords: keywords, tags: tags});
            dispatch(searchQuestions(keywords, tags));
        }
    };

    const RenderQuestions: React.FC = useCallback(() => {
        if (isLoading) {
            return <Loading rdn="ui.loading_questions" fullHeight={false}/>
        } else if (errorMessage) {
            return <Error text={errorMessage}/>
        } else {
            return <MemoizedQuestionCards questions={questions} pickerState={pickerState}/>
        }
    }, [questions, pickerState, errorMessage, isLoading]);

    const onChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeywords(event.target.value)
    };

    return (<div>
        <Container component="main" maxWidth="xs">
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="keyword"
                            label={getLabel('ui.search_by_keyword')}
                            name="keyword"
                            autoComplete="keyword"
                            autoFocus
                            value={keywords}
                            onChange={onChangeKeyword}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            {getLabel('ui.search_questions')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <StandardPicker
                onChange={onChangedStandardPicker}
                onlyStandardsWithQuestions={true}
                hasSessionContext={true}
            />
        </Container>

        <RenderQuestions/>
    </div>)
};

export default QuestionsSearch;
