import {Constraint} from "../data/constraints/Constraints";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export interface ConstraintState {
    /**
     * Maps datapoint rdns to constraints
     */
    constraints: Map<string, Constraint>;
}

const initialState: ConstraintState = {
    constraints: new Map<string, Constraint>()
};

export const constraintSlice = createSlice({
    name: 'constraint',
    initialState,
    reducers: {
        setConstraints: (state, action: PayloadAction<Map<string, Constraint>>) => {
            state.constraints = action.payload;
        }
    }
});

export const {setConstraints} = constraintSlice.actions;
export const selectConstraints = (state: RootState) => state.constraints.constraints;

export default constraintSlice.reducer;
