export const paMap = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "name": "Intermediate Unit 1",
        "teachers": 3381.8
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -80.518955,
              40.477793
            ],
            [
              -80.360394,
              40.477507
            ],
            [
              -80.182878,
              40.332751
            ],
            [
              -79.914524,
              40.25205
            ],
            [
              -79.937372,
              40.241587
            ],
            [
              -79.962348,
              40.238849
            ],
            [
              -79.969696,
              40.224861
            ],
            [
              -79.954791,
              40.212871
            ],
            [
              -79.929115,
              40.207167
            ],
            [
              -79.894577,
              40.194353
            ],
            [
              -79.871478,
              40.197368
            ],
            [
              -79.854014,
              40.190939
            ],
            [
              -79.852373,
              40.169532
            ],
            [
              -79.861172,
              40.162455
            ],
            [
              -79.894249,
              40.164993
            ],
            [
              -79.904968,
              40.156959
            ],
            [
              -79.884466,
              40.12879
            ],
            [
              -79.850891,
              40.115478
            ],
            [
              -79.833463,
              40.079819
            ],
            [
              -79.801041,
              40.08018
            ],
            [
              -79.787362,
              40.0945
            ],
            [
              -79.788607,
              40.110543
            ],
            [
              -79.781043,
              40.128615
            ],
            [
              -79.744496,
              40.128135
            ],
            [
              -79.704671,
              40.107925
            ],
            [
              -79.691244,
              40.113233
            ],
            [
              -79.666312,
              40.103032
            ],
            [
              -79.654094,
              40.089394
            ],
            [
              -79.623954,
              40.08094
            ],
            [
              -79.622079,
              40.046758
            ],
            [
              -79.60851,
              40.04723
            ],
            [
              -79.578279,
              40.073189
            ],
            [
              -79.553819,
              40.098302
            ],
            [
              -79.541049,
              40.120829
            ],
            [
              -79.527825,
              40.122887
            ],
            [
              -79.503225,
              40.14112
            ],
            [
              -79.480537,
              40.14325
            ],
            [
              -79.455871,
              40.134715
            ],
            [
              -79.437409,
              40.115782
            ],
            [
              -79.414529,
              40.104323
            ],
            [
              -79.401677,
              40.102687
            ],
            [
              -79.392899,
              40.090352
            ],
            [
              -79.355443,
              40.074479
            ],
            [
              -79.341121,
              40.042902
            ],
            [
              -79.293247,
              40.040623
            ],
            [
              -79.398865,
              39.900669
            ],
            [
              -79.417409,
              39.853599
            ],
            [
              -79.381972,
              39.836527
            ],
            [
              -79.38355,
              39.82858
            ],
            [
              -79.366103,
              39.821616
            ],
            [
              -79.366149,
              39.791236
            ],
            [
              -79.392896,
              39.778785
            ],
            [
              -79.380389,
              39.772889
            ],
            [
              -79.386175,
              39.761916
            ],
            [
              -79.400075,
              39.755401
            ],
            [
              -79.393541,
              39.744238
            ],
            [
              -79.392458,
              39.721438
            ],
            [
              -79.544699,
              39.72101
            ],
            [
              -79.881814,
              39.720649
            ],
            [
              -80.056568,
              39.721304
            ],
            [
              -80.249731,
              39.721159
            ],
            [
              -80.519267,
              39.721342
            ],
            [
              -80.519241,
              39.875112
            ],
            [
              -80.518939,
              40.074058
            ],
            [
              -80.519098,
              40.19575
            ],
            [
              -80.518955,
              40.477793
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu1"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Pittsburgh-Mount Oliver IU 2",
        "teachers": 1985
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -79.937889,
              40.374701
            ],
            [
              -79.940523,
              40.374608
            ],
            [
              -79.939252,
              40.39709
            ],
            [
              -79.963599,
              40.409476
            ],
            [
              -79.979471,
              40.399746
            ],
            [
              -79.973078,
              40.388638
            ],
            [
              -79.98634,
              40.377573
            ],
            [
              -80.013326,
              40.383334
            ],
            [
              -80.013645,
              40.384035
            ],
            [
              -80.028178,
              40.391462
            ],
            [
              -80.03644,
              40.404247
            ],
            [
              -80.051186,
              40.401895
            ],
            [
              -80.034886,
              40.428341
            ],
            [
              -80.05981,
              40.428161
            ],
            [
              -80.058199,
              40.44447
            ],
            [
              -80.066213,
              40.449383
            ],
            [
              -80.090058,
              40.444333
            ],
            [
              -80.095018,
              40.459802
            ],
            [
              -80.050969,
              40.46558
            ],
            [
              -80.051507,
              40.481354
            ],
            [
              -80.04073,
              40.491484
            ],
            [
              -80.016882,
              40.499955
            ],
            [
              -80.004008,
              40.497811
            ],
            [
              -80.003062,
              40.482395
            ],
            [
              -79.969095,
              40.471893
            ],
            [
              -79.952978,
              40.487022
            ],
            [
              -79.927704,
              40.491604
            ],
            [
              -79.9014,
              40.485906
            ],
            [
              -79.884355,
              40.490917
            ],
            [
              -79.899659,
              40.41587
            ],
            [
              -79.918564,
              40.410939
            ],
            [
              -79.916156,
              40.38202
            ],
            [
              -79.900392,
              40.365303
            ],
            [
              -79.92039,
              40.365398
            ],
            [
              -79.937889,
              40.374701
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu2"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Allegheny IU 3",
        "teachers": 8093.72
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -79.871478,
              40.197368
            ],
            [
              -79.894577,
              40.194353
            ],
            [
              -79.929115,
              40.207167
            ],
            [
              -79.954791,
              40.212871
            ],
            [
              -79.969696,
              40.224861
            ],
            [
              -79.962348,
              40.238849
            ],
            [
              -79.937372,
              40.241587
            ],
            [
              -79.914524,
              40.25205
            ],
            [
              -80.182878,
              40.332751
            ],
            [
              -80.360394,
              40.477507
            ],
            [
              -80.227074,
              40.574148
            ],
            [
              -80.214298,
              40.580882
            ],
            [
              -80.180395,
              40.60893
            ],
            [
              -80.165361,
              40.606405
            ],
            [
              -80.144899,
              40.613507
            ],
            [
              -80.148392,
              40.674283
            ],
            [
              -79.989618,
              40.67367
            ],
            [
              -79.95971,
              40.672343
            ],
            [
              -79.852397,
              40.672705
            ],
            [
              -79.785116,
              40.670924
            ],
            [
              -79.692654,
              40.668331
            ],
            [
              -79.69382,
              40.667509
            ],
            [
              -79.688756,
              40.645501
            ],
            [
              -79.694134,
              40.635193
            ],
            [
              -79.722932,
              40.607053
            ],
            [
              -79.762939,
              40.593707
            ],
            [
              -79.771415,
              40.585696
            ],
            [
              -79.773923,
              40.568444
            ],
            [
              -79.765058,
              40.549602
            ],
            [
              -79.75204,
              40.551797
            ],
            [
              -79.722292,
              40.542021
            ],
            [
              -79.701604,
              40.525437
            ],
            [
              -79.704566,
              40.426442
            ],
            [
              -79.727529,
              40.412382
            ],
            [
              -79.732339,
              40.399902
            ],
            [
              -79.758896,
              40.393226
            ],
            [
              -79.772331,
              40.385733
            ],
            [
              -79.770866,
              40.375858
            ],
            [
              -79.787797,
              40.297652
            ],
            [
              -79.77528,
              40.288373
            ],
            [
              -79.795618,
              40.28124
            ],
            [
              -79.788348,
              40.264729
            ],
            [
              -79.80721,
              40.23696
            ],
            [
              -79.782964,
              40.227767
            ],
            [
              -79.871478,
              40.197368
            ]
          ],
          [
            [
              -80.013645,
              40.384035
            ],
            [
              -80.013326,
              40.383334
            ],
            [
              -79.98634,
              40.377573
            ],
            [
              -79.973078,
              40.388638
            ],
            [
              -79.979471,
              40.399746
            ],
            [
              -79.963599,
              40.409476
            ],
            [
              -79.939252,
              40.39709
            ],
            [
              -79.940523,
              40.374608
            ],
            [
              -79.937889,
              40.374701
            ],
            [
              -79.92039,
              40.365398
            ],
            [
              -79.900392,
              40.365303
            ],
            [
              -79.916156,
              40.38202
            ],
            [
              -79.918564,
              40.410939
            ],
            [
              -79.899659,
              40.41587
            ],
            [
              -79.884355,
              40.490917
            ],
            [
              -79.9014,
              40.485906
            ],
            [
              -79.927704,
              40.491604
            ],
            [
              -79.952978,
              40.487022
            ],
            [
              -79.969095,
              40.471893
            ],
            [
              -80.003062,
              40.482395
            ],
            [
              -80.004008,
              40.497811
            ],
            [
              -80.016882,
              40.499955
            ],
            [
              -80.04073,
              40.491484
            ],
            [
              -80.051507,
              40.481354
            ],
            [
              -80.050969,
              40.46558
            ],
            [
              -80.095018,
              40.459802
            ],
            [
              -80.090058,
              40.444333
            ],
            [
              -80.066213,
              40.449383
            ],
            [
              -80.058199,
              40.44447
            ],
            [
              -80.05981,
              40.428161
            ],
            [
              -80.034886,
              40.428341
            ],
            [
              -80.051186,
              40.401895
            ],
            [
              -80.03644,
              40.404247
            ],
            [
              -80.028178,
              40.391462
            ],
            [
              -80.013645,
              40.384035
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu3"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Midwestern IU 4",
        "teachers": 3541.9
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -80.519229,
              41.578489
            ],
            [
              -80.400184,
              41.576052
            ],
            [
              -80.395052,
              41.567701
            ],
            [
              -80.398295,
              41.486264
            ],
            [
              -80.125311,
              41.480392
            ],
            [
              -80.10136,
              41.486947
            ],
            [
              -80.101458,
              41.430074
            ],
            [
              -79.999834,
              41.432493
            ],
            [
              -80.000997,
              41.372676
            ],
            [
              -79.999712,
              41.250318
            ],
            [
              -79.99977,
              41.171903
            ],
            [
              -79.786348,
              41.172902
            ],
            [
              -79.785971,
              41.101541
            ],
            [
              -79.690406,
              41.102969
            ],
            [
              -79.69032,
              41.079114
            ],
            [
              -79.67271,
              41.083287
            ],
            [
              -79.654916,
              41.06947
            ],
            [
              -79.665803,
              41.056738
            ],
            [
              -79.65255,
              41.046514
            ],
            [
              -79.620159,
              41.034473
            ],
            [
              -79.599181,
              41.036888
            ],
            [
              -79.607693,
              41.00689
            ],
            [
              -79.584069,
              41.001571
            ],
            [
              -79.572537,
              40.986356
            ],
            [
              -79.587635,
              40.974639
            ],
            [
              -79.580744,
              40.894101
            ],
            [
              -79.691168,
              40.894805
            ],
            [
              -79.692312,
              40.819889
            ],
            [
              -79.690956,
              40.741057
            ],
            [
              -79.784546,
              40.742482
            ],
            [
              -79.785116,
              40.670924
            ],
            [
              -79.852397,
              40.672705
            ],
            [
              -79.95971,
              40.672343
            ],
            [
              -79.989618,
              40.67367
            ],
            [
              -80.148392,
              40.674283
            ],
            [
              -80.154494,
              40.776428
            ],
            [
              -80.157629,
              40.855673
            ],
            [
              -80.183172,
              40.854815
            ],
            [
              -80.519072,
              40.851296
            ],
            [
              -80.51911,
              41.125048
            ],
            [
              -80.519251,
              41.360968
            ],
            [
              -80.519229,
              41.578489
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu4"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Northwest Tri-County IU 5",
        "teachers": 3483.72
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -78.918888,
              41.99872
            ],
            [
              -78.915499,
              41.952913
            ],
            [
              -78.917057,
              41.861821
            ],
            [
              -78.915642,
              41.840932
            ],
            [
              -78.921408,
              41.830904
            ],
            [
              -78.948611,
              41.830518
            ],
            [
              -78.956138,
              41.623334
            ],
            [
              -79.034748,
              41.625428
            ],
            [
              -79.15307,
              41.626719
            ],
            [
              -79.306214,
              41.62578
            ],
            [
              -79.402467,
              41.626109
            ],
            [
              -79.493391,
              41.624746
            ],
            [
              -79.494831,
              41.630159
            ],
            [
              -79.493921,
              41.704047
            ],
            [
              -79.612975,
              41.703997
            ],
            [
              -79.612616,
              41.775023
            ],
            [
              -79.771405,
              41.774254
            ],
            [
              -79.773121,
              41.748024
            ],
            [
              -79.7708,
              41.71655
            ],
            [
              -79.746422,
              41.716135
            ],
            [
              -79.748049,
              41.617604
            ],
            [
              -79.768367,
              41.617623
            ],
            [
              -79.768583,
              41.583712
            ],
            [
              -79.762323,
              41.569974
            ],
            [
              -79.766342,
              41.557045
            ],
            [
              -79.800341,
              41.548043
            ],
            [
              -79.794646,
              41.536811
            ],
            [
              -79.832688,
              41.526758
            ],
            [
              -79.850297,
              41.548264
            ],
            [
              -79.870589,
              41.545276
            ],
            [
              -79.885118,
              41.56223
            ],
            [
              -79.908684,
              41.562088
            ],
            [
              -79.909349,
              41.550701
            ],
            [
              -79.925508,
              41.549802
            ],
            [
              -79.923549,
              41.53754
            ],
            [
              -79.943454,
              41.53747
            ],
            [
              -79.942507,
              41.525231
            ],
            [
              -79.959239,
              41.525397
            ],
            [
              -79.958277,
              41.513431
            ],
            [
              -79.981528,
              41.512866
            ],
            [
              -79.98166,
              41.500078
            ],
            [
              -79.998586,
              41.500906
            ],
            [
              -79.999834,
              41.432493
            ],
            [
              -80.101458,
              41.430074
            ],
            [
              -80.10136,
              41.486947
            ],
            [
              -80.125311,
              41.480392
            ],
            [
              -80.398295,
              41.486264
            ],
            [
              -80.395052,
              41.567701
            ],
            [
              -80.400184,
              41.576052
            ],
            [
              -80.519229,
              41.578489
            ],
            [
              -80.519175,
              41.750018
            ],
            [
              -80.519349,
              41.977628
            ],
            [
              -80.494674,
              41.987411
            ],
            [
              -80.439526,
              42.004632
            ],
            [
              -80.403277,
              42.013208
            ],
            [
              -80.363366,
              42.027898
            ],
            [
              -80.323629,
              42.038369
            ],
            [
              -80.267138,
              42.060541
            ],
            [
              -80.243822,
              42.073048
            ],
            [
              -80.183115,
              42.096406
            ],
            [
              -80.158152,
              42.110678
            ],
            [
              -80.134716,
              42.15085
            ],
            [
              -80.115393,
              42.166709
            ],
            [
              -80.080704,
              42.173706
            ],
            [
              -80.088088,
              42.161504
            ],
            [
              -80.121296,
              42.158057
            ],
            [
              -80.139059,
              42.140107
            ],
            [
              -80.150703,
              42.11274
            ],
            [
              -80.099142,
              42.130704
            ],
            [
              -80.080932,
              42.144445
            ],
            [
              -80.06086,
              42.145032
            ],
            [
              -79.931008,
              42.206733
            ],
            [
              -79.901984,
              42.216081
            ],
            [
              -79.872707,
              42.23186
            ],
            [
              -79.842108,
              42.236248
            ],
            [
              -79.817878,
              42.245393
            ],
            [
              -79.783862,
              42.263905
            ],
            [
              -79.762028,
              42.267375
            ],
            [
              -79.761875,
              41.998788
            ],
            [
              -79.51088,
              41.998363
            ],
            [
              -79.234309,
              41.998953
            ],
            [
              -79.158607,
              41.999671
            ],
            [
              -78.918888,
              41.99872
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu5"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Riverview IU 6",
        "teachers": 1666.88
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -79.999834,
              41.432493
            ],
            [
              -79.998586,
              41.500906
            ],
            [
              -79.98166,
              41.500078
            ],
            [
              -79.981528,
              41.512866
            ],
            [
              -79.958277,
              41.513431
            ],
            [
              -79.959239,
              41.525397
            ],
            [
              -79.942507,
              41.525231
            ],
            [
              -79.943454,
              41.53747
            ],
            [
              -79.923549,
              41.53754
            ],
            [
              -79.925508,
              41.549802
            ],
            [
              -79.909349,
              41.550701
            ],
            [
              -79.908684,
              41.562088
            ],
            [
              -79.885118,
              41.56223
            ],
            [
              -79.870589,
              41.545276
            ],
            [
              -79.850297,
              41.548264
            ],
            [
              -79.832688,
              41.526758
            ],
            [
              -79.794646,
              41.536811
            ],
            [
              -79.800341,
              41.548043
            ],
            [
              -79.766342,
              41.557045
            ],
            [
              -79.762323,
              41.569974
            ],
            [
              -79.768583,
              41.583712
            ],
            [
              -79.768367,
              41.617623
            ],
            [
              -79.748049,
              41.617604
            ],
            [
              -79.746422,
              41.716135
            ],
            [
              -79.7708,
              41.71655
            ],
            [
              -79.773121,
              41.748024
            ],
            [
              -79.771405,
              41.774254
            ],
            [
              -79.612616,
              41.775023
            ],
            [
              -79.612975,
              41.703997
            ],
            [
              -79.493921,
              41.704047
            ],
            [
              -79.494831,
              41.630159
            ],
            [
              -79.493391,
              41.624746
            ],
            [
              -79.402467,
              41.626109
            ],
            [
              -79.306214,
              41.62578
            ],
            [
              -79.15307,
              41.626719
            ],
            [
              -79.034748,
              41.625428
            ],
            [
              -78.956138,
              41.623334
            ],
            [
              -78.959113,
              41.360887
            ],
            [
              -78.736069,
              41.26162
            ],
            [
              -78.700869,
              41.27995
            ],
            [
              -78.691218,
              41.269113
            ],
            [
              -78.689019,
              41.207588
            ],
            [
              -78.648042,
              41.217389
            ],
            [
              -78.64779,
              41.254239
            ],
            [
              -78.485314,
              41.243605
            ],
            [
              -78.486406,
              41.148362
            ],
            [
              -78.57045,
              41.148524
            ],
            [
              -78.580342,
              41.1375
            ],
            [
              -78.583857,
              41.050677
            ],
            [
              -78.591811,
              41.050457
            ],
            [
              -78.593164,
              41.00172
            ],
            [
              -78.602258,
              40.990074
            ],
            [
              -78.627492,
              40.987847
            ],
            [
              -78.642602,
              40.981431
            ],
            [
              -78.660115,
              41.000598
            ],
            [
              -78.709587,
              41.000808
            ],
            [
              -78.709414,
              40.993309
            ],
            [
              -78.804342,
              40.993687
            ],
            [
              -78.804601,
              40.926985
            ],
            [
              -78.806549,
              40.845762
            ],
            [
              -78.806317,
              40.818415
            ],
            [
              -78.82532,
              40.829762
            ],
            [
              -78.840608,
              40.829031
            ],
            [
              -78.833124,
              40.812005
            ],
            [
              -78.901838,
              40.815656
            ],
            [
              -78.902132,
              40.876089
            ],
            [
              -78.921466,
              40.879162
            ],
            [
              -78.952987,
              40.877515
            ],
            [
              -78.963991,
              40.88991
            ],
            [
              -78.992091,
              40.885884
            ],
            [
              -78.993714,
              40.836624
            ],
            [
              -79.101197,
              40.83835
            ],
            [
              -79.1026,
              40.910069
            ],
            [
              -79.215458,
              40.911895
            ],
            [
              -79.251526,
              40.929704
            ],
            [
              -79.26705,
              40.917412
            ],
            [
              -79.293196,
              40.928242
            ],
            [
              -79.36528,
              40.928135
            ],
            [
              -79.395713,
              40.917409
            ],
            [
              -79.413649,
              40.937911
            ],
            [
              -79.427976,
              40.925508
            ],
            [
              -79.440536,
              40.93754
            ],
            [
              -79.452226,
              40.926697
            ],
            [
              -79.468734,
              40.937624
            ],
            [
              -79.483886,
              40.937409
            ],
            [
              -79.497659,
              40.922191
            ],
            [
              -79.540486,
              40.940546
            ],
            [
              -79.548916,
              40.956314
            ],
            [
              -79.551403,
              40.981384
            ],
            [
              -79.572537,
              40.986356
            ],
            [
              -79.584069,
              41.001571
            ],
            [
              -79.607693,
              41.00689
            ],
            [
              -79.599181,
              41.036888
            ],
            [
              -79.620159,
              41.034473
            ],
            [
              -79.65255,
              41.046514
            ],
            [
              -79.665803,
              41.056738
            ],
            [
              -79.654916,
              41.06947
            ],
            [
              -79.67271,
              41.083287
            ],
            [
              -79.69032,
              41.079114
            ],
            [
              -79.690406,
              41.102969
            ],
            [
              -79.785971,
              41.101541
            ],
            [
              -79.786348,
              41.172902
            ],
            [
              -79.99977,
              41.171903
            ],
            [
              -79.999712,
              41.250318
            ],
            [
              -80.000997,
              41.372676
            ],
            [
              -79.999834,
              41.432493
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu6"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Westmoreland IU 7",
        "teachers": 3042.79
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -79.871478,
              40.197368
            ],
            [
              -79.782964,
              40.227767
            ],
            [
              -79.80721,
              40.23696
            ],
            [
              -79.788348,
              40.264729
            ],
            [
              -79.795618,
              40.28124
            ],
            [
              -79.77528,
              40.288373
            ],
            [
              -79.787797,
              40.297652
            ],
            [
              -79.770866,
              40.375858
            ],
            [
              -79.772331,
              40.385733
            ],
            [
              -79.758896,
              40.393226
            ],
            [
              -79.732339,
              40.399902
            ],
            [
              -79.727529,
              40.412382
            ],
            [
              -79.704566,
              40.426442
            ],
            [
              -79.701604,
              40.525437
            ],
            [
              -79.722292,
              40.542021
            ],
            [
              -79.75204,
              40.551797
            ],
            [
              -79.765058,
              40.549602
            ],
            [
              -79.773923,
              40.568444
            ],
            [
              -79.771415,
              40.585696
            ],
            [
              -79.762939,
              40.593707
            ],
            [
              -79.722932,
              40.607053
            ],
            [
              -79.694134,
              40.635193
            ],
            [
              -79.688756,
              40.645501
            ],
            [
              -79.69382,
              40.667509
            ],
            [
              -79.692654,
              40.668331
            ],
            [
              -79.669173,
              40.679362
            ],
            [
              -79.637315,
              40.664039
            ],
            [
              -79.636453,
              40.656033
            ],
            [
              -79.619244,
              40.645056
            ],
            [
              -79.628315,
              40.630123
            ],
            [
              -79.613342,
              40.623008
            ],
            [
              -79.591528,
              40.634249
            ],
            [
              -79.56605,
              40.642286
            ],
            [
              -79.564479,
              40.672313
            ],
            [
              -79.511789,
              40.668413
            ],
            [
              -79.495905,
              40.645732
            ],
            [
              -79.566213,
              40.593251
            ],
            [
              -79.570382,
              40.583116
            ],
            [
              -79.560173,
              40.56302
            ],
            [
              -79.526658,
              40.542914
            ],
            [
              -79.511478,
              40.542734
            ],
            [
              -79.488061,
              40.526416
            ],
            [
              -79.48336,
              40.53631
            ],
            [
              -79.455628,
              40.53452
            ],
            [
              -79.459818,
              40.517027
            ],
            [
              -79.455516,
              40.497171
            ],
            [
              -79.478726,
              40.49271
            ],
            [
              -79.48716,
              40.47824
            ],
            [
              -79.5046,
              40.473888
            ],
            [
              -79.531497,
              40.474012
            ],
            [
              -79.44451,
              40.415578
            ],
            [
              -79.373402,
              40.442114
            ],
            [
              -79.3625,
              40.460709
            ],
            [
              -79.329303,
              40.453035
            ],
            [
              -79.302586,
              40.46124
            ],
            [
              -79.300003,
              40.434481
            ],
            [
              -79.284413,
              40.442434
            ],
            [
              -79.273031,
              40.439362
            ],
            [
              -79.265249,
              40.416411
            ],
            [
              -79.246949,
              40.432111
            ],
            [
              -79.216316,
              40.425121
            ],
            [
              -79.202214,
              40.411356
            ],
            [
              -79.190487,
              40.41516
            ],
            [
              -79.154949,
              40.41147
            ],
            [
              -79.154001,
              40.401891
            ],
            [
              -79.117607,
              40.394517
            ],
            [
              -79.12712,
              40.375082
            ],
            [
              -79.101322,
              40.368795
            ],
            [
              -79.083484,
              40.389516
            ],
            [
              -79.063261,
              40.379119
            ],
            [
              -79.042621,
              40.391536
            ],
            [
              -79.016342,
              40.420323
            ],
            [
              -78.99009,
              40.412219
            ],
            [
              -78.974156,
              40.394581
            ],
            [
              -79.01028,
              40.340229
            ],
            [
              -79.020019,
              40.335606
            ],
            [
              -79.056805,
              40.278687
            ],
            [
              -79.06745,
              40.273089
            ],
            [
              -79.077958,
              40.253372
            ],
            [
              -79.091563,
              40.240306
            ],
            [
              -79.100462,
              40.222095
            ],
            [
              -79.124144,
              40.193421
            ],
            [
              -79.139987,
              40.164755
            ],
            [
              -79.161468,
              40.160487
            ],
            [
              -79.167548,
              40.129751
            ],
            [
              -79.184829,
              40.110353
            ],
            [
              -79.196799,
              40.117977
            ],
            [
              -79.213334,
              40.11813
            ],
            [
              -79.226326,
              40.098843
            ],
            [
              -79.255378,
              40.088434
            ],
            [
              -79.267301,
              40.067438
            ],
            [
              -79.293247,
              40.040623
            ],
            [
              -79.341121,
              40.042902
            ],
            [
              -79.355443,
              40.074479
            ],
            [
              -79.392899,
              40.090352
            ],
            [
              -79.401677,
              40.102687
            ],
            [
              -79.414529,
              40.104323
            ],
            [
              -79.437409,
              40.115782
            ],
            [
              -79.455871,
              40.134715
            ],
            [
              -79.480537,
              40.14325
            ],
            [
              -79.503225,
              40.14112
            ],
            [
              -79.527825,
              40.122887
            ],
            [
              -79.541049,
              40.120829
            ],
            [
              -79.553819,
              40.098302
            ],
            [
              -79.578279,
              40.073189
            ],
            [
              -79.60851,
              40.04723
            ],
            [
              -79.622079,
              40.046758
            ],
            [
              -79.623954,
              40.08094
            ],
            [
              -79.654094,
              40.089394
            ],
            [
              -79.666312,
              40.103032
            ],
            [
              -79.691244,
              40.113233
            ],
            [
              -79.704671,
              40.107925
            ],
            [
              -79.744496,
              40.128135
            ],
            [
              -79.781043,
              40.128615
            ],
            [
              -79.788607,
              40.110543
            ],
            [
              -79.787362,
              40.0945
            ],
            [
              -79.801041,
              40.08018
            ],
            [
              -79.833463,
              40.079819
            ],
            [
              -79.850891,
              40.115478
            ],
            [
              -79.884466,
              40.12879
            ],
            [
              -79.904968,
              40.156959
            ],
            [
              -79.894249,
              40.164993
            ],
            [
              -79.861172,
              40.162455
            ],
            [
              -79.852373,
              40.169532
            ],
            [
              -79.854014,
              40.190939
            ],
            [
              -79.871478,
              40.197368
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu7"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Appalachia IU 8",
        "teachers": 3549.64
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -79.392458,
              39.721438
            ],
            [
              -79.393541,
              39.744238
            ],
            [
              -79.400075,
              39.755401
            ],
            [
              -79.386175,
              39.761916
            ],
            [
              -79.380389,
              39.772889
            ],
            [
              -79.392896,
              39.778785
            ],
            [
              -79.366149,
              39.791236
            ],
            [
              -79.366103,
              39.821616
            ],
            [
              -79.38355,
              39.82858
            ],
            [
              -79.381972,
              39.836527
            ],
            [
              -79.417409,
              39.853599
            ],
            [
              -79.398865,
              39.900669
            ],
            [
              -79.293247,
              40.040623
            ],
            [
              -79.267301,
              40.067438
            ],
            [
              -79.255378,
              40.088434
            ],
            [
              -79.226326,
              40.098843
            ],
            [
              -79.213334,
              40.11813
            ],
            [
              -79.196799,
              40.117977
            ],
            [
              -79.184829,
              40.110353
            ],
            [
              -79.167548,
              40.129751
            ],
            [
              -79.161468,
              40.160487
            ],
            [
              -79.139987,
              40.164755
            ],
            [
              -79.124144,
              40.193421
            ],
            [
              -79.100462,
              40.222095
            ],
            [
              -79.091563,
              40.240306
            ],
            [
              -79.077958,
              40.253372
            ],
            [
              -79.06745,
              40.273089
            ],
            [
              -79.056805,
              40.278687
            ],
            [
              -79.020019,
              40.335606
            ],
            [
              -79.01028,
              40.340229
            ],
            [
              -78.974156,
              40.394581
            ],
            [
              -78.921923,
              40.483512
            ],
            [
              -78.885946,
              40.554067
            ],
            [
              -78.850671,
              40.626336
            ],
            [
              -78.808862,
              40.721062
            ],
            [
              -78.802289,
              40.724623
            ],
            [
              -78.691996,
              40.724337
            ],
            [
              -78.635328,
              40.723078
            ],
            [
              -78.578038,
              40.725296
            ],
            [
              -78.578163,
              40.638574
            ],
            [
              -78.534421,
              40.63914
            ],
            [
              -78.440734,
              40.636515
            ],
            [
              -78.432458,
              40.647719
            ],
            [
              -78.408506,
              40.644333
            ],
            [
              -78.348818,
              40.723261
            ],
            [
              -78.359938,
              40.732608
            ],
            [
              -78.285779,
              40.736261
            ],
            [
              -78.200275,
              40.781318
            ],
            [
              -78.135924,
              40.816533
            ],
            [
              -78.063538,
              40.777272
            ],
            [
              -78.115895,
              40.739255
            ],
            [
              -77.944253,
              40.691558
            ],
            [
              -77.999923,
              40.674671
            ],
            [
              -78.051346,
              40.654143
            ],
            [
              -78.097487,
              40.621915
            ],
            [
              -78.132694,
              40.642223
            ],
            [
              -78.159988,
              40.64408
            ],
            [
              -78.172593,
              40.627346
            ],
            [
              -78.166043,
              40.615647
            ],
            [
              -78.184326,
              40.59541
            ],
            [
              -78.178142,
              40.584394
            ],
            [
              -78.197033,
              40.558604
            ],
            [
              -78.178251,
              40.537608
            ],
            [
              -78.147114,
              40.533533
            ],
            [
              -78.153707,
              40.496951
            ],
            [
              -78.136786,
              40.498542
            ],
            [
              -78.129581,
              40.490658
            ],
            [
              -78.135148,
              40.471568
            ],
            [
              -78.155651,
              40.438158
            ],
            [
              -78.205086,
              40.373516
            ],
            [
              -78.216318,
              40.353691
            ],
            [
              -78.243218,
              40.322525
            ],
            [
              -78.215187,
              40.312351
            ],
            [
              -78.177114,
              40.286245
            ],
            [
              -78.155101,
              40.314935
            ],
            [
              -78.123934,
              40.342537
            ],
            [
              -78.083604,
              40.302582
            ],
            [
              -78.046166,
              40.272735
            ],
            [
              -78.031317,
              40.256295
            ],
            [
              -78.041474,
              40.229946
            ],
            [
              -78.062933,
              40.162746
            ],
            [
              -78.077977,
              40.133594
            ],
            [
              -78.134482,
              40.165193
            ],
            [
              -78.158904,
              40.122458
            ],
            [
              -78.206532,
              40.086402
            ],
            [
              -78.202827,
              40.069215
            ],
            [
              -78.181303,
              40.058998
            ],
            [
              -78.188035,
              40.047237
            ],
            [
              -78.204129,
              40.042401
            ],
            [
              -78.200742,
              40.013071
            ],
            [
              -78.219822,
              39.992675
            ],
            [
              -78.239013,
              39.951303
            ],
            [
              -78.268838,
              39.900098
            ],
            [
              -78.299948,
              39.824601
            ],
            [
              -78.325459,
              39.785387
            ],
            [
              -78.343546,
              39.762134
            ],
            [
              -78.381197,
              39.722537
            ],
            [
              -78.692471,
              39.722993
            ],
            [
              -79.006732,
              39.722489
            ],
            [
              -79.392458,
              39.721438
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu8"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Seneca Highlands IU 9",
        "teachers": 919.19
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -78.956138,
              41.623334
            ],
            [
              -78.948611,
              41.830518
            ],
            [
              -78.921408,
              41.830904
            ],
            [
              -78.915642,
              41.840932
            ],
            [
              -78.917057,
              41.861821
            ],
            [
              -78.915499,
              41.952913
            ],
            [
              -78.918888,
              41.99872
            ],
            [
              -78.715275,
              41.998565
            ],
            [
              -78.618768,
              41.999818
            ],
            [
              -78.268864,
              41.999245
            ],
            [
              -78.125603,
              42.000466
            ],
            [
              -77.906828,
              41.998238
            ],
            [
              -77.769995,
              41.998451
            ],
            [
              -77.610003,
              41.999418
            ],
            [
              -77.605474,
              41.809237
            ],
            [
              -77.492635,
              41.810208
            ],
            [
              -77.490853,
              41.688862
            ],
            [
              -77.458491,
              41.689128
            ],
            [
              -77.459234,
              41.632986
            ],
            [
              -77.462926,
              41.54351
            ],
            [
              -77.59927,
              41.542264
            ],
            [
              -77.599917,
              41.570874
            ],
            [
              -77.781367,
              41.567467
            ],
            [
              -77.826795,
              41.567202
            ],
            [
              -77.826148,
              41.476967
            ],
            [
              -77.985737,
              41.475728
            ],
            [
              -77.986316,
              41.361849
            ],
            [
              -78.093218,
              41.219453
            ],
            [
              -78.485314,
              41.243605
            ],
            [
              -78.64779,
              41.254239
            ],
            [
              -78.648042,
              41.217389
            ],
            [
              -78.689019,
              41.207588
            ],
            [
              -78.691218,
              41.269113
            ],
            [
              -78.700869,
              41.27995
            ],
            [
              -78.736069,
              41.26162
            ],
            [
              -78.959113,
              41.360887
            ],
            [
              -78.956138,
              41.623334
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu9"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Central IU 10",
        "teachers": 1917.32
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -78.808862,
              40.721062
            ],
            [
              -78.806311,
              40.729703
            ],
            [
              -78.806316,
              40.806262
            ],
            [
              -78.795964,
              40.802493
            ],
            [
              -78.778519,
              40.80883
            ],
            [
              -78.781788,
              40.824762
            ],
            [
              -78.806317,
              40.818415
            ],
            [
              -78.806549,
              40.845762
            ],
            [
              -78.703893,
              40.843731
            ],
            [
              -78.715686,
              40.833489
            ],
            [
              -78.716031,
              40.816664
            ],
            [
              -78.706064,
              40.807738
            ],
            [
              -78.689169,
              40.807437
            ],
            [
              -78.695793,
              40.828872
            ],
            [
              -78.676198,
              40.82902
            ],
            [
              -78.672129,
              40.844735
            ],
            [
              -78.680415,
              40.852144
            ],
            [
              -78.665349,
              40.865857
            ],
            [
              -78.706901,
              40.905419
            ],
            [
              -78.709414,
              40.993309
            ],
            [
              -78.709587,
              41.000808
            ],
            [
              -78.660115,
              41.000598
            ],
            [
              -78.642602,
              40.981431
            ],
            [
              -78.627492,
              40.987847
            ],
            [
              -78.602258,
              40.990074
            ],
            [
              -78.593164,
              41.00172
            ],
            [
              -78.591811,
              41.050457
            ],
            [
              -78.583857,
              41.050677
            ],
            [
              -78.580342,
              41.1375
            ],
            [
              -78.57045,
              41.148524
            ],
            [
              -78.486406,
              41.148362
            ],
            [
              -78.485314,
              41.243605
            ],
            [
              -78.093218,
              41.219453
            ],
            [
              -77.986316,
              41.361849
            ],
            [
              -77.985737,
              41.475728
            ],
            [
              -77.826148,
              41.476967
            ],
            [
              -77.826795,
              41.567202
            ],
            [
              -77.781367,
              41.567467
            ],
            [
              -77.599917,
              41.570874
            ],
            [
              -77.59927,
              41.542264
            ],
            [
              -77.59516,
              41.440596
            ],
            [
              -77.584605,
              41.440871
            ],
            [
              -77.575199,
              41.420525
            ],
            [
              -77.563527,
              41.41203
            ],
            [
              -77.542011,
              41.382219
            ],
            [
              -77.539815,
              41.367684
            ],
            [
              -77.526182,
              41.3585
            ],
            [
              -77.499264,
              41.353662
            ],
            [
              -77.476223,
              41.338503
            ],
            [
              -77.471922,
              41.31438
            ],
            [
              -77.455005,
              41.303364
            ],
            [
              -77.442603,
              41.286285
            ],
            [
              -77.429565,
              41.282558
            ],
            [
              -77.399047,
              41.25307
            ],
            [
              -77.370131,
              41.232972
            ],
            [
              -77.349663,
              41.228672
            ],
            [
              -77.34256,
              41.220687
            ],
            [
              -77.325775,
              41.219725
            ],
            [
              -77.328896,
              41.206204
            ],
            [
              -77.34579,
              41.189695
            ],
            [
              -77.327636,
              41.163748
            ],
            [
              -77.313569,
              41.17011
            ],
            [
              -77.285055,
              41.166135
            ],
            [
              -77.2695,
              41.169992
            ],
            [
              -77.252001,
              41.153218
            ],
            [
              -77.286218,
              41.142353
            ],
            [
              -77.297373,
              41.130511
            ],
            [
              -77.308546,
              41.077006
            ],
            [
              -77.175041,
              41.078277
            ],
            [
              -77.165153,
              41.068612
            ],
            [
              -77.145301,
              41.068922
            ],
            [
              -77.143461,
              41.042971
            ],
            [
              -77.203089,
              40.993708
            ],
            [
              -77.279223,
              40.909717
            ],
            [
              -77.364171,
              40.84694
            ],
            [
              -77.429604,
              40.827541
            ],
            [
              -77.492315,
              40.80139
            ],
            [
              -77.59406,
              40.76107
            ],
            [
              -77.65257,
              40.744925
            ],
            [
              -77.661992,
              40.737742
            ],
            [
              -77.710548,
              40.714564
            ],
            [
              -77.720686,
              40.726259
            ],
            [
              -77.770399,
              40.719271
            ],
            [
              -77.824889,
              40.743527
            ],
            [
              -77.894292,
              40.716202
            ],
            [
              -77.944253,
              40.691558
            ],
            [
              -78.115895,
              40.739255
            ],
            [
              -78.063538,
              40.777272
            ],
            [
              -78.135924,
              40.816533
            ],
            [
              -78.200275,
              40.781318
            ],
            [
              -78.285779,
              40.736261
            ],
            [
              -78.359938,
              40.732608
            ],
            [
              -78.348818,
              40.723261
            ],
            [
              -78.408506,
              40.644333
            ],
            [
              -78.432458,
              40.647719
            ],
            [
              -78.440734,
              40.636515
            ],
            [
              -78.534421,
              40.63914
            ],
            [
              -78.578163,
              40.638574
            ],
            [
              -78.578038,
              40.725296
            ],
            [
              -78.635328,
              40.723078
            ],
            [
              -78.691996,
              40.724337
            ],
            [
              -78.802289,
              40.724623
            ],
            [
              -78.808862,
              40.721062
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu10"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Tuscarora IU 11",
        "teachers": 1139.76
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -78.098773,
              39.722356
            ],
            [
              -78.381197,
              39.722537
            ],
            [
              -78.343546,
              39.762134
            ],
            [
              -78.325459,
              39.785387
            ],
            [
              -78.299948,
              39.824601
            ],
            [
              -78.268838,
              39.900098
            ],
            [
              -78.239013,
              39.951303
            ],
            [
              -78.219822,
              39.992675
            ],
            [
              -78.200742,
              40.013071
            ],
            [
              -78.204129,
              40.042401
            ],
            [
              -78.188035,
              40.047237
            ],
            [
              -78.181303,
              40.058998
            ],
            [
              -78.202827,
              40.069215
            ],
            [
              -78.206532,
              40.086402
            ],
            [
              -78.158904,
              40.122458
            ],
            [
              -78.134482,
              40.165193
            ],
            [
              -78.077977,
              40.133594
            ],
            [
              -78.062933,
              40.162746
            ],
            [
              -78.041474,
              40.229946
            ],
            [
              -78.031317,
              40.256295
            ],
            [
              -78.046166,
              40.272735
            ],
            [
              -78.083604,
              40.302582
            ],
            [
              -78.123934,
              40.342537
            ],
            [
              -78.155101,
              40.314935
            ],
            [
              -78.177114,
              40.286245
            ],
            [
              -78.215187,
              40.312351
            ],
            [
              -78.243218,
              40.322525
            ],
            [
              -78.216318,
              40.353691
            ],
            [
              -78.205086,
              40.373516
            ],
            [
              -78.155651,
              40.438158
            ],
            [
              -78.135148,
              40.471568
            ],
            [
              -78.129581,
              40.490658
            ],
            [
              -78.136786,
              40.498542
            ],
            [
              -78.153707,
              40.496951
            ],
            [
              -78.147114,
              40.533533
            ],
            [
              -78.178251,
              40.537608
            ],
            [
              -78.197033,
              40.558604
            ],
            [
              -78.178142,
              40.584394
            ],
            [
              -78.184326,
              40.59541
            ],
            [
              -78.166043,
              40.615647
            ],
            [
              -78.172593,
              40.627346
            ],
            [
              -78.159988,
              40.64408
            ],
            [
              -78.132694,
              40.642223
            ],
            [
              -78.097487,
              40.621915
            ],
            [
              -78.051346,
              40.654143
            ],
            [
              -77.999923,
              40.674671
            ],
            [
              -77.944253,
              40.691558
            ],
            [
              -77.894292,
              40.716202
            ],
            [
              -77.824889,
              40.743527
            ],
            [
              -77.770399,
              40.719271
            ],
            [
              -77.720686,
              40.726259
            ],
            [
              -77.710548,
              40.714564
            ],
            [
              -77.661992,
              40.737742
            ],
            [
              -77.65257,
              40.744925
            ],
            [
              -77.59406,
              40.76107
            ],
            [
              -77.492315,
              40.80139
            ],
            [
              -77.429604,
              40.827541
            ],
            [
              -77.364171,
              40.84694
            ],
            [
              -77.357319,
              40.844259
            ],
            [
              -77.354316,
              40.701873
            ],
            [
              -77.17128,
              40.679055
            ],
            [
              -77.1503,
              40.677044
            ],
            [
              -77.140096,
              40.683543
            ],
            [
              -77.11039,
              40.691486
            ],
            [
              -77.094366,
              40.676428
            ],
            [
              -77.068322,
              40.679801
            ],
            [
              -77.035777,
              40.676919
            ],
            [
              -77.032702,
              40.664334
            ],
            [
              -77.010464,
              40.661495
            ],
            [
              -77.003382,
              40.638889
            ],
            [
              -76.987167,
              40.643833
            ],
            [
              -76.968159,
              40.641221
            ],
            [
              -76.946002,
              40.650265
            ],
            [
              -76.939188,
              40.638561
            ],
            [
              -76.948203,
              40.628586
            ],
            [
              -76.956184,
              40.627937
            ],
            [
              -77.023735,
              40.604272
            ],
            [
              -77.063586,
              40.599637
            ],
            [
              -77.063896,
              40.654018
            ],
            [
              -77.116791,
              40.642519
            ],
            [
              -77.174658,
              40.633041
            ],
            [
              -77.163217,
              40.586865
            ],
            [
              -77.182958,
              40.582136
            ],
            [
              -77.167691,
              40.559444
            ],
            [
              -77.198625,
              40.552131
            ],
            [
              -77.265514,
              40.526989
            ],
            [
              -77.306345,
              40.510024
            ],
            [
              -77.411607,
              40.461313
            ],
            [
              -77.478081,
              40.432054
            ],
            [
              -77.540219,
              40.399237
            ],
            [
              -77.532248,
              40.375918
            ],
            [
              -77.563381,
              40.368348
            ],
            [
              -77.593606,
              40.351404
            ],
            [
              -77.647595,
              40.313345
            ],
            [
              -77.684799,
              40.276581
            ],
            [
              -77.724227,
              40.250108
            ],
            [
              -77.768328,
              40.204125
            ],
            [
              -77.79659,
              40.166543
            ],
            [
              -77.815866,
              40.132442
            ],
            [
              -77.82807,
              40.116666
            ],
            [
              -77.861394,
              40.062963
            ],
            [
              -77.876544,
              40.054702
            ],
            [
              -77.918169,
              40.004721
            ],
            [
              -77.921103,
              39.988465
            ],
            [
              -77.932217,
              39.972041
            ],
            [
              -77.937096,
              39.949278
            ],
            [
              -77.956156,
              39.926683
            ],
            [
              -77.954515,
              39.920736
            ],
            [
              -77.977596,
              39.867082
            ],
            [
              -78.015332,
              39.811138
            ],
            [
              -78.030941,
              39.797782
            ],
            [
              -78.059727,
              39.763494
            ],
            [
              -78.06854,
              39.748542
            ],
            [
              -78.098773,
              39.722356
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu11"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Lincoln IU 12",
        "teachers": 5630.47
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -76.721368,
              40.120837
            ],
            [
              -76.697386,
              40.103379
            ],
            [
              -76.664154,
              40.063929
            ],
            [
              -76.644674,
              40.058692
            ],
            [
              -76.597643,
              40.052594
            ],
            [
              -76.572557,
              40.046408
            ],
            [
              -76.533174,
              40.051513
            ],
            [
              -76.525452,
              40.025975
            ],
            [
              -76.497288,
              39.996864
            ],
            [
              -76.488212,
              39.965392
            ],
            [
              -76.467923,
              39.944262
            ],
            [
              -76.390309,
              39.912333
            ],
            [
              -76.379188,
              39.888323
            ],
            [
              -76.378558,
              39.868715
            ],
            [
              -76.351194,
              39.847896
            ],
            [
              -76.336932,
              39.822207
            ],
            [
              -76.316034,
              39.810478
            ],
            [
              -76.293926,
              39.7836
            ],
            [
              -76.276414,
              39.775145
            ],
            [
              -76.26721,
              39.758241
            ],
            [
              -76.240231,
              39.728688
            ],
            [
              -76.240763,
              39.721785
            ],
            [
              -76.279552,
              39.721196
            ],
            [
              -76.712605,
              39.720933
            ],
            [
              -76.97623,
              39.720077
            ],
            [
              -77.25846,
              39.719869
            ],
            [
              -77.499751,
              39.719947
            ],
            [
              -77.729235,
              39.721082
            ],
            [
              -77.936471,
              39.722409
            ],
            [
              -78.098773,
              39.722356
            ],
            [
              -78.06854,
              39.748542
            ],
            [
              -78.059727,
              39.763494
            ],
            [
              -78.030941,
              39.797782
            ],
            [
              -78.015332,
              39.811138
            ],
            [
              -77.977596,
              39.867082
            ],
            [
              -77.954515,
              39.920736
            ],
            [
              -77.956156,
              39.926683
            ],
            [
              -77.937096,
              39.949278
            ],
            [
              -77.932217,
              39.972041
            ],
            [
              -77.921103,
              39.988465
            ],
            [
              -77.918169,
              40.004721
            ],
            [
              -77.876544,
              40.054702
            ],
            [
              -77.861394,
              40.062963
            ],
            [
              -77.82807,
              40.116666
            ],
            [
              -77.815866,
              40.132442
            ],
            [
              -77.79659,
              40.166543
            ],
            [
              -77.768328,
              40.204125
            ],
            [
              -77.724227,
              40.250108
            ],
            [
              -77.684799,
              40.276581
            ],
            [
              -77.647595,
              40.313345
            ],
            [
              -77.593606,
              40.351404
            ],
            [
              -77.577121,
              40.334307
            ],
            [
              -77.64598,
              40.282736
            ],
            [
              -77.671702,
              40.257141
            ],
            [
              -77.647128,
              40.256872
            ],
            [
              -77.649951,
              40.226283
            ],
            [
              -77.642145,
              40.217472
            ],
            [
              -77.619256,
              40.229484
            ],
            [
              -77.603952,
              40.229031
            ],
            [
              -77.604768,
              40.206033
            ],
            [
              -77.627264,
              40.188753
            ],
            [
              -77.608269,
              40.15268
            ],
            [
              -77.568485,
              40.126024
            ],
            [
              -77.559247,
              40.105331
            ],
            [
              -77.579108,
              40.087856
            ],
            [
              -77.599166,
              40.082827
            ],
            [
              -77.592896,
              40.073871
            ],
            [
              -77.623512,
              40.058968
            ],
            [
              -77.630923,
              40.048342
            ],
            [
              -77.619282,
              40.034882
            ],
            [
              -77.585763,
              40.009936
            ],
            [
              -77.470795,
              39.944171
            ],
            [
              -77.403497,
              39.99454
            ],
            [
              -77.228922,
              40.025144
            ],
            [
              -77.185605,
              40.029534
            ],
            [
              -77.16039,
              40.053302
            ],
            [
              -77.137291,
              40.069938
            ],
            [
              -77.092077,
              40.053748
            ],
            [
              -77.069946,
              40.029871
            ],
            [
              -77.054441,
              40.02319
            ],
            [
              -77.015353,
              40.073509
            ],
            [
              -76.968122,
              40.044837
            ],
            [
              -76.94691,
              40.022732
            ],
            [
              -76.928968,
              40.01054
            ],
            [
              -76.920678,
              40.026178
            ],
            [
              -76.901607,
              40.02581
            ],
            [
              -76.906584,
              40.041026
            ],
            [
              -76.881397,
              40.051558
            ],
            [
              -76.881799,
              40.061695
            ],
            [
              -76.860293,
              40.071467
            ],
            [
              -76.865065,
              40.082176
            ],
            [
              -76.838497,
              40.083576
            ],
            [
              -76.823633,
              40.095813
            ],
            [
              -76.802522,
              40.098701
            ],
            [
              -76.794288,
              40.119285
            ],
            [
              -76.783103,
              40.121184
            ],
            [
              -76.758084,
              40.14627
            ],
            [
              -76.745526,
              40.146013
            ],
            [
              -76.743947,
              40.132438
            ],
            [
              -76.721368,
              40.120837
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu12"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Lancaster-Lebanon IU 13",
        "teachers": 5874.21
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -76.721368,
              40.120837
            ],
            [
              -76.715709,
              40.13187
            ],
            [
              -76.6962,
              40.150215
            ],
            [
              -76.676235,
              40.153317
            ],
            [
              -76.666396,
              40.163017
            ],
            [
              -76.644134,
              40.164805
            ],
            [
              -76.606315,
              40.190573
            ],
            [
              -76.586692,
              40.1894
            ],
            [
              -76.568969,
              40.194938
            ],
            [
              -76.616816,
              40.316071
            ],
            [
              -76.649679,
              40.403768
            ],
            [
              -76.680733,
              40.474771
            ],
            [
              -76.535186,
              40.555228
            ],
            [
              -76.440051,
              40.495856
            ],
            [
              -76.231794,
              40.366003
            ],
            [
              -76.198645,
              40.354921
            ],
            [
              -76.151129,
              40.31591
            ],
            [
              -75.873079,
              40.136904
            ],
            [
              -75.94356,
              40.107805
            ],
            [
              -75.936714,
              40.08053
            ],
            [
              -75.935171,
              40.047071
            ],
            [
              -75.93478,
              40.038591
            ],
            [
              -75.971467,
              39.988236
            ],
            [
              -76.026648,
              39.980701
            ],
            [
              -76.071814,
              39.969022
            ],
            [
              -76.06003,
              39.951707
            ],
            [
              -76.054652,
              39.93317
            ],
            [
              -76.03898,
              39.923192
            ],
            [
              -76.019238,
              39.894734
            ],
            [
              -76.000341,
              39.874723
            ],
            [
              -76.007602,
              39.852228
            ],
            [
              -76.032008,
              39.819273
            ],
            [
              -76.0309,
              39.812073
            ],
            [
              -76.064645,
              39.771164
            ],
            [
              -76.081742,
              39.761431
            ],
            [
              -76.084932,
              39.747315
            ],
            [
              -76.102033,
              39.72413
            ],
            [
              -76.119815,
              39.736416
            ],
            [
              -76.135534,
              39.721502
            ],
            [
              -76.240763,
              39.721785
            ],
            [
              -76.240231,
              39.728688
            ],
            [
              -76.26721,
              39.758241
            ],
            [
              -76.276414,
              39.775145
            ],
            [
              -76.293926,
              39.7836
            ],
            [
              -76.316034,
              39.810478
            ],
            [
              -76.336932,
              39.822207
            ],
            [
              -76.351194,
              39.847896
            ],
            [
              -76.378558,
              39.868715
            ],
            [
              -76.379188,
              39.888323
            ],
            [
              -76.390309,
              39.912333
            ],
            [
              -76.467923,
              39.944262
            ],
            [
              -76.488212,
              39.965392
            ],
            [
              -76.497288,
              39.996864
            ],
            [
              -76.525452,
              40.025975
            ],
            [
              -76.533174,
              40.051513
            ],
            [
              -76.572557,
              40.046408
            ],
            [
              -76.597643,
              40.052594
            ],
            [
              -76.644674,
              40.058692
            ],
            [
              -76.664154,
              40.063929
            ],
            [
              -76.697386,
              40.103379
            ],
            [
              -76.721368,
              40.120837
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu13"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Berks County IU 14",
        "teachers": 4470.79
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -75.696759,
              40.24191
            ],
            [
              -75.756999,
              40.210722
            ],
            [
              -75.821375,
              40.169638
            ],
            [
              -75.800363,
              40.140924
            ],
            [
              -75.800281,
              40.131121
            ],
            [
              -75.782015,
              40.114463
            ],
            [
              -75.833665,
              40.073551
            ],
            [
              -75.841075,
              40.056894
            ],
            [
              -75.865843,
              40.059195
            ],
            [
              -75.935171,
              40.047071
            ],
            [
              -75.936714,
              40.08053
            ],
            [
              -75.94356,
              40.107805
            ],
            [
              -75.873079,
              40.136904
            ],
            [
              -76.151129,
              40.31591
            ],
            [
              -76.198645,
              40.354921
            ],
            [
              -76.231794,
              40.366003
            ],
            [
              -76.440051,
              40.495856
            ],
            [
              -76.408822,
              40.497649
            ],
            [
              -76.370257,
              40.509278
            ],
            [
              -76.336987,
              40.51564
            ],
            [
              -76.301195,
              40.51472
            ],
            [
              -76.255867,
              40.517042
            ],
            [
              -76.218039,
              40.529038
            ],
            [
              -76.171629,
              40.534917
            ],
            [
              -76.155172,
              40.545305
            ],
            [
              -76.117282,
              40.558639
            ],
            [
              -76.079575,
              40.566098
            ],
            [
              -76.065559,
              40.579623
            ],
            [
              -76.049731,
              40.576124
            ],
            [
              -76.039607,
              40.583298
            ],
            [
              -76.02115,
              40.573701
            ],
            [
              -76.012245,
              40.577641
            ],
            [
              -75.993583,
              40.638397
            ],
            [
              -75.891916,
              40.677177
            ],
            [
              -75.822558,
              40.6319
            ],
            [
              -75.694192,
              40.551264
            ],
            [
              -75.589841,
              40.484664
            ],
            [
              -75.637146,
              40.439876
            ],
            [
              -75.627882,
              40.420594
            ],
            [
              -75.60122,
              40.431242
            ],
            [
              -75.562176,
              40.411894
            ],
            [
              -75.569268,
              40.397936
            ],
            [
              -75.535982,
              40.381736
            ],
            [
              -75.545902,
              40.370733
            ],
            [
              -75.481835,
              40.336426
            ],
            [
              -75.471869,
              40.31439
            ],
            [
              -75.519062,
              40.271266
            ],
            [
              -75.535695,
              40.280509
            ],
            [
              -75.560192,
              40.257184
            ],
            [
              -75.599882,
              40.281081
            ],
            [
              -75.645237,
              40.304955
            ],
            [
              -75.696759,
              40.24191
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu14"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Capital Area IU 15",
        "teachers": 5673.74
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -76.680733,
              40.474771
            ],
            [
              -76.649679,
              40.403768
            ],
            [
              -76.616816,
              40.316071
            ],
            [
              -76.568969,
              40.194938
            ],
            [
              -76.586692,
              40.1894
            ],
            [
              -76.606315,
              40.190573
            ],
            [
              -76.644134,
              40.164805
            ],
            [
              -76.666396,
              40.163017
            ],
            [
              -76.676235,
              40.153317
            ],
            [
              -76.6962,
              40.150215
            ],
            [
              -76.715709,
              40.13187
            ],
            [
              -76.721368,
              40.120837
            ],
            [
              -76.743947,
              40.132438
            ],
            [
              -76.745526,
              40.146013
            ],
            [
              -76.758084,
              40.14627
            ],
            [
              -76.783103,
              40.121184
            ],
            [
              -76.794288,
              40.119285
            ],
            [
              -76.802522,
              40.098701
            ],
            [
              -76.823633,
              40.095813
            ],
            [
              -76.838497,
              40.083576
            ],
            [
              -76.865065,
              40.082176
            ],
            [
              -76.860293,
              40.071467
            ],
            [
              -76.881799,
              40.061695
            ],
            [
              -76.881397,
              40.051558
            ],
            [
              -76.906584,
              40.041026
            ],
            [
              -76.901607,
              40.02581
            ],
            [
              -76.920678,
              40.026178
            ],
            [
              -76.928968,
              40.01054
            ],
            [
              -76.94691,
              40.022732
            ],
            [
              -76.968122,
              40.044837
            ],
            [
              -77.015353,
              40.073509
            ],
            [
              -77.054441,
              40.02319
            ],
            [
              -77.069946,
              40.029871
            ],
            [
              -77.092077,
              40.053748
            ],
            [
              -77.137291,
              40.069938
            ],
            [
              -77.16039,
              40.053302
            ],
            [
              -77.185605,
              40.029534
            ],
            [
              -77.228922,
              40.025144
            ],
            [
              -77.403497,
              39.99454
            ],
            [
              -77.470795,
              39.944171
            ],
            [
              -77.585763,
              40.009936
            ],
            [
              -77.619282,
              40.034882
            ],
            [
              -77.630923,
              40.048342
            ],
            [
              -77.623512,
              40.058968
            ],
            [
              -77.592896,
              40.073871
            ],
            [
              -77.599166,
              40.082827
            ],
            [
              -77.579108,
              40.087856
            ],
            [
              -77.559247,
              40.105331
            ],
            [
              -77.568485,
              40.126024
            ],
            [
              -77.608269,
              40.15268
            ],
            [
              -77.627264,
              40.188753
            ],
            [
              -77.604768,
              40.206033
            ],
            [
              -77.603952,
              40.229031
            ],
            [
              -77.619256,
              40.229484
            ],
            [
              -77.642145,
              40.217472
            ],
            [
              -77.649951,
              40.226283
            ],
            [
              -77.647128,
              40.256872
            ],
            [
              -77.671702,
              40.257141
            ],
            [
              -77.64598,
              40.282736
            ],
            [
              -77.577121,
              40.334307
            ],
            [
              -77.593606,
              40.351404
            ],
            [
              -77.563381,
              40.368348
            ],
            [
              -77.532248,
              40.375918
            ],
            [
              -77.540219,
              40.399237
            ],
            [
              -77.478081,
              40.432054
            ],
            [
              -77.411607,
              40.461313
            ],
            [
              -77.306345,
              40.510024
            ],
            [
              -77.265514,
              40.526989
            ],
            [
              -77.198625,
              40.552131
            ],
            [
              -77.167691,
              40.559444
            ],
            [
              -77.182958,
              40.582136
            ],
            [
              -77.163217,
              40.586865
            ],
            [
              -77.174658,
              40.633041
            ],
            [
              -77.116791,
              40.642519
            ],
            [
              -77.063896,
              40.654018
            ],
            [
              -77.063586,
              40.599637
            ],
            [
              -77.023735,
              40.604272
            ],
            [
              -76.956184,
              40.627937
            ],
            [
              -76.948203,
              40.628586
            ],
            [
              -76.936721,
              40.612772
            ],
            [
              -76.924791,
              40.608413
            ],
            [
              -76.892148,
              40.610112
            ],
            [
              -76.872804,
              40.616422
            ],
            [
              -76.866064,
              40.626972
            ],
            [
              -76.840958,
              40.625337
            ],
            [
              -76.828307,
              40.63357
            ],
            [
              -76.810603,
              40.634062
            ],
            [
              -76.777829,
              40.646044
            ],
            [
              -76.756216,
              40.648098
            ],
            [
              -76.744718,
              40.654723
            ],
            [
              -76.7217,
              40.653446
            ],
            [
              -76.701582,
              40.657495
            ],
            [
              -76.639574,
              40.619801
            ],
            [
              -76.689002,
              40.593725
            ],
            [
              -76.756773,
              40.583127
            ],
            [
              -76.756473,
              40.575059
            ],
            [
              -76.735622,
              40.570332
            ],
            [
              -76.731673,
              40.55171
            ],
            [
              -76.710423,
              40.555918
            ],
            [
              -76.710581,
              40.570337
            ],
            [
              -76.656135,
              40.565543
            ],
            [
              -76.576917,
              40.580737
            ],
            [
              -76.56247,
              40.571911
            ],
            [
              -76.600657,
              40.553218
            ],
            [
              -76.658675,
              40.513299
            ],
            [
              -76.711643,
              40.485996
            ],
            [
              -76.780279,
              40.459657
            ],
            [
              -76.768249,
              40.436782
            ],
            [
              -76.718268,
              40.455598
            ],
            [
              -76.680733,
              40.474771
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu15"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Central Susquehanna IU 16",
        "teachers": 2231.97
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -76.310902,
              41.310196
            ],
            [
              -76.32114,
              41.211722
            ],
            [
              -76.278098,
              41.132116
            ],
            [
              -76.228872,
              41.137923
            ],
            [
              -76.179494,
              41.162134
            ],
            [
              -76.156819,
              41.154946
            ],
            [
              -76.146229,
              41.144395
            ],
            [
              -76.127526,
              41.098971
            ],
            [
              -76.130648,
              41.077128
            ],
            [
              -76.063423,
              41.108787
            ],
            [
              -76.0276,
              41.055064
            ],
            [
              -76.039985,
              41.050827
            ],
            [
              -76.058247,
              41.056279
            ],
            [
              -76.061318,
              41.043785
            ],
            [
              -76.102873,
              41.031033
            ],
            [
              -76.156856,
              41.019651
            ],
            [
              -76.180156,
              41.01202
            ],
            [
              -76.22156,
              41.004608
            ],
            [
              -76.208613,
              40.949923
            ],
            [
              -76.283658,
              40.885603
            ],
            [
              -76.298514,
              40.833658
            ],
            [
              -76.322461,
              40.854374
            ],
            [
              -76.329789,
              40.85187
            ],
            [
              -76.409052,
              40.842687
            ],
            [
              -76.409663,
              40.833622
            ],
            [
              -76.380321,
              40.775344
            ],
            [
              -76.467138,
              40.743304
            ],
            [
              -76.554802,
              40.712647
            ],
            [
              -76.672664,
              40.669844
            ],
            [
              -76.701582,
              40.657495
            ],
            [
              -76.7217,
              40.653446
            ],
            [
              -76.744718,
              40.654723
            ],
            [
              -76.756216,
              40.648098
            ],
            [
              -76.777829,
              40.646044
            ],
            [
              -76.810603,
              40.634062
            ],
            [
              -76.828307,
              40.63357
            ],
            [
              -76.840958,
              40.625337
            ],
            [
              -76.866064,
              40.626972
            ],
            [
              -76.872804,
              40.616422
            ],
            [
              -76.892148,
              40.610112
            ],
            [
              -76.924791,
              40.608413
            ],
            [
              -76.936721,
              40.612772
            ],
            [
              -76.948203,
              40.628586
            ],
            [
              -76.939188,
              40.638561
            ],
            [
              -76.946002,
              40.650265
            ],
            [
              -76.968159,
              40.641221
            ],
            [
              -76.987167,
              40.643833
            ],
            [
              -77.003382,
              40.638889
            ],
            [
              -77.010464,
              40.661495
            ],
            [
              -77.032702,
              40.664334
            ],
            [
              -77.035777,
              40.676919
            ],
            [
              -77.068322,
              40.679801
            ],
            [
              -77.094366,
              40.676428
            ],
            [
              -77.11039,
              40.691486
            ],
            [
              -77.140096,
              40.683543
            ],
            [
              -77.1503,
              40.677044
            ],
            [
              -77.17128,
              40.679055
            ],
            [
              -77.354316,
              40.701873
            ],
            [
              -77.357319,
              40.844259
            ],
            [
              -77.364171,
              40.84694
            ],
            [
              -77.279223,
              40.909717
            ],
            [
              -77.203089,
              40.993708
            ],
            [
              -77.143461,
              41.042971
            ],
            [
              -77.145301,
              41.068922
            ],
            [
              -77.112806,
              41.069422
            ],
            [
              -77.068365,
              41.085575
            ],
            [
              -76.976465,
              41.086976
            ],
            [
              -76.960227,
              41.148801
            ],
            [
              -76.895562,
              41.13899
            ],
            [
              -76.880884,
              41.159804
            ],
            [
              -76.829626,
              41.165666
            ],
            [
              -76.790826,
              41.175732
            ],
            [
              -76.729364,
              41.171763
            ],
            [
              -76.678672,
              41.154229
            ],
            [
              -76.59082,
              41.157206
            ],
            [
              -76.561484,
              41.18255
            ],
            [
              -76.4518,
              41.272289
            ],
            [
              -76.405404,
              41.308356
            ],
            [
              -76.310902,
              41.310196
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu16"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "BlaST IU 17",
        "teachers": 2205.6
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -76.145519,
              41.998842
            ],
            [
              -76.121268,
              41.730558
            ],
            [
              -76.113562,
              41.635387
            ],
            [
              -76.122154,
              41.611106
            ],
            [
              -76.10089,
              41.605913
            ],
            [
              -76.087527,
              41.597022
            ],
            [
              -76.096502,
              41.577873
            ],
            [
              -76.123266,
              41.579897
            ],
            [
              -76.139175,
              41.594969
            ],
            [
              -76.165059,
              41.593452
            ],
            [
              -76.187292,
              41.604368
            ],
            [
              -76.19747,
              41.585048
            ],
            [
              -76.18966,
              41.574375
            ],
            [
              -76.213505,
              41.570578
            ],
            [
              -76.228131,
              41.511137
            ],
            [
              -76.276953,
              41.39116
            ],
            [
              -76.310902,
              41.310196
            ],
            [
              -76.405404,
              41.308356
            ],
            [
              -76.4518,
              41.272289
            ],
            [
              -76.561484,
              41.18255
            ],
            [
              -76.59082,
              41.157206
            ],
            [
              -76.678672,
              41.154229
            ],
            [
              -76.729364,
              41.171763
            ],
            [
              -76.790826,
              41.175732
            ],
            [
              -76.829626,
              41.165666
            ],
            [
              -76.880884,
              41.159804
            ],
            [
              -76.895562,
              41.13899
            ],
            [
              -76.960227,
              41.148801
            ],
            [
              -76.976465,
              41.086976
            ],
            [
              -77.068365,
              41.085575
            ],
            [
              -77.112806,
              41.069422
            ],
            [
              -77.145301,
              41.068922
            ],
            [
              -77.165153,
              41.068612
            ],
            [
              -77.175041,
              41.078277
            ],
            [
              -77.308546,
              41.077006
            ],
            [
              -77.297373,
              41.130511
            ],
            [
              -77.286218,
              41.142353
            ],
            [
              -77.252001,
              41.153218
            ],
            [
              -77.2695,
              41.169992
            ],
            [
              -77.285055,
              41.166135
            ],
            [
              -77.313569,
              41.17011
            ],
            [
              -77.327636,
              41.163748
            ],
            [
              -77.34579,
              41.189695
            ],
            [
              -77.328896,
              41.206204
            ],
            [
              -77.325775,
              41.219725
            ],
            [
              -77.34256,
              41.220687
            ],
            [
              -77.349663,
              41.228672
            ],
            [
              -77.370131,
              41.232972
            ],
            [
              -77.399047,
              41.25307
            ],
            [
              -77.429565,
              41.282558
            ],
            [
              -77.442603,
              41.286285
            ],
            [
              -77.455005,
              41.303364
            ],
            [
              -77.471922,
              41.31438
            ],
            [
              -77.476223,
              41.338503
            ],
            [
              -77.499264,
              41.353662
            ],
            [
              -77.526182,
              41.3585
            ],
            [
              -77.539815,
              41.367684
            ],
            [
              -77.542011,
              41.382219
            ],
            [
              -77.563527,
              41.41203
            ],
            [
              -77.575199,
              41.420525
            ],
            [
              -77.584605,
              41.440871
            ],
            [
              -77.59516,
              41.440596
            ],
            [
              -77.59927,
              41.542264
            ],
            [
              -77.462926,
              41.54351
            ],
            [
              -77.459234,
              41.632986
            ],
            [
              -77.458491,
              41.689128
            ],
            [
              -77.490853,
              41.688862
            ],
            [
              -77.492635,
              41.810208
            ],
            [
              -77.605474,
              41.809237
            ],
            [
              -77.610003,
              41.999418
            ],
            [
              -77.392812,
              42.000475
            ],
            [
              -77.303019,
              41.999824
            ],
            [
              -77.103055,
              41.999637
            ],
            [
              -76.868816,
              42.002439
            ],
            [
              -76.638106,
              42.000667
            ],
            [
              -76.390768,
              41.998462
            ],
            [
              -76.145519,
              41.998842
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu17"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Luzerne IU 18",
        "teachers": 2739.91
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -76.310902,
              41.310196
            ],
            [
              -76.276953,
              41.39116
            ],
            [
              -76.228131,
              41.511137
            ],
            [
              -76.213505,
              41.570578
            ],
            [
              -76.18966,
              41.574375
            ],
            [
              -76.19747,
              41.585048
            ],
            [
              -76.187292,
              41.604368
            ],
            [
              -76.165059,
              41.593452
            ],
            [
              -76.139175,
              41.594969
            ],
            [
              -76.123266,
              41.579897
            ],
            [
              -76.096502,
              41.577873
            ],
            [
              -76.087527,
              41.597022
            ],
            [
              -76.068578,
              41.612143
            ],
            [
              -76.048723,
              41.607338
            ],
            [
              -76.050187,
              41.596068
            ],
            [
              -76.009903,
              41.597147
            ],
            [
              -76.001668,
              41.631143
            ],
            [
              -75.983869,
              41.644715
            ],
            [
              -75.889581,
              41.64358
            ],
            [
              -75.874315,
              41.604442
            ],
            [
              -75.855561,
              41.605549
            ],
            [
              -75.860608,
              41.57494
            ],
            [
              -75.8439,
              41.57006
            ],
            [
              -75.839349,
              41.541451
            ],
            [
              -75.789362,
              41.541261
            ],
            [
              -75.803232,
              41.524235
            ],
            [
              -75.771081,
              41.505463
            ],
            [
              -75.834557,
              41.42692
            ],
            [
              -75.832664,
              41.400482
            ],
            [
              -75.820344,
              41.387872
            ],
            [
              -75.801765,
              41.383803
            ],
            [
              -75.77513,
              41.37032
            ],
            [
              -75.741499,
              41.348194
            ],
            [
              -75.687245,
              41.339216
            ],
            [
              -75.689496,
              41.28777
            ],
            [
              -75.679368,
              41.288111
            ],
            [
              -75.678045,
              41.264358
            ],
            [
              -75.652923,
              41.258314
            ],
            [
              -75.649151,
              41.187401
            ],
            [
              -75.631006,
              41.169424
            ],
            [
              -75.616598,
              41.172611
            ],
            [
              -75.600673,
              41.161782
            ],
            [
              -75.615588,
              41.150431
            ],
            [
              -75.632607,
              41.127979
            ],
            [
              -75.648986,
              41.122209
            ],
            [
              -75.666798,
              41.133826
            ],
            [
              -75.690453,
              41.131279
            ],
            [
              -75.708316,
              41.117969
            ],
            [
              -75.718658,
              41.117993
            ],
            [
              -75.732962,
              41.102789
            ],
            [
              -75.753581,
              41.103233
            ],
            [
              -75.772056,
              41.076892
            ],
            [
              -75.770209,
              41.049921
            ],
            [
              -75.756429,
              41.031391
            ],
            [
              -75.739372,
              41.028817
            ],
            [
              -75.733069,
              41.00917
            ],
            [
              -75.801945,
              40.98302
            ],
            [
              -75.819581,
              40.982276
            ],
            [
              -75.849636,
              40.971657
            ],
            [
              -75.860598,
              40.962098
            ],
            [
              -75.84727,
              40.942832
            ],
            [
              -75.977503,
              40.895992
            ],
            [
              -75.957726,
              40.878682
            ],
            [
              -75.977137,
              40.867949
            ],
            [
              -76.026332,
              40.861364
            ],
            [
              -76.034495,
              40.871744
            ],
            [
              -76.1092,
              40.856772
            ],
            [
              -76.120021,
              40.868127
            ],
            [
              -76.172738,
              40.848944
            ],
            [
              -76.195503,
              40.865996
            ],
            [
              -76.207037,
              40.885461
            ],
            [
              -76.283658,
              40.885603
            ],
            [
              -76.208613,
              40.949923
            ],
            [
              -76.22156,
              41.004608
            ],
            [
              -76.180156,
              41.01202
            ],
            [
              -76.156856,
              41.019651
            ],
            [
              -76.102873,
              41.031033
            ],
            [
              -76.061318,
              41.043785
            ],
            [
              -76.058247,
              41.056279
            ],
            [
              -76.039985,
              41.050827
            ],
            [
              -76.0276,
              41.055064
            ],
            [
              -76.063423,
              41.108787
            ],
            [
              -76.130648,
              41.077128
            ],
            [
              -76.127526,
              41.098971
            ],
            [
              -76.146229,
              41.144395
            ],
            [
              -76.156819,
              41.154946
            ],
            [
              -76.179494,
              41.162134
            ],
            [
              -76.228872,
              41.137923
            ],
            [
              -76.278098,
              41.132116
            ],
            [
              -76.32114,
              41.211722
            ],
            [
              -76.310902,
              41.310196
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu18"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Northeastern Educational IU 19",
        "teachers": 3130.34
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -76.087527,
              41.597022
            ],
            [
              -76.10089,
              41.605913
            ],
            [
              -76.122154,
              41.611106
            ],
            [
              -76.113562,
              41.635387
            ],
            [
              -76.121268,
              41.730558
            ],
            [
              -76.145519,
              41.998842
            ],
            [
              -75.953745,
              41.998867
            ],
            [
              -75.764666,
              41.997729
            ],
            [
              -75.579042,
              41.999203
            ],
            [
              -75.359571,
              41.99952
            ],
            [
              -75.341079,
              41.992856
            ],
            [
              -75.341591,
              41.972216
            ],
            [
              -75.329089,
              41.968577
            ],
            [
              -75.310941,
              41.949103
            ],
            [
              -75.291385,
              41.952176
            ],
            [
              -75.278709,
              41.938523
            ],
            [
              -75.267734,
              41.907868
            ],
            [
              -75.27136,
              41.887513
            ],
            [
              -75.260165,
              41.883302
            ],
            [
              -75.257584,
              41.861957
            ],
            [
              -75.241147,
              41.867252
            ],
            [
              -75.223166,
              41.857916
            ],
            [
              -75.214781,
              41.867483
            ],
            [
              -75.176318,
              41.872423
            ],
            [
              -75.157655,
              41.848692
            ],
            [
              -75.139876,
              41.852058
            ],
            [
              -75.113332,
              41.841667
            ],
            [
              -75.112677,
              41.822395
            ],
            [
              -75.090246,
              41.811793
            ],
            [
              -75.072248,
              41.814131
            ],
            [
              -75.07907,
              41.79704
            ],
            [
              -75.092638,
              41.79666
            ],
            [
              -75.103948,
              41.78015
            ],
            [
              -75.101895,
              41.769609
            ],
            [
              -75.07577,
              41.771667
            ],
            [
              -75.053197,
              41.752425
            ],
            [
              -75.054721,
              41.736487
            ],
            [
              -75.049644,
              41.713632
            ],
            [
              -75.068917,
              41.709794
            ],
            [
              -75.053045,
              41.689189
            ],
            [
              -75.059425,
              41.673537
            ],
            [
              -75.050207,
              41.662534
            ],
            [
              -75.044854,
              41.616617
            ],
            [
              -75.074475,
              41.608125
            ],
            [
              -75.060612,
              41.59138
            ],
            [
              -75.047246,
              41.583682
            ],
            [
              -75.040458,
              41.569617
            ],
            [
              -75.028435,
              41.564603
            ],
            [
              -75.016466,
              41.543346
            ],
            [
              -75.024638,
              41.535011
            ],
            [
              -75.008017,
              41.527474
            ],
            [
              -75.000733,
              41.507386
            ],
            [
              -74.987491,
              41.508819
            ],
            [
              -74.981804,
              41.479949
            ],
            [
              -74.966274,
              41.476964
            ],
            [
              -74.99067,
              41.392843
            ],
            [
              -74.97131,
              41.389554
            ],
            [
              -74.976021,
              41.37929
            ],
            [
              -75.005491,
              41.369956
            ],
            [
              -75.002414,
              41.354011
            ],
            [
              -75.01609,
              41.342898
            ],
            [
              -75.045419,
              41.289821
            ],
            [
              -75.116078,
              41.293566
            ],
            [
              -75.130102,
              41.252006
            ],
            [
              -75.366714,
              41.238373
            ],
            [
              -75.508206,
              41.232438
            ],
            [
              -75.516976,
              41.219343
            ],
            [
              -75.540018,
              41.225376
            ],
            [
              -75.562045,
              41.205552
            ],
            [
              -75.575896,
              41.180301
            ],
            [
              -75.600673,
              41.161782
            ],
            [
              -75.616598,
              41.172611
            ],
            [
              -75.631006,
              41.169424
            ],
            [
              -75.649151,
              41.187401
            ],
            [
              -75.652923,
              41.258314
            ],
            [
              -75.678045,
              41.264358
            ],
            [
              -75.679368,
              41.288111
            ],
            [
              -75.689496,
              41.28777
            ],
            [
              -75.687245,
              41.339216
            ],
            [
              -75.741499,
              41.348194
            ],
            [
              -75.77513,
              41.37032
            ],
            [
              -75.801765,
              41.383803
            ],
            [
              -75.820344,
              41.387872
            ],
            [
              -75.832664,
              41.400482
            ],
            [
              -75.834557,
              41.42692
            ],
            [
              -75.771081,
              41.505463
            ],
            [
              -75.803232,
              41.524235
            ],
            [
              -75.789362,
              41.541261
            ],
            [
              -75.839349,
              41.541451
            ],
            [
              -75.8439,
              41.57006
            ],
            [
              -75.860608,
              41.57494
            ],
            [
              -75.855561,
              41.605549
            ],
            [
              -75.874315,
              41.604442
            ],
            [
              -75.889581,
              41.64358
            ],
            [
              -75.983869,
              41.644715
            ],
            [
              -76.001668,
              41.631143
            ],
            [
              -76.009903,
              41.597147
            ],
            [
              -76.050187,
              41.596068
            ],
            [
              -76.048723,
              41.607338
            ],
            [
              -76.068578,
              41.612143
            ],
            [
              -76.087527,
              41.597022
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu19"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Colonial IU 20",
        "teachers": 5079.53
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -74.966274,
              41.476964
            ],
            [
              -74.943415,
              41.483681
            ],
            [
              -74.909543,
              41.473235
            ],
            [
              -74.906553,
              41.460876
            ],
            [
              -74.889098,
              41.45143
            ],
            [
              -74.895128,
              41.439335
            ],
            [
              -74.853909,
              41.443146
            ],
            [
              -74.835857,
              41.431095
            ],
            [
              -74.812488,
              41.442802
            ],
            [
              -74.801403,
              41.43867
            ],
            [
              -74.794545,
              41.423102
            ],
            [
              -74.758251,
              41.423364
            ],
            [
              -74.741456,
              41.431154
            ],
            [
              -74.734863,
              41.421904
            ],
            [
              -74.741735,
              41.408777
            ],
            [
              -74.73601,
              41.39833
            ],
            [
              -74.71948,
              41.394528
            ],
            [
              -74.689754,
              41.361747
            ],
            [
              -74.725951,
              41.346192
            ],
            [
              -74.752398,
              41.346536
            ],
            [
              -74.772657,
              41.324781
            ],
            [
              -74.791584,
              41.323019
            ],
            [
              -74.792616,
              41.310436
            ],
            [
              -74.821211,
              41.294313
            ],
            [
              -74.837821,
              41.278015
            ],
            [
              -74.867574,
              41.229169
            ],
            [
              -74.859191,
              41.219973
            ],
            [
              -74.882331,
              41.180821
            ],
            [
              -74.900738,
              41.165189
            ],
            [
              -74.923149,
              41.138527
            ],
            [
              -74.944498,
              41.129974
            ],
            [
              -74.964699,
              41.114053
            ],
            [
              -74.981783,
              41.108739
            ],
            [
              -74.991473,
              41.092932
            ],
            [
              -74.971102,
              41.085558
            ],
            [
              -75.013458,
              41.065955
            ],
            [
              -75.027006,
              41.039834
            ],
            [
              -75.072893,
              41.017909
            ],
            [
              -75.082008,
              41.016654
            ],
            [
              -75.127089,
              40.993998
            ],
            [
              -75.135504,
              40.973294
            ],
            [
              -75.122925,
              40.96997
            ],
            [
              -75.118459,
              40.954662
            ],
            [
              -75.096141,
              40.924589
            ],
            [
              -75.078763,
              40.913385
            ],
            [
              -75.075252,
              40.893942
            ],
            [
              -75.052058,
              40.87181
            ],
            [
              -75.064307,
              40.848516
            ],
            [
              -75.090269,
              40.849273
            ],
            [
              -75.095842,
              40.837418
            ],
            [
              -75.083536,
              40.825105
            ],
            [
              -75.099067,
              40.809181
            ],
            [
              -75.109545,
              40.790111
            ],
            [
              -75.13572,
              40.773431
            ],
            [
              -75.169134,
              40.778273
            ],
            [
              -75.177811,
              40.763308
            ],
            [
              -75.196616,
              40.748443
            ],
            [
              -75.183174,
              40.734404
            ],
            [
              -75.204062,
              40.692102
            ],
            [
              -75.19637,
              40.681049
            ],
            [
              -75.176996,
              40.676288
            ],
            [
              -75.200138,
              40.648978
            ],
            [
              -75.190385,
              40.634463
            ],
            [
              -75.189852,
              40.620359
            ],
            [
              -75.201876,
              40.616478
            ],
            [
              -75.191949,
              40.602354
            ],
            [
              -75.191291,
              40.585706
            ],
            [
              -75.207784,
              40.588639
            ],
            [
              -75.209065,
              40.603066
            ],
            [
              -75.258187,
              40.581986
            ],
            [
              -75.333048,
              40.536767
            ],
            [
              -75.404412,
              40.581154
            ],
            [
              -75.39429,
              40.593018
            ],
            [
              -75.397504,
              40.606715
            ],
            [
              -75.419352,
              40.616233
            ],
            [
              -75.422073,
              40.634358
            ],
            [
              -75.43123,
              40.644374
            ],
            [
              -75.420709,
              40.659834
            ],
            [
              -75.429769,
              40.674379
            ],
            [
              -75.466233,
              40.661278
            ],
            [
              -75.483992,
              40.666871
            ],
            [
              -75.517214,
              40.704306
            ],
            [
              -75.528089,
              40.735018
            ],
            [
              -75.556702,
              40.72079
            ],
            [
              -75.571309,
              40.733212
            ],
            [
              -75.588314,
              40.733776
            ],
            [
              -75.593406,
              40.764228
            ],
            [
              -75.602521,
              40.765281
            ],
            [
              -75.609083,
              40.787454
            ],
            [
              -75.579298,
              40.798963
            ],
            [
              -75.54243,
              40.806753
            ],
            [
              -75.472506,
              40.815004
            ],
            [
              -75.531944,
              40.898537
            ],
            [
              -75.568304,
              40.945746
            ],
            [
              -75.534949,
              40.958985
            ],
            [
              -75.541091,
              40.968803
            ],
            [
              -75.496292,
              40.988946
            ],
            [
              -75.546446,
              41.034135
            ],
            [
              -75.580812,
              41.067637
            ],
            [
              -75.606149,
              41.086216
            ],
            [
              -75.625124,
              41.111379
            ],
            [
              -75.646541,
              41.11332
            ],
            [
              -75.648986,
              41.122209
            ],
            [
              -75.632607,
              41.127979
            ],
            [
              -75.615588,
              41.150431
            ],
            [
              -75.600673,
              41.161782
            ],
            [
              -75.575896,
              41.180301
            ],
            [
              -75.562045,
              41.205552
            ],
            [
              -75.540018,
              41.225376
            ],
            [
              -75.516976,
              41.219343
            ],
            [
              -75.508206,
              41.232438
            ],
            [
              -75.366714,
              41.238373
            ],
            [
              -75.130102,
              41.252006
            ],
            [
              -75.116078,
              41.293566
            ],
            [
              -75.045419,
              41.289821
            ],
            [
              -75.01609,
              41.342898
            ],
            [
              -75.002414,
              41.354011
            ],
            [
              -75.005491,
              41.369956
            ],
            [
              -74.976021,
              41.37929
            ],
            [
              -74.97131,
              41.389554
            ],
            [
              -74.99067,
              41.392843
            ],
            [
              -74.966274,
              41.476964
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu20"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Carbon-Lehigh IU 21",
        "teachers": 3617.66
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -75.333048,
              40.536767
            ],
            [
              -75.409735,
              40.487985
            ],
            [
              -75.483927,
              40.418485
            ],
            [
              -75.589841,
              40.484664
            ],
            [
              -75.694192,
              40.551264
            ],
            [
              -75.822558,
              40.6319
            ],
            [
              -75.891916,
              40.677177
            ],
            [
              -75.854819,
              40.693191
            ],
            [
              -75.844672,
              40.693001
            ],
            [
              -75.807796,
              40.707493
            ],
            [
              -75.777694,
              40.729535
            ],
            [
              -75.757809,
              40.735415
            ],
            [
              -75.828686,
              40.780205
            ],
            [
              -75.890155,
              40.821727
            ],
            [
              -75.934717,
              40.805093
            ],
            [
              -75.936455,
              40.818716
            ],
            [
              -75.906012,
              40.833934
            ],
            [
              -75.957726,
              40.878682
            ],
            [
              -75.977503,
              40.895992
            ],
            [
              -75.84727,
              40.942832
            ],
            [
              -75.860598,
              40.962098
            ],
            [
              -75.849636,
              40.971657
            ],
            [
              -75.819581,
              40.982276
            ],
            [
              -75.801945,
              40.98302
            ],
            [
              -75.733069,
              41.00917
            ],
            [
              -75.739372,
              41.028817
            ],
            [
              -75.756429,
              41.031391
            ],
            [
              -75.770209,
              41.049921
            ],
            [
              -75.772056,
              41.076892
            ],
            [
              -75.753581,
              41.103233
            ],
            [
              -75.732962,
              41.102789
            ],
            [
              -75.718658,
              41.117993
            ],
            [
              -75.708316,
              41.117969
            ],
            [
              -75.690453,
              41.131279
            ],
            [
              -75.666798,
              41.133826
            ],
            [
              -75.648986,
              41.122209
            ],
            [
              -75.646541,
              41.11332
            ],
            [
              -75.625124,
              41.111379
            ],
            [
              -75.606149,
              41.086216
            ],
            [
              -75.580812,
              41.067637
            ],
            [
              -75.546446,
              41.034135
            ],
            [
              -75.496292,
              40.988946
            ],
            [
              -75.541091,
              40.968803
            ],
            [
              -75.534949,
              40.958985
            ],
            [
              -75.568304,
              40.945746
            ],
            [
              -75.531944,
              40.898537
            ],
            [
              -75.472506,
              40.815004
            ],
            [
              -75.54243,
              40.806753
            ],
            [
              -75.579298,
              40.798963
            ],
            [
              -75.609083,
              40.787454
            ],
            [
              -75.602521,
              40.765281
            ],
            [
              -75.593406,
              40.764228
            ],
            [
              -75.588314,
              40.733776
            ],
            [
              -75.571309,
              40.733212
            ],
            [
              -75.556702,
              40.72079
            ],
            [
              -75.528089,
              40.735018
            ],
            [
              -75.517214,
              40.704306
            ],
            [
              -75.483992,
              40.666871
            ],
            [
              -75.466233,
              40.661278
            ],
            [
              -75.429769,
              40.674379
            ],
            [
              -75.420709,
              40.659834
            ],
            [
              -75.43123,
              40.644374
            ],
            [
              -75.422073,
              40.634358
            ],
            [
              -75.419352,
              40.616233
            ],
            [
              -75.397504,
              40.606715
            ],
            [
              -75.39429,
              40.593018
            ],
            [
              -75.404412,
              40.581154
            ],
            [
              -75.333048,
              40.536767
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu21"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Bucks County IU 22",
        "teachers": 5640.73
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -74.974392,
              40.049803
            ],
            [
              -74.980275,
              40.066203
            ],
            [
              -74.958874,
              40.082623
            ],
            [
              -74.955831,
              40.095167
            ],
            [
              -74.964903,
              40.118654
            ],
            [
              -74.982396,
              40.120419
            ],
            [
              -75.014957,
              40.137881
            ],
            [
              -75.139412,
              40.211791
            ],
            [
              -75.209973,
              40.255073
            ],
            [
              -75.317185,
              40.318485
            ],
            [
              -75.316214,
              40.33108
            ],
            [
              -75.334618,
              40.328794
            ],
            [
              -75.483927,
              40.418485
            ],
            [
              -75.409735,
              40.487985
            ],
            [
              -75.333048,
              40.536767
            ],
            [
              -75.258187,
              40.581986
            ],
            [
              -75.209065,
              40.603066
            ],
            [
              -75.207784,
              40.588639
            ],
            [
              -75.191291,
              40.585706
            ],
            [
              -75.195064,
              40.577576
            ],
            [
              -75.174429,
              40.564716
            ],
            [
              -75.15852,
              40.565184
            ],
            [
              -75.144263,
              40.574304
            ],
            [
              -75.117397,
              40.573481
            ],
            [
              -75.100836,
              40.567825
            ],
            [
              -75.068637,
              40.542247
            ],
            [
              -75.06201,
              40.488346
            ],
            [
              -75.070837,
              40.457267
            ],
            [
              -75.061957,
              40.424406
            ],
            [
              -75.055891,
              40.416439
            ],
            [
              -75.022488,
              40.404063
            ],
            [
              -74.992462,
              40.409996
            ],
            [
              -74.963633,
              40.394641
            ],
            [
              -74.948967,
              40.365566
            ],
            [
              -74.940421,
              40.338279
            ],
            [
              -74.915174,
              40.320343
            ],
            [
              -74.896666,
              40.315273
            ],
            [
              -74.870952,
              40.297893
            ],
            [
              -74.860543,
              40.285354
            ],
            [
              -74.842387,
              40.250721
            ],
            [
              -74.818541,
              40.237786
            ],
            [
              -74.793744,
              40.228863
            ],
            [
              -74.771702,
              40.21567
            ],
            [
              -74.750625,
              40.183344
            ],
            [
              -74.739892,
              40.179733
            ],
            [
              -74.723627,
              40.163681
            ],
            [
              -74.723971,
              40.147133
            ],
            [
              -74.739263,
              40.135863
            ],
            [
              -74.759572,
              40.134252
            ],
            [
              -74.781215,
              40.122402
            ],
            [
              -74.814401,
              40.12752
            ],
            [
              -74.825584,
              40.125089
            ],
            [
              -74.838025,
              40.1004
            ],
            [
              -74.860661,
              40.083942
            ],
            [
              -74.905823,
              40.071302
            ],
            [
              -74.927973,
              40.070618
            ],
            [
              -74.974392,
              40.049803
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu22"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Montgomery County IU 23",
        "teachers": 7713.52
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -75.276393,
              39.977
            ],
            [
              -75.311752,
              40.020148
            ],
            [
              -75.320683,
              40.016208
            ],
            [
              -75.360901,
              40.065558
            ],
            [
              -75.366825,
              40.072362
            ],
            [
              -75.393008,
              40.060808
            ],
            [
              -75.420112,
              40.09717
            ],
            [
              -75.43861,
              40.089153
            ],
            [
              -75.456517,
              40.087539
            ],
            [
              -75.47355,
              40.129327
            ],
            [
              -75.489814,
              40.125554
            ],
            [
              -75.510863,
              40.140361
            ],
            [
              -75.500962,
              40.154649
            ],
            [
              -75.513665,
              40.15777
            ],
            [
              -75.527624,
              40.151082
            ],
            [
              -75.531995,
              40.170669
            ],
            [
              -75.554558,
              40.175196
            ],
            [
              -75.549038,
              40.189622
            ],
            [
              -75.562334,
              40.198737
            ],
            [
              -75.574794,
              40.196025
            ],
            [
              -75.588003,
              40.215028
            ],
            [
              -75.603056,
              40.227513
            ],
            [
              -75.650773,
              40.241957
            ],
            [
              -75.670096,
              40.244823
            ],
            [
              -75.685712,
              40.236613
            ],
            [
              -75.696759,
              40.24191
            ],
            [
              -75.645237,
              40.304955
            ],
            [
              -75.599882,
              40.281081
            ],
            [
              -75.560192,
              40.257184
            ],
            [
              -75.535695,
              40.280509
            ],
            [
              -75.519062,
              40.271266
            ],
            [
              -75.471869,
              40.31439
            ],
            [
              -75.481835,
              40.336426
            ],
            [
              -75.545902,
              40.370733
            ],
            [
              -75.535982,
              40.381736
            ],
            [
              -75.569268,
              40.397936
            ],
            [
              -75.562176,
              40.411894
            ],
            [
              -75.60122,
              40.431242
            ],
            [
              -75.627882,
              40.420594
            ],
            [
              -75.637146,
              40.439876
            ],
            [
              -75.589841,
              40.484664
            ],
            [
              -75.483927,
              40.418485
            ],
            [
              -75.334618,
              40.328794
            ],
            [
              -75.316214,
              40.33108
            ],
            [
              -75.317185,
              40.318485
            ],
            [
              -75.209973,
              40.255073
            ],
            [
              -75.139412,
              40.211791
            ],
            [
              -75.014957,
              40.137881
            ],
            [
              -75.058021,
              40.106242
            ],
            [
              -75.096516,
              40.068948
            ],
            [
              -75.087434,
              40.0636
            ],
            [
              -75.10905,
              40.045577
            ],
            [
              -75.176473,
              40.08467
            ],
            [
              -75.188513,
              40.072781
            ],
            [
              -75.223671,
              40.092916
            ],
            [
              -75.264382,
              40.054075
            ],
            [
              -75.245111,
              40.037231
            ],
            [
              -75.205828,
              40.011579
            ],
            [
              -75.276393,
              39.977
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu23"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Chester County IU 24",
        "teachers": 4877.05
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -75.696759,
              40.24191
            ],
            [
              -75.685712,
              40.236613
            ],
            [
              -75.670096,
              40.244823
            ],
            [
              -75.650773,
              40.241957
            ],
            [
              -75.603056,
              40.227513
            ],
            [
              -75.588003,
              40.215028
            ],
            [
              -75.574794,
              40.196025
            ],
            [
              -75.562334,
              40.198737
            ],
            [
              -75.549038,
              40.189622
            ],
            [
              -75.554558,
              40.175196
            ],
            [
              -75.531995,
              40.170669
            ],
            [
              -75.527624,
              40.151082
            ],
            [
              -75.513665,
              40.15777
            ],
            [
              -75.500962,
              40.154649
            ],
            [
              -75.510863,
              40.140361
            ],
            [
              -75.489814,
              40.125554
            ],
            [
              -75.47355,
              40.129327
            ],
            [
              -75.456517,
              40.087539
            ],
            [
              -75.43861,
              40.089153
            ],
            [
              -75.420112,
              40.09717
            ],
            [
              -75.393008,
              40.060808
            ],
            [
              -75.366825,
              40.072362
            ],
            [
              -75.360901,
              40.065558
            ],
            [
              -75.414972,
              40.041796
            ],
            [
              -75.404333,
              40.027419
            ],
            [
              -75.45377,
              39.993745
            ],
            [
              -75.436015,
              39.985176
            ],
            [
              -75.507758,
              39.951687
            ],
            [
              -75.493173,
              39.932894
            ],
            [
              -75.483003,
              39.937519
            ],
            [
              -75.47268,
              39.92398
            ],
            [
              -75.552118,
              39.887637
            ],
            [
              -75.535187,
              39.866053
            ],
            [
              -75.559245,
              39.849649
            ],
            [
              -75.552507,
              39.839438
            ],
            [
              -75.613412,
              39.834856
            ],
            [
              -75.646495,
              39.827006
            ],
            [
              -75.682725,
              39.812891
            ],
            [
              -75.708042,
              39.798522
            ],
            [
              -75.741915,
              39.770355
            ],
            [
              -75.762887,
              39.743269
            ],
            [
              -75.773611,
              39.722178
            ],
            [
              -76.135534,
              39.721502
            ],
            [
              -76.119815,
              39.736416
            ],
            [
              -76.102033,
              39.72413
            ],
            [
              -76.084932,
              39.747315
            ],
            [
              -76.081742,
              39.761431
            ],
            [
              -76.064645,
              39.771164
            ],
            [
              -76.0309,
              39.812073
            ],
            [
              -76.032008,
              39.819273
            ],
            [
              -76.007602,
              39.852228
            ],
            [
              -76.000341,
              39.874723
            ],
            [
              -76.019238,
              39.894734
            ],
            [
              -76.03898,
              39.923192
            ],
            [
              -76.054652,
              39.93317
            ],
            [
              -76.06003,
              39.951707
            ],
            [
              -76.071814,
              39.969022
            ],
            [
              -76.026648,
              39.980701
            ],
            [
              -75.971467,
              39.988236
            ],
            [
              -75.93478,
              40.038591
            ],
            [
              -75.935171,
              40.047071
            ],
            [
              -75.865843,
              40.059195
            ],
            [
              -75.841075,
              40.056894
            ],
            [
              -75.833665,
              40.073551
            ],
            [
              -75.782015,
              40.114463
            ],
            [
              -75.800281,
              40.131121
            ],
            [
              -75.800363,
              40.140924
            ],
            [
              -75.821375,
              40.169638
            ],
            [
              -75.756999,
              40.210722
            ],
            [
              -75.696759,
              40.24191
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu24"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Delaware County IU 25",
        "teachers": 4867.32
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -75.552507,
              39.839438
            ],
            [
              -75.559245,
              39.849649
            ],
            [
              -75.535187,
              39.866053
            ],
            [
              -75.552118,
              39.887637
            ],
            [
              -75.47268,
              39.92398
            ],
            [
              -75.483003,
              39.937519
            ],
            [
              -75.493173,
              39.932894
            ],
            [
              -75.507758,
              39.951687
            ],
            [
              -75.436015,
              39.985176
            ],
            [
              -75.45377,
              39.993745
            ],
            [
              -75.404333,
              40.027419
            ],
            [
              -75.414972,
              40.041796
            ],
            [
              -75.360901,
              40.065558
            ],
            [
              -75.320683,
              40.016208
            ],
            [
              -75.311752,
              40.020148
            ],
            [
              -75.276393,
              39.977
            ],
            [
              -75.275114,
              39.969308
            ],
            [
              -75.247189,
              39.962756
            ],
            [
              -75.251339,
              39.949184
            ],
            [
              -75.240281,
              39.942101
            ],
            [
              -75.24727,
              39.913537
            ],
            [
              -75.264549,
              39.888068
            ],
            [
              -75.262083,
              39.876474
            ],
            [
              -75.221369,
              39.875479
            ],
            [
              -75.210121,
              39.86582
            ],
            [
              -75.232734,
              39.857164
            ],
            [
              -75.273749,
              39.848359
            ],
            [
              -75.326563,
              39.84815
            ],
            [
              -75.347859,
              39.842292
            ],
            [
              -75.377387,
              39.824323
            ],
            [
              -75.394736,
              39.809411
            ],
            [
              -75.413203,
              39.801217
            ],
            [
              -75.440249,
              39.815105
            ],
            [
              -75.481556,
              39.829511
            ],
            [
              -75.51039,
              39.835595
            ],
            [
              -75.552507,
              39.839438
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu25"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "School District of Philadelphia, IU 26",
        "teachers": 7951.87
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -75.014957,
              40.137881
            ],
            [
              -74.982396,
              40.120419
            ],
            [
              -74.964903,
              40.118654
            ],
            [
              -74.955831,
              40.095167
            ],
            [
              -74.958874,
              40.082623
            ],
            [
              -74.980275,
              40.066203
            ],
            [
              -74.974392,
              40.049803
            ],
            [
              -75.017325,
              40.018303
            ],
            [
              -75.051617,
              40.006897
            ],
            [
              -75.062681,
              39.987611
            ],
            [
              -75.076764,
              39.977886
            ],
            [
              -75.10797,
              39.970691
            ],
            [
              -75.130947,
              39.958737
            ],
            [
              -75.135047,
              39.927557
            ],
            [
              -75.129057,
              39.906895
            ],
            [
              -75.140716,
              39.885951
            ],
            [
              -75.176381,
              39.882211
            ],
            [
              -75.195964,
              39.876709
            ],
            [
              -75.210121,
              39.86582
            ],
            [
              -75.221369,
              39.875479
            ],
            [
              -75.262083,
              39.876474
            ],
            [
              -75.264549,
              39.888068
            ],
            [
              -75.24727,
              39.913537
            ],
            [
              -75.240281,
              39.942101
            ],
            [
              -75.251339,
              39.949184
            ],
            [
              -75.247189,
              39.962756
            ],
            [
              -75.275114,
              39.969308
            ],
            [
              -75.276393,
              39.977
            ],
            [
              -75.205828,
              40.011579
            ],
            [
              -75.245111,
              40.037231
            ],
            [
              -75.264382,
              40.054075
            ],
            [
              -75.223671,
              40.092916
            ],
            [
              -75.188513,
              40.072781
            ],
            [
              -75.176473,
              40.08467
            ],
            [
              -75.10905,
              40.045577
            ],
            [
              -75.087434,
              40.0636
            ],
            [
              -75.096516,
              40.068948
            ],
            [
              -75.058021,
              40.106242
            ],
            [
              -75.014957,
              40.137881
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu26"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Beaver Valley IU 27",
        "teachers": 1502.48
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -80.360394,
              40.477507
            ],
            [
              -80.518955,
              40.477793
            ],
            [
              -80.519044,
              40.637164
            ],
            [
              -80.519072,
              40.851296
            ],
            [
              -80.183172,
              40.854815
            ],
            [
              -80.157629,
              40.855673
            ],
            [
              -80.154494,
              40.776428
            ],
            [
              -80.148392,
              40.674283
            ],
            [
              -80.144899,
              40.613507
            ],
            [
              -80.165361,
              40.606405
            ],
            [
              -80.180395,
              40.60893
            ],
            [
              -80.214298,
              40.580882
            ],
            [
              -80.227074,
              40.574148
            ],
            [
              -80.360394,
              40.477507
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu27"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "ARIN IU 28",
        "teachers": 1349.23
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -79.692654,
              40.668331
            ],
            [
              -79.785116,
              40.670924
            ],
            [
              -79.784546,
              40.742482
            ],
            [
              -79.690956,
              40.741057
            ],
            [
              -79.692312,
              40.819889
            ],
            [
              -79.691168,
              40.894805
            ],
            [
              -79.580744,
              40.894101
            ],
            [
              -79.587635,
              40.974639
            ],
            [
              -79.572537,
              40.986356
            ],
            [
              -79.551403,
              40.981384
            ],
            [
              -79.548916,
              40.956314
            ],
            [
              -79.540486,
              40.940546
            ],
            [
              -79.497659,
              40.922191
            ],
            [
              -79.483886,
              40.937409
            ],
            [
              -79.468734,
              40.937624
            ],
            [
              -79.452226,
              40.926697
            ],
            [
              -79.440536,
              40.93754
            ],
            [
              -79.427976,
              40.925508
            ],
            [
              -79.413649,
              40.937911
            ],
            [
              -79.395713,
              40.917409
            ],
            [
              -79.36528,
              40.928135
            ],
            [
              -79.293196,
              40.928242
            ],
            [
              -79.26705,
              40.917412
            ],
            [
              -79.251526,
              40.929704
            ],
            [
              -79.215458,
              40.911895
            ],
            [
              -79.1026,
              40.910069
            ],
            [
              -79.101197,
              40.83835
            ],
            [
              -78.993714,
              40.836624
            ],
            [
              -78.992091,
              40.885884
            ],
            [
              -78.963991,
              40.88991
            ],
            [
              -78.952987,
              40.877515
            ],
            [
              -78.921466,
              40.879162
            ],
            [
              -78.902132,
              40.876089
            ],
            [
              -78.901838,
              40.815656
            ],
            [
              -78.833124,
              40.812005
            ],
            [
              -78.840608,
              40.829031
            ],
            [
              -78.82532,
              40.829762
            ],
            [
              -78.806317,
              40.818415
            ],
            [
              -78.781788,
              40.824762
            ],
            [
              -78.778519,
              40.80883
            ],
            [
              -78.795964,
              40.802493
            ],
            [
              -78.806316,
              40.806262
            ],
            [
              -78.806311,
              40.729703
            ],
            [
              -78.808862,
              40.721062
            ],
            [
              -78.850671,
              40.626336
            ],
            [
              -78.885946,
              40.554067
            ],
            [
              -78.921923,
              40.483512
            ],
            [
              -78.974156,
              40.394581
            ],
            [
              -78.99009,
              40.412219
            ],
            [
              -79.016342,
              40.420323
            ],
            [
              -79.042621,
              40.391536
            ],
            [
              -79.063261,
              40.379119
            ],
            [
              -79.083484,
              40.389516
            ],
            [
              -79.101322,
              40.368795
            ],
            [
              -79.12712,
              40.375082
            ],
            [
              -79.117607,
              40.394517
            ],
            [
              -79.154001,
              40.401891
            ],
            [
              -79.154949,
              40.41147
            ],
            [
              -79.190487,
              40.41516
            ],
            [
              -79.202214,
              40.411356
            ],
            [
              -79.216316,
              40.425121
            ],
            [
              -79.246949,
              40.432111
            ],
            [
              -79.265249,
              40.416411
            ],
            [
              -79.273031,
              40.439362
            ],
            [
              -79.284413,
              40.442434
            ],
            [
              -79.300003,
              40.434481
            ],
            [
              -79.302586,
              40.46124
            ],
            [
              -79.329303,
              40.453035
            ],
            [
              -79.3625,
              40.460709
            ],
            [
              -79.373402,
              40.442114
            ],
            [
              -79.44451,
              40.415578
            ],
            [
              -79.531497,
              40.474012
            ],
            [
              -79.5046,
              40.473888
            ],
            [
              -79.48716,
              40.47824
            ],
            [
              -79.478726,
              40.49271
            ],
            [
              -79.455516,
              40.497171
            ],
            [
              -79.459818,
              40.517027
            ],
            [
              -79.455628,
              40.53452
            ],
            [
              -79.48336,
              40.53631
            ],
            [
              -79.488061,
              40.526416
            ],
            [
              -79.511478,
              40.542734
            ],
            [
              -79.526658,
              40.542914
            ],
            [
              -79.560173,
              40.56302
            ],
            [
              -79.570382,
              40.583116
            ],
            [
              -79.566213,
              40.593251
            ],
            [
              -79.495905,
              40.645732
            ],
            [
              -79.511789,
              40.668413
            ],
            [
              -79.564479,
              40.672313
            ],
            [
              -79.56605,
              40.642286
            ],
            [
              -79.591528,
              40.634249
            ],
            [
              -79.613342,
              40.623008
            ],
            [
              -79.628315,
              40.630123
            ],
            [
              -79.619244,
              40.645056
            ],
            [
              -79.636453,
              40.656033
            ],
            [
              -79.637315,
              40.664039
            ],
            [
              -79.669173,
              40.679362
            ],
            [
              -79.692654,
              40.668331
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu28"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "ARIN IU 28",
        "teachers": 1349.23
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -78.806549,
              40.845762
            ],
            [
              -78.804601,
              40.926985
            ],
            [
              -78.804342,
              40.993687
            ],
            [
              -78.709414,
              40.993309
            ],
            [
              -78.706901,
              40.905419
            ],
            [
              -78.665349,
              40.865857
            ],
            [
              -78.680415,
              40.852144
            ],
            [
              -78.672129,
              40.844735
            ],
            [
              -78.676198,
              40.82902
            ],
            [
              -78.695793,
              40.828872
            ],
            [
              -78.689169,
              40.807437
            ],
            [
              -78.706064,
              40.807738
            ],
            [
              -78.716031,
              40.816664
            ],
            [
              -78.715686,
              40.833489
            ],
            [
              -78.703893,
              40.843731
            ],
            [
              -78.806549,
              40.845762
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu28"
    },
    {
      "type": "Feature",
      "properties": {
        "name": "Schuylkill IU 29",
        "teachers": 1334.48
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -76.440051,
              40.495856
            ],
            [
              -76.535186,
              40.555228
            ],
            [
              -76.680733,
              40.474771
            ],
            [
              -76.718268,
              40.455598
            ],
            [
              -76.768249,
              40.436782
            ],
            [
              -76.780279,
              40.459657
            ],
            [
              -76.711643,
              40.485996
            ],
            [
              -76.658675,
              40.513299
            ],
            [
              -76.600657,
              40.553218
            ],
            [
              -76.56247,
              40.571911
            ],
            [
              -76.576917,
              40.580737
            ],
            [
              -76.656135,
              40.565543
            ],
            [
              -76.710581,
              40.570337
            ],
            [
              -76.710423,
              40.555918
            ],
            [
              -76.731673,
              40.55171
            ],
            [
              -76.735622,
              40.570332
            ],
            [
              -76.756473,
              40.575059
            ],
            [
              -76.756773,
              40.583127
            ],
            [
              -76.689002,
              40.593725
            ],
            [
              -76.639574,
              40.619801
            ],
            [
              -76.701582,
              40.657495
            ],
            [
              -76.672664,
              40.669844
            ],
            [
              -76.554802,
              40.712647
            ],
            [
              -76.467138,
              40.743304
            ],
            [
              -76.380321,
              40.775344
            ],
            [
              -76.409663,
              40.833622
            ],
            [
              -76.409052,
              40.842687
            ],
            [
              -76.329789,
              40.85187
            ],
            [
              -76.322461,
              40.854374
            ],
            [
              -76.298514,
              40.833658
            ],
            [
              -76.283658,
              40.885603
            ],
            [
              -76.207037,
              40.885461
            ],
            [
              -76.195503,
              40.865996
            ],
            [
              -76.172738,
              40.848944
            ],
            [
              -76.120021,
              40.868127
            ],
            [
              -76.1092,
              40.856772
            ],
            [
              -76.034495,
              40.871744
            ],
            [
              -76.026332,
              40.861364
            ],
            [
              -75.977137,
              40.867949
            ],
            [
              -75.957726,
              40.878682
            ],
            [
              -75.906012,
              40.833934
            ],
            [
              -75.936455,
              40.818716
            ],
            [
              -75.934717,
              40.805093
            ],
            [
              -75.890155,
              40.821727
            ],
            [
              -75.828686,
              40.780205
            ],
            [
              -75.757809,
              40.735415
            ],
            [
              -75.777694,
              40.729535
            ],
            [
              -75.807796,
              40.707493
            ],
            [
              -75.844672,
              40.693001
            ],
            [
              -75.854819,
              40.693191
            ],
            [
              -75.891916,
              40.677177
            ],
            [
              -75.993583,
              40.638397
            ],
            [
              -76.012245,
              40.577641
            ],
            [
              -76.02115,
              40.573701
            ],
            [
              -76.039607,
              40.583298
            ],
            [
              -76.049731,
              40.576124
            ],
            [
              -76.065559,
              40.579623
            ],
            [
              -76.079575,
              40.566098
            ],
            [
              -76.117282,
              40.558639
            ],
            [
              -76.155172,
              40.545305
            ],
            [
              -76.171629,
              40.534917
            ],
            [
              -76.218039,
              40.529038
            ],
            [
              -76.255867,
              40.517042
            ],
            [
              -76.301195,
              40.51472
            ],
            [
              -76.336987,
              40.51564
            ],
            [
              -76.370257,
              40.509278
            ],
            [
              -76.408822,
              40.497649
            ],
            [
              -76.440051,
              40.495856
            ]
          ]
        ]
      },
      "rdn": "us.pa.iu29"
    }
  ]
};