import React from "react";
import {SoleProps} from "../common/SoleProps";
import NumberComponent from "../../input/NumberComponent";
import {useTranslationShort} from "../../../hooks/useTranslation";

interface SoleNumberProps extends SoleProps {
    isRating?: boolean;
    isSlider?: boolean;
}

const SoleNumberComponent: React.FC<SoleNumberProps> = (props) => {
    const getShort = useTranslationShort();
    const rdn = props.rdn;
    const jsonKey = props.jsonKey as keyof typeof sole;
    const sole = props.sole;
    const label = props.label ?? getShort(rdn) ?? 'no label';
    const initialValue = sole && sole[jsonKey] ? Number(sole[jsonKey]) : undefined;

    return (<NumberComponent
        isRating={props.isRating}
        isSlider={props.isSlider}
        initialValue={initialValue}
        label={label}
        rdn={rdn}
        onChange={
            (value: number | undefined) => {
                props.setSole({
                    ...props.sole,
                    [jsonKey]: value
                });
            }
        }
    />)
};

export default SoleNumberComponent;
