import Parse from "parse";
import {Box, Grid} from "@material-ui/core";
import ObservationCard from "../common/ObservationCardComponent";
import {DropzoneArea} from "material-ui-dropzone";
import React, {useEffect, useState} from "react";
import {Props, ValidatePart} from "./00_PartProps";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import {isMobile} from "react-device-detect";
import {ISole} from "../../../shared/soleTypes";

export const validatePicturesPart:ValidatePart = ({sole, getLabel}) => {
    if (sole.observations === undefined || sole.observations.length === 0) {
        return getLabel('ui.reflect.missing_photos');
    }
    return null;
};

export const PicturesPart: React.FC<Props> = ({props}) => {

    const getLabel = useTranslationLabel();

    const [pictureFiles, setPictureFiles] = useState<File[]>([]);
    const [pictureUploadCount, setPictureUploadCount] = useState(0);

    useEffect(() => {
        (async () => {
            const uploadedFiles: File[] = [];
            for (const file of pictureFiles) {
                try {
                    const parseFile = new Parse.File('picture', file);
                    const obj = await parseFile.save();
                    await Parse.Cloud.run<(param: {id: string; imageFile:any}) => any>('webapp.saveImage', {
                        id: props.sole.id!,
                        imageFile: obj
                    });
                    uploadedFiles.push(file);
                    props.setError(undefined);
                } catch (error: any) {
                    props.setError(error.message);
                }
            }
            if (uploadedFiles.length > 0 && props.sole.id) {
                setPictureFiles((currentPictureFiles) => currentPictureFiles.filter((file) => !uploadedFiles.includes(file)));
                setPictureUploadCount((currentUploadCount) => currentUploadCount + 1);
                try { // TODO: don't reload sole, just fetch the updated observations and merge into existing sole
                    const newSole = await Parse.Cloud.run<(param: {id: string}) => ISole>('webapp.getSoleById', {id: props.sole.id}, {sessionToken: Parse.User.current()?.getSessionToken()});
                    props.setSole({...props.sole, observations: newSole.observations});
                    props.setError(undefined);
                } catch (error: any) {
                    props.setError(error.message);
                }
            }
        })();
    }, [pictureFiles]);

    return (<>
        <Grid container>
            {props.sole.observations?.filter((obs) => (obs.img !== '')).map((obs) =>
                (<Grid item key={obs.id} xs={12} sm={6} md={4}>
                    <ObservationCard observation={obs}/>
                </Grid>))}
        </Grid>
        <Box mt={5}>
            <DropzoneArea
                key={`dropzone-${pictureUploadCount}`}
                acceptedFiles={['image/*']}
                dropzoneText={getLabel(isMobile ? 'ui.reflect.mobile.drop_photos' : 'ui.reflect.drop_photos')}
                filesLimit={10}
                onChange={setPictureFiles}
                showAlerts={['error']}
            />
        </Box>
    </>);

};


