import React from "react";
import {StandardPickerState} from "../standards/StandardPickerComponent";
import {IQuestion} from "@startsole/startsole-types/src/solejson";
import SingleQuestion from "./SingleQuestionComponent";
import Error from "../ErrorComponent";

interface SingleQuestionStateProps {
    location: {
        state: {
            question: IQuestion;
            pickerState: StandardPickerState;
        };
    };
}

const SingleQuestionState: React.FC<SingleQuestionStateProps> = ({location}) => {

    if (location.state !== undefined) {
        window.history.replaceState(null, location.state.question.text ?? '', `/questions/${location.state.question.id}`);
        return <SingleQuestion question={location.state.question} pickerState={location.state.pickerState}/>;
    } else {
        return <Error/>;
    }

};

export default SingleQuestionState;
