import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {ISole} from "../../shared/soleTypes";
import {Autocomplete} from '@material-ui/lab';
import {Checkbox, FormControlLabel, TextField} from '@material-ui/core';
import {getDocumentUrl} from "../sole/primitives/SoleCardComponent";

const useStyles = makeStyles({
    root: {
        maxWidth: 345
    },
    media: {
        height: 140
    }
});

interface SoleApprovalCardProps {
    sole: ISole;
    approve: (soleId: string, comment: string, requestSocialMedia: boolean) => void;
    reject: (soleId: string, comment: string, requestSocialMedia: boolean) => void;
}

const autoCompleteResponses = [
    {text: "I see you have requested help - could you please tell us a little bit more about what was challenging so we can better assist you?"},
    {text: "Nice work!  This looks like an effective SOLE session"},
    {text: "Great question! In the future, be sure to give a more complete answer to the reflection questions."},
    {text: "Great reflection! In the future, be sure to include photographs as further documentation of your SOLE."},
    {text: "Good job!  Nice to see students working well together in groups - keep it up!"},
    {text: "Your photos show clear evidence of student collaboration - keep up the great work!"},
    {text: "The posters produced during this SOLE look great!"},
    {text: "Nice work!  Wonderful to see your students were so enthusiastic about the process and wanted more... keep it up!"},
    {text: "Your observations clearly document great examples of students engagement.  Looking good!"},
    {text: "Glad to see students were so excited to complete this SOLE - keep up the good work!"},
    {text: "Student presentations look really good, keep up the great work!"},
    {text: "Glad to see you achieved your objective for this SOLE - revealing student misconceptions is an important part of formative assessment!"},
    {text: "If you're looking to help support students with researching and citing sources, you may want to check out the 'Teacher Resources' page on our website.  We've got dozens of teacher-created printable resources there that could be useful as student organizers.  If you filter by 'Help with the Investigation phase' you should find some good options."},
    {text: "In order to get the most benefit out of the SOLE process, students should work in groups with less than one device per student.  This helps foster collaboration during the research phase."},
    {text: "Great question!  In order to get the most benefit out of the SOLE process, students should work in groups with less than one device per student.  This helps foster collaboration during the research phase.  It looks like some of the groups in this SOLE were using one device per student; please try reducing the number of devices per group, we will be looking for this in your next SOLE session."},
    {text: "We are unable to approve this SOLE due to missing photographic documentation.  If you have photos of student work produced during this SOLE please reply to this email and we can update your credit towards a CEU appropriately."},
    {text: "Great work! If you need resources to help students with research, please let us know"},
    {text: "I noticed you mentioned that students struggled to dig deeper-- if you would like help with ways to counterract this, please let us know!"},
    {text: "I see you have requested help-- what can we assist you with?"},
    {text: "In the future, please include photos in your reflection! I see you requested help-- what can we assist you with?"},
    {text: "Is there any support we can offer you to help improve the use of Technology during your SOLE session?"},
    {text: "It looks like the facilitation duration of this session was too short to be approved.  According to your iOS data your facilitation only lasted 3 minutes, and SOLE sessions should be at least 30 minutes to be effective."},
    {text: "Looks like students did a great job using technology during this SOLE.  If you need help improving communication skills in your classroom during SOLE, check our resoures page for some materials produced by other teachers designed to help: <a href=\"https://app.startsole.org/#!/resources\">https://app.startsole.org/#!/resources</a>"},
    {text: "Looks like students were very engaged in this SOLE.  If you need help improving communication skills in your classroom during SOLE, check our resoures page for some materials produced by other teachers designed to help: <a href=\"https://app.startsole.org/#!/resources\">https://app.startsole.org/#!/resources</a>"},
    {text: "Thank you for submitting.  In order to approve this SOLE we will need to see one of two things: a more descriptive reflection or photos that provide more convincing evidence of your use of the SOLE process."},
    {text: "Thank you for submitting.  In order to approve this SOLE we will need to see one of two things: a more descriptive reflection or photos that provide more convincing evidence of your use of the SOLE process. We're also happy to talk with you more in person about engagement strategies.  Please give us a time that works for you and we will be happy to follow up."},
    {text: "Thanks for submitting.  Unfortunately, the picture provided is not a clear example of the complete SOLE process. Please feel free to contact us if you'd like support structuring your classrom to support the SOLE process."},
    {text: "Thanks for submitting.  Unfortunately, the user reflection, notes, and pictures provided are not a clear example of the complete SOLE process. Please feel free to contact us if you'd like support structuring your classrom to support the SOLE process."},
    {text: "The student work produced during this SOLE looks great, glad to see that student use of technology is increasing over time!  You noted that collaboration was low even though the students were allowed to move around the room during this SOLE.  We have some resources shared on our site which might be helpful supporting collaboration in your room, if these are not useful please let us know and we can try to offer further support: <a href=\"https://app.startsole.org/#!/resources\">https://app.startsole.org/#!/resources</a>"},
    {text: "This appears to be a duplicate of the an approved SOLE-- perhaps there was an error submitting it and you redid it?"},
    {text: "This looks like a great SOLE!  Is there more help we could provide you with in order to better support collaboration and communication during the process?  If you have ideas for ways we could support you and your class doing SOLE, we'd love to hear about it.  Please feel free to write back and give us your thoughts, and keep up the good work!"},
    {text: "This looks like a great start to SOLE!  Glad to see you were using the organizers from our site during the process, is there more help we could provide you with in order to better support communication and building student independence?  If you have ideas for ways we could support you and your class doing SOLE or feedback about our app, we'd love to hear about it.  Please feel free to write back and give us your thoughts, and keep up the good work!"},
    {text: "This SOLE looks great! I see that you noted there was some difficulty with the presentations. We have many resources around that on the STARTSOLE website! In addition, if you need more help, please let us know."},
    {text: "This SOLE looks great! I see you noted that you had difficulty keeping all students engaged-- would you like us to send you resources related to that?"},
    {text: "This SOLE looks great! In the future, be sure to go more in depth on your reflection."},
    {text: "This SOLE looks great! What can we help you with?"},
    {text: "We are unable to approve this SOLE because this question does not seem appropriate for the SOLE process.  Please feel free to contact us if you'd like support creating SOLE questions for your classroom."},
    {text: "We are unable to approve this SOLE due to missing photographic documentation and limited feedback on reflection"},
    {text: "We are unable to approve this SOLE due to missing photographic documentation and limited feedback on reflection I see you noted that you don’t believe you met your objective for this SOLE - based on what you've learned, what would you do differently in your next SOLE?"},
    {text: "We are unable to approve this SOLE due to missing photographic documentation and limited feedback on reflection If you are just testing out the program, is there anything we can help you with?"},
    {text: "We are unable to approve this SOLE due to missing photographic documentation and limited feedback on reflection I see you have requested help-- what can we assist you with?"},
    {text: "We are unable to approve this SOLE due to the lack of photographic documentation and reflection.  The photos provided seemed to be a duplicate of another SOLE.  If you are having technical difficutlies with our site, please let us know."},
    {text: "We are unable to approve this SOLE due to the lack of photographic documentation.  The photos provided seemed to be a duplicate of another SOLE.  If you are having technical difficutlies with our site, please let us know."},
    {text: "You indiciated that creativity suffered in this SOLE-- if you need resources to help increase creativity, please let us know!"},
    {text: "Your SOLE has been approved--however, in the future, be sure to expand on your reflection a bit more!"},
    {text: "Your SOLE reflection is great, keep up the good work!"},
    {text: "I see students struggled a bit with this SOLE-- we recommend checking out the resources at the STARTSOLE website <a href=\"https://app.startsole.org/#!/resources\">https://app.startsole.org/#!/resources</a> If you need further help, please feel free to respond to this email."},
    {text: "This looks like a great SOLE!  Glad to see that communication skills seem to be improving."},
    {text: "Nice work! It's not uncommon to provide students with some resources to help them get started with the research phase, and if you'd like to see some materials compiled by other teachers, check out what's available at <a href=\"https://app.startsole.org/#!/resources\">https://app.startsole.org/#!/resources</a>.  We also just added a new Sample Presentation Rubric to help with student presentations.  It's aligned to Common Core standards and might be of interest to you in future SOLEs."},
    {text: "Glad to hear students were able to discover the meaning of hierarchy on their own.  If you'd like support with student communication during the presentation phase, we just added a new Sample Presentation Rubric to help with student presentations.  It's aligned to Common Core standards and might be of interest to you in future SOLEs; you can find it at <a href=\"https://app.startsole.org/#!/resources\">https://app.startsole.org/#!/resources</a>"},
    {text: "This looks like a good SOLE, and your observations show deep reflection on the process.  Our resources section has a student organizer called 'Research Notes' submitted by another teacher which is designed to help students get better at paraphrasing.  Feel free to check it out by visiting <a href=\"https://app.startsole.org/#!/resources\">https://app.startsole.org/#!/resources</a> and searching for 'Research Notes'. I also noticed that you were displaying a timer on your projector during the student presentations.  The new StartSOLE app allows you to wirelessly connect to your projector and display class timers, your big question, rubrics, and ground rules for students.  If you'd like to try it out, visit <a href=\"http://www.startsole.org/app-home.html\">http://www.startsole.org/app-home.html</a> to get started. Keep up the great work!"},
    {text: "great work!"},
    {text: "In the future, please include photos in your reflection... this is a great question and we'd love to see the work your students produced!"},
    {text: "Nice work!"},
    {text: "This also looks like a great SOLE session!  Could you tell me a little more about how you used modeling boards for this SOLE?  This may be a technique that we'd like to share with other teachers if it worked well for your class."},
    {text: "This looks like a great SOLE!  If you need more help with communication, check out our resources section using the link below or feel free to reply to this email for personal assistance. <a href=\"https://app.startsole.org/#!/resources\">https://app.startsole.org/#!/resources</a>"},
    {text: "This looks like a successful SOLE, and your idea to require groups to cite 3 sources is a good support for encouraging better research practice.  Keep up the good work!"},
    {text: "This looks like a test of our tools.  If you need help with SOLE in the future feel free to email us here we're always eager to assist."},
    {text: "We are unable to approve this SOLE due to missing photographic documentation and limited feedback on reflection  If you are just testing out the program is there anything we can help you with?"},
    {text: "Don't give up!  It's not unusual that the first SOLE experience is challenging, our research has shown that it takes about 3 SOLE sessions for deeper transformation to take root.  If there are particular ideas that came out of your discussion about what you'd try differently next time we'd love to hear them... we're always trying to spread best practices through the SOLE community."},
    {text: "Great question!  If there is any support we can offer for in service teacher training please let us know."},
    {text: "Nice work!  This looks like a great SOLE session"}
];

const SoleApprovalCard: React.FC<SoleApprovalCardProps> = ({sole, approve, reject}) => {
    const classes = useStyles();
    const [socialChecked, setSocialChecked] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');

    const handleChange = () => {
        setSocialChecked(!socialChecked);
    };

    const handleReject = () => {
        if (sole.id) {
            reject(sole.id, comment, socialChecked);
        }
    };

    const handleApprove = () => {
        if (sole.id) {
            approve(sole.id, comment, socialChecked);
        }
    };

    const onClickDownloadSummary = async (id:string) => {
        const url = await getDocumentUrl(id, 'summary');
        window.open(url);
    };

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardContent>
                    <div onClick={() => { onClickDownloadSummary(sole.id ?? '') }}>
                        <Typography gutterBottom variant="h5" component="h2">
                            {sole.question?.text}
                        </Typography>
                    </div>
                    <Autocomplete
                        id={`sole-feedback${sole.id}`}
                        freeSolo
                        options={autoCompleteResponses}
                        getOptionLabel={(option) => option.text}
                        style={{width: 300}}
                        renderInput={(params) => (
                            <TextField
                                {...params} label="Feedback" variant="outlined"
                                multiline={true}
                                onChange={(event) => {
                                    setComment(event.target.value)
                                }}/>
                        )}
                    />
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button
                    size="small"
                    color="primary"
                    onClick={handleApprove}>
                    Approve
                </Button>
                <Button
                    size="small"
                    color="primary"
                    onClick={handleReject}>
                    DENY!
                </Button>
                <FormControlLabel
                    control={<Checkbox checked={socialChecked} onChange={handleChange} name="social-request"/>}
                    label="Share?"
                />
            </CardActions>
        </Card>
    );
};

export default SoleApprovalCard;
