import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useSessionContext} from "../../../hooks/useSessionContext";
import {ISole} from "../../../shared/soleTypes";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import {SessionContextItem} from "../../../redux/sessionSlice";
import SoleSaver from "./SoleSaver";
import SoleStepper, {SoleStep} from "./SoleStepper";
import {OnSoleChanged, PartGridSizes, PartProps} from "../parts/00_PartProps";
import {initialStandardPickerState, StandardPickerState} from "../../standards/StandardPickerComponent";
import {useAppSelector} from "../../../redux/reduxHooks";
import {selectConstraints} from "../../../redux/constraintSlice";

const gridSizes:PartGridSizes = {
    xs:12,
    sm:8,
    md:5,
    lg:4
};

interface PlanReflectProps {
    steps: SoleStep[];
    sole: ISole;
    standardsState?: StandardPickerState;
}

const PlanReflect: React.FC<PlanReflectProps> = (props) => {
    const constraints = useAppSelector(selectConstraints);
    const getLabel = useTranslationLabel();

    // switch between using a Ref or a State for sole/getSole
    // true: sole is stored in a Ref => no renders on setSole
    // false: sole is stored as a State => re-renders on setSole
    const preferRef = true;

    // define both state and ref, only of these is used depending of preferRef
    const [sole, setSole] = useState(props.sole);
    const soleRef = useRef(props.sole);

    // a part can use a callback to indicate that the question was updated or an error occured
    // a question update triggers the "history"-effect
    // an error is passed to the stepper for rendering
    const [question, setQuestion] = useState(props.sole.question?.text);
    const [errorMessage, setErrorMessage] = useState<string>();

    const standardPickerStateRef = useRef(props.standardsState ?? initialStandardPickerState);
    const onSoleChangedListenersRef = useRef<OnSoleChanged[]>([]);

    // set the PartProps propagated to to all rendered parts
    const partProps:PartProps = {
        gridSizes:gridSizes,
        get sole():ISole { // use by parts to access to sole
            if (preferRef) {
                return soleRef.current; // return current ref
            }
            return sole; // return current state
        },
        setSole:(parm, thisOnSoleChanged) => { // called by parts to update the sole
            const prevSole = sole;
            const newSole = typeof parm === "function" ? (parm as (current:ISole) => ISole)(preferRef ? soleRef.current : sole) : parm as ISole;
//            console.log(`SETSOLE:${JSON.stringify(newSole)}`);
            if (preferRef) {
                soleRef.current = newSole; // set current ref => NO NEW RENDER
            } else {
                setSole(newSole); // set current state => TRIGGER RENDER
            }
            onSoleChangedListenersRef.current.forEach((onSoleChanged) => {
                if (thisOnSoleChanged !== onSoleChanged) {
                    onSoleChanged(newSole, prevSole)
                }
            });
        },
        setOnSoleChanged:((onSoleChanged, mount) => {
            if (mount) {
                onSoleChangedListenersRef.current.push(onSoleChanged);
            } else {
                onSoleChangedListenersRef.current = onSoleChangedListenersRef.current.filter((e) => e !== onSoleChanged);
            }
        }),
        setQuestion:(question) => { // called by parts when the question is updated (in additional to a sole update)
  //          console.log(`SETQUESTION:${JSON.stringify(question)}`);
            setQuestion(question);
        },
        setError:(msg) => { setErrorMessage(msg) },
        get standardPickerState():StandardPickerState {
            return standardPickerStateRef.current;
        },
        set standardPickerState(newState) {
            standardPickerStateRef.current = newState;
        },
        getLabel: (rdn) => getLabel(rdn) ?? "",
        constraints:constraints
    };

    const history = useHistory();
    const [, setSessionContext] = useSessionContext([]);

    // update the history context whenever the question changes
    // todo: this triggers a complete screen re-render => optimize history updates
    useEffect(() => {
        const context: SessionContextItem[] = [{
            callback(): void {
                history.push('/soles');
            },
            text: getLabel('ui.my_soles')!
        }];
        if (question) {
            context.push({
                callback(): void {},
                text: question
            });
        }
        setSessionContext(context);
    }, [question]);

//    useEffect(() => {
//        console.log(`USEFFECT:${JSON.stringify(sole)}`);
//    }, [sole]);

//    console.log(`RENDER:${JSON.stringify(sole)}`);

    return (<SoleSaver getContent={(onSave) =>
        <SoleStepper steps={props.steps} props={partProps} errorMsg={errorMessage} onSave={onSave}/>
    }/>)
};

export default PlanReflect;


