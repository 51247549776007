import {ISole} from "../../shared/soleTypes";
import {IQuestion} from "@startsole/startsole-types/src/solejson";
import {allRdn, StandardPickerState} from "../standards/StandardPickerComponent";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import useFetchQuestion from "../../hooks/useFetchQuestion";
import Loading from "../LoadingComponent";
import Error from "../ErrorComponent";
import {getAllSubjects} from "../../shared/subjects";
import {useAppSelector} from "../../redux/reduxHooks";
import {selectPub} from "../../redux/userSlice";
import PlanReflect from "./common/PlanReflectComponent";
import usePlanSteps from "./steps/PlanSteps";
import SoleLoader from "./common/SoleLoader";

interface PlanStateProps {
    location: {
        state?: {
            question?: IQuestion;
            pickerState?: StandardPickerState;
        };
    };
}

/*
Either called with:
 1) questionId in "location.search" (??? what is the difference with /soles/:id/plan ???)
 2) question in "props.state.question"

 In both cases a new sole is created with the question.
 If set, the pickerState is set from props.state.pickerState
*/

const PlanState: React.FC<PlanStateProps> = (props) => {
    const location = useLocation();
    const [question, isLoadingQuestion, questionErrorMessage, fetchQuestion, setQuestion] = useFetchQuestion();
    const [sole, setSole] = useState<ISole>();
    const steps = usePlanSteps();

    // fetch or set question on load
    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const questionId = parsed.question as string || undefined;
        if (questionId !== undefined) {
            fetchQuestion(questionId);
        } else if (props.location.state) {
            setQuestion(props.location.state.question);
        } else {
            setSole({});
        }
    }, []);

    // create a new sole with the question and optionally set the pickerState and sole subject/grade
    useEffect(() => {
        if (question !== undefined) {
            setSole({question: {...question}});
        }
    }, [question]);

    if (isLoadingQuestion) {
        return <Loading rdn="ui.loading"/>;
    } else if (questionErrorMessage) {
        return <Error text={questionErrorMessage}/>
    } else if (sole !== undefined) {
        return <PlanReflect steps={steps} sole={sole} standardsState={props.location.state?.pickerState}/>;
    } else {
        return <div/>;
    }
};

export default PlanState;
