import {Tag} from "../data/Tag";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {SoleText} from "../data/SoleText";

export interface TagsState {
    isLoading: boolean;
    errorMessage?: string;
    tags: Tag[];
    grades: Tag[];
}

const initialState: TagsState = {
    isLoading: false,
    errorMessage: undefined,
    tags: [],
    grades: []
};

export const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        loadTags: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
            state.tags = [];
        },
        setTags: (state, action: PayloadAction<Tag[]>) => {
            state.isLoading = false;
            state.errorMessage = undefined;
            state.tags = action.payload;
        },
        setGrades: (state, action: PayloadAction<Tag[]>) => {
            state.grades = action.payload;
        },
        loadTagsFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
            state.tags = [];
        }
    }
});

export const {loadTags, setTags, setGrades, loadTagsFailed} = tagsSlice.actions;

export const selectGrades = (state: RootState): Tag[] => state.tags.grades;

export default tagsSlice.reducer;
