import {IUserPub} from "../../shared/soleTypes";
import SelectionComponent from "../input/SelectionComponent";
import React, {useEffect, useState} from "react";
import {Box, FormControl, Grid, TextField} from "@material-ui/core";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {useAppSelector} from "../../redux/reduxHooks";
import {selectPub} from "../../redux/userSlice";

export interface AboutMeState {
    firstName?: string;
    lastName?: string;
    role?: string;
    grades?: string[];
    subjects?: string[];
}

interface AboutMeProps {
    callback: (state: AboutMeState) => void;
}

export const createAboutMeState = (pub?: IUserPub): AboutMeState => {
    return {
        firstName: pub?.firstName,
        lastName: pub?.lastName,
        role: pub?.role,
        grades: pub?.grades,
        subjects: pub?.subjects
    };
};

export const AboutMe: React.FC<AboutMeProps> = (props) => {
    const getLabel = useTranslationLabel();
    const pub = useAppSelector(selectPub);
    const [state, setState] = useState<AboutMeState>(createAboutMeState(pub));

    useEffect(() => {
        props.callback(state);
    }, [state]);

    const onChangeState = (newState: AboutMeState) => {
        setState(newState);
    };

    return (<Grid container>
        <Grid item xs={12}>
            <FormControl fullWidth={true}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label={getLabel('ui.profile.about_first_name')}
                    name="firstName"
                    autoFocus
                    value={state.firstName}
                    onChange={(e) => onChangeState({...state, firstName: e.target.value})}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label={getLabel('ui.profile.about_last_name')}
                    name="lastName"
                    value={state.lastName}
                    onChange={(e) => onChangeState({...state, lastName: e.target.value})}
                />
            </FormControl>
        </Grid>
        <Grid item xs={12}>
            <Box mt="16px" mb="8px">
                <SelectionComponent
                    initialValue={state.role}
                    label={getLabel('ui.profile.about_role')}
                    rdn="user.role"
                    onChange={
                        (value: string | string[] | undefined) => {
                            onChangeState({...state, role: value as string | undefined});
                        }
                    }
                />
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Box mt="16px" mb="8px">
                <SelectionComponent
                    initialValue={state.grades}
                    label={getLabel('ui.profile.about_grades')}
                    rdn="user.grades"
                    onChange={
                        (value: string | string[] | undefined) => {
                            onChangeState({...state, grades: value as string[] | undefined});
                        }
                    }
                />
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Box mt="16px" mb="8px">
                <SelectionComponent
                    initialValue={state.subjects}
                    label={getLabel('ui.profile.about_subjects')}
                    rdn="user.subjects"
                    onChange={
                        (value: string | string[] | undefined) => {
                            onChangeState({...state, subjects: value as string[] | undefined});
                        }
                    }
                />
            </Box>
        </Grid>
    </Grid>)
};

