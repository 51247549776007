import {SessionContextItem, setSessionContext} from "../redux/sessionSlice";
import {useEffect, useState} from "react";
import {useAppDispatch} from "../redux/reduxHooks";

export const useSessionContext = (context: SessionContextItem[]): [SessionContextItem[], (context: SessionContextItem[]) => void] => {
    const [myContext, setMySessionContext] = useState(context);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setSessionContext(myContext));

        return () => {
            dispatch(setSessionContext([]))
        };
    }, [myContext]);

    return [myContext, setMySessionContext];
};
