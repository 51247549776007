import React from "react";
import SelectionComponent from "../../input/SelectionComponent";
import {SoleProps} from "../common/SoleProps";
import {useTranslationShort} from "../../../hooks/useTranslation";
import {SelectionConstraints} from "../../../data/constraints/Constraints";

interface SoleSelectionProps extends SoleProps {
    isExpanded?: boolean;
    isRow?: boolean;
    constraint?: SelectionConstraints;
}

const SoleSelectionComponent: React.FC<SoleSelectionProps> = (props) => {
    const getShort = useTranslationShort();
    const rdn = props.rdn;
    const jsonKey = props.jsonKey as keyof typeof sole;
    const sole = props.sole;
    const label = props.label ?? getShort(rdn) ?? '';
    const initialValue = sole && sole[jsonKey] ? sole[jsonKey] : undefined;

    return (<SelectionComponent
        initialValue={initialValue}
        label={label}
        isExpanded={props.isExpanded}
        isRow={props.isRow}
        rdn={rdn}
        onChange={
            (value: string | string[] | undefined) => {
                props.setSole({
                    ...props.sole,
                    [jsonKey]: value
                });
            }
        }
        constraint={props.constraint}
    />)
};

export default SoleSelectionComponent;
