import React from "react";
import {Box, Grid} from "@material-ui/core";
import {useTranslationLabel, useTranslationShort} from "../../../hooks/useTranslation";
import {Props, ValidatePart} from "./00_PartProps";
import SoleNumberComponent from "../primitives/SoleNumberComponent";

export const validateRatingsPart:ValidatePart = ({sole, getLabel}) => {
    return null;
};

const RatingsPart: React.FC<Props> = ({props}) => {

    const getLabel = useTranslationLabel();

    return (
        <Grid item sm={12}>
            <Box mb={3}>
                <SoleNumberComponent
                    label={getLabel('ui.reflect_actively_engaged')}
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="engagementRatio"
                    rdn="session.engagement.ratio"
                />
            </Box>

            <Box mb={3}>
                <SoleNumberComponent
                    label={getLabel('ui.reflect_shared_responsibility')}
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="reflectionCollaboration"
                    rdn="reflection.collaboration"
                />
            </Box>

            <Box mb={3}>
                <SoleNumberComponent
                    label={getLabel('ui.reflect_technology_effectively')}
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="reflectionTechnology"
                    rdn="reflection.technology"
                />
            </Box>

            <Box mb={3}>
                <SoleNumberComponent
                    label={getLabel('ui.reflect_articulate_listen')}
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="reflectionCommunication"
                    rdn="reflection.communication"
                />
            </Box>
        </Grid>
    );

};

export default RatingsPart;
