import {useEffect, useState} from "react";
import {useSessionContext} from "../../hooks/useSessionContext";
import {SessionContextItem} from "../../redux/sessionSlice";
import {allRdn, StandardPickerState} from "./StandardPickerComponent";

const useStandardPickerSessionContext = (
    selectSubject: (rdn: string) => void,
    selectGrade: (rdn: string) => void,
    selectTag: (index: number, rdn: string) => void):
    [(pickerState: StandardPickerState) => void] => {

    const [pickerState, setPickerState] = useState<StandardPickerState | undefined>(undefined);
    const [, setMySessionContext] = useSessionContext([]);

    useEffect(() => {
        if (pickerState) {
            const sessionContext: SessionContextItem[] = [];
            if (pickerState.selectedSubjectRdn) {
                sessionContext.push({
                    text: pickerState.subjects.find((tag) => (tag.rdn === pickerState.selectedSubjectRdn || tag.subject === pickerState.selectedSubjectRdn))?.short || "",
                    callback: () => {
                        if (pickerState.selectedSubjectRdn) {
                            selectSubject(pickerState.selectedSubjectRdn);
                        }
                    }
                });
            }
            if (pickerState.grades?.length > 0 && pickerState.selectedGradeRdn) {
                sessionContext.push({
                    text: pickerState.grades.find((tag) => tag.rdn === pickerState.selectedGradeRdn)?.short || "",
                    callback: () => {
                        if (pickerState.selectedGradeRdn) {
                            selectGrade(pickerState.selectedGradeRdn);
                        }
                    }
                });
            }
            if (pickerState.tags.length > 0) {
                pickerState.selectedTagRdns.forEach((rdn, index) => {
                    if (rdn !== allRdn) {
                        sessionContext.push({
                            text: pickerState.tags[index].find((tag) => tag.rdn === pickerState.selectedTagRdns[index])?.short || "",
                            callback: () => {
                                selectTag(index, rdn);
                            }
                        });
                    }
                });
            }
            //           dispatch(setSessionContext(sessionContext));
            setMySessionContext(sessionContext);
        }
    }, [pickerState]);

    return [setPickerState];
};

export default useStandardPickerSessionContext;
