import React from 'react';
import {useLocation} from "react-router-dom";
import queryString from 'query-string';
import VirtualWelcome from "./VirtualWelcomeComponent";
import VirtualQuestion from "./VirtualQuestionComponent";
import VirtualRegister from "./VirtualRegister";
import VirtualSole from "./VirtualSoleComponent";
import {useAppSelector} from "../../redux/reduxHooks";
import {selectPub} from "../../redux/userSlice";

const Virtual: React.FC = () => {
    const location = useLocation();
    const pub = useAppSelector(selectPub);

    const parsed = queryString.parse(location.search);
    const questionId = parsed.question as string;
    const soleId = parsed.sole as string;

    const hasVirtualAccess = pub?.roles?.find((role) => role === 'virtual') !== undefined;

    if (hasVirtualAccess) {
        if (soleId) {
            return <VirtualSole soleId={soleId}/>;
        } else if (questionId) {
            return <VirtualQuestion questionId={questionId}/>;
        } else {
            return <VirtualWelcome/>;
        }
    } else {
        return <VirtualRegister/>;
    }
};

export default Virtual;
