export interface Training {
    id: string;
    image: string;
    title: string;
    description: string;
    time?: string;
}

export const trainings:Training[] = [
    {id: "UDL010", image: "/images/trainings/intro-to-udl-cover.png", title: "UDL Introduction", description: "A brief history and overview of Universal Design for Learning", time: '1 hour'},
    {id: "UDL020", image: "/images/trainings/applying-udl-cover.png", title: "Applying UDL", description: "A comprehensive look at how to apply UDL to your lesson planning, assignments and teaching.", time: '1 hour'},
    {id: "UDL030", image: "/images/trainings/udl-and-math-cover.png", title: "UDL and Mathematics", description: "How to apply the principles of UDL in the mathematic classroom including resources and strategies.", time: '1 hour'},
    {id: "UDL040", image: "/images/trainings/udl-and-ela-cover.png", title: "UDL and ELA", description: "How to apply the principles of UDL in the ELA classroom including resources and strategies.", time: '1 hour'},
    {id: "UDL050", image: "/images/trainings/learner-variability-networks-cover.png", title: "Learner Variability Networks", description: "Closer look at the three networks and how to address those variables in your teaching.", time: '1 hour'},
    {id: "UDL060", image: "/images/trainings/udl-and-social-studies-cover.png", title: "UDL and Social Studies", description: "Through project based learning, the principles of UDL are applied to the social studies classroom.", time: '30 minutes'},
    {id: "UDL070", image: "/images/trainings/udl-and-science-cover.png", title: "UDL and Science", description: "A closer look at applying UDL in the classroom and technology tools to support UDL.", time: '1 hour'}
];
