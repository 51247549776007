export interface DemoAccount {
    name: string;
    userName: string;
    userPub: string;
    languageOverride?: 'es_CO' | 'en_US' | 'ja_JP';
}

const demoAccounts:DemoAccount[] = [
    {
        name: 'Basic Demo',
        userName: 'demo@startsole.org',
        userPub: 'xDUHHL4Acy'
    },
    {
        name: 'Embedded Asynchronous Training (Lake Erie West)',
        userName: 'demo+lake-erie@startsole.org',
        userPub: 'n6CvecrZ38'
    },
    {
        name: 'Statewide Customization (Pennsylvania)',
        userName: 'demo+pa@startsole.org',
        userPub: 'h6SJMfaV1E'
    },
    {
        name: 'Language Customization (Colombia)',
        userName: 'demo+colombia@startsole.org',
        userPub: '4wEaULvhGK',
        languageOverride: 'es_CO'
    },
    {
        name: 'Trainer Account',
        userName: 'demo+trainer@startsole.org',
        userPub: 'HJPWMjfQeo'
    },
    {
        name: 'Ringleader Account (Ohio & Northwest ESC)',
        userName: 'demo+ringleader@startsole.org',
        userPub: 'n6f5Cikxi1'
    },
    {
        name: 'Different Lesson Plan Templates',
        userName: 'demo+plan-templates@startsole.org',
        userPub: 'nOGJINYZDB'
    },
    {
        name: 'Different Observation Criteria (OH Means Jobs)',
        userName: 'demo+oh-means-jobs@startsole.org',
        userPub: 'CjKy9blD6q'
    },
    {
        name: 'Student Search',
        userName: 'demo+search@startsole.org',
        userPub: 'ZWD36ltGVF'
    },
    {
        name: 'EdTech Awards',
        userName: 'edtechdigest@gmail.com',
        userPub: 'up8mfmlMnh'
    }
];


export default demoAccounts;