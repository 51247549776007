import React from "react";
import {match} from "react-router-dom";
import Loading from "../LoadingComponent";
import Error from "../ErrorComponent";
import useFetchQuestion from "../../hooks/useFetchQuestion";
import {useTranslationLabel} from "../../hooks/useTranslation";
import SingleQuestion from "./SingleQuestionComponent";

interface SingleQuestionIdProps {
    match: match<{ id: string }>;
}

const SingleQuestionId: React.FC<SingleQuestionIdProps> = ({match}) => {
    const getLabel = useTranslationLabel();
    const [question, isLoading, errorMessage] = useFetchQuestion(match.params.id);

    if (isLoading) {
        return <Loading rdn="ui.admin.loading_question"/>
    } else if (errorMessage) {
        return <Error text={errorMessage.replace('Object', getLabel('ui.question') ?? 'Question')}/>
    } else if (question !== undefined) {
        return <SingleQuestion question={question}/>
    } else {
        return <Error/>
    }
};

export default SingleQuestionId;
