import React, {useEffect, useRef, useState} from "react";
import {SelectionConstraints} from "../../data/constraints/Constraints";
import {
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    ListItemText,
    makeStyles,
    MenuItem,
    OutlinedInput,
    Select
} from "@material-ui/core";
import clsx from "clsx";
import {useAppSelector} from "../../redux/reduxHooks";
import {selectConstraints} from "../../redux/constraintSlice";
import {useTranslationLabel, useTranslationShort} from "../../hooks/useTranslation";

const useStyles = makeStyles((theme) =>
    createStyles({
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        rowItem: {
            padding: theme.spacing(1),
            [theme.breakpoints.down('xs')]: {
                flexGrow: 0,
                maxWidth: '100%',
                flexBasis: '100%'
            },
            [theme.breakpoints.up('sm')]: {
                flexGrow: 0,
                maxWidth: '49%',
                flexBasis: '49%'
            },
            [theme.breakpoints.up('md')]: {
                flexGrow: 0,
                maxWidth: '32%',
                flexBasis: '32%'
            }
        },
        menu: {
            width: '100%'
        },
        listItem: {
            whiteSpace: 'normal'
        },
        redBG: {
            background: 'red'
        }
    })
);

// Generic selection (either single or multi selection)
interface SelectionProps {
    initialValue?: string | string[];
    label?: string;
    rdn: string;
    isExpanded?: boolean;
    isRow?: boolean;
    onChange: (value: string | string[] | undefined) => void;
    constraint?: SelectionConstraints;
}

const SelectionComponent: React.FC<SelectionProps> = (props) => {
    const constraints = useAppSelector(selectConstraints);
    const constraint = props.constraint ? props.constraint : props.rdn ? constraints.get(props.rdn) : undefined;
    if (constraint instanceof SelectionConstraints) {
        const isExpanded = props.isExpanded ?? false;
        if (isExpanded) {
            return (<ExpandedSelectionComponent
                onChange={props.onChange}
                initialValue={props.initialValue}
                label={props.label}
                isRow={props.isRow}
                rdn={props.rdn}
                constraint={props.constraint}/>)
        } else if (constraint.max !== undefined && constraint.max > 1) {
            return (<MultiSelectionComponent
                onChange={props.onChange}
                initialValue={props.initialValue}
                label={props.label}
                rdn={props.rdn}
                constraint={props.constraint}
            />)
        } else {
            return (<SingleSelectionComponent
                onChange={props.onChange}
                initialValue={props.initialValue as string | undefined}
                label={props.label}
                rdn={props.rdn}
                constraint={props.constraint}
            />)
        }
    } else {
        return <div/>;
    }
};

// Single selection
interface SingleSelectionProps {
    initialValue?: string;
    label?: string;
    rdn: string;
    onChange: (value: string | string[] | undefined) => void;
    constraint?: SelectionConstraints;
}

const SingleSelectionComponent: React.FC<SingleSelectionProps> = (props) => {
    const getLabel = useTranslationLabel();
    const [value, setValue] = useState<string | undefined>(props.initialValue ?? "");
    const constraints = useAppSelector(selectConstraints);
    const [labelWidth, setLabelWidth] = useState<number | undefined>(0);
    const inputLabelRef = useRef<HTMLLabelElement>(null);
    const classes = useStyles();

    useEffect(() => {
        const width = inputLabelRef.current?.offsetWidth;
        if (width !== undefined) {
            setLabelWidth(width);
        }
    }, [inputLabelRef]);

    useEffect(() => {
        setValue(props.initialValue ?? "");
    }, [props.initialValue]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const v = event.target.value as string;
        setValue(v);
        props.onChange(v);
    };

    const constraint = props.constraint ? props.constraint : props.rdn ? constraints.get(props.rdn) : undefined;
    if (constraint instanceof SelectionConstraints) {
        return (<FormControl variant="outlined" fullWidth={true}>
            <InputLabel style={value && value.length > 0 ? {} : {width:"85%"}}
                ref={inputLabelRef}
                htmlFor={`${props.rdn}-label`}
            >{props.label}</InputLabel>
            <Select
                label={props.label}
                id={props.rdn}
                multiple={false}
                displayEmpty
                value={value}
                input={<OutlinedInput
                    labelWidth={labelWidth}
                />}
                inputProps={{
                    name: `${props.rdn}-name`,
                    id: props.rdn
                }}
                onChange={handleChange}
                MenuProps={{className: classes.menu}}
                SelectDisplayProps={{className: clsx(classes.listItem, 'MuiSelect-root', 'MuiSelect-select', 'MuiSelect-selectMenu', 'MuiSelect-outlined', 'MuiInputBase-input', 'MuiOutlinedInput-input')}}
            >
                {/* <option value="" disabled={true}></option>*/}
                {constraint.items.map((item) =>
                    <MenuItem key={item.rdn} value={item.rdn} className={classes.listItem}>{item.title ?? getLabel(item.rdn)}</MenuItem>
                )}
            </Select>
        </FormControl>);
    } else {
        return <div/>;
    }
};

// Expanded selection (single and multi selection)

const getInitialArrayValue = (values: string[] | string | undefined): string[] | undefined => {
    if (typeof values === 'string') {
        return [values];
    } else {
        return values;
    }
};

const ExpandedSelectionComponent: React.FC<SelectionProps> = (props) => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const [value, setValue] = useState<string[] | undefined>(
        getInitialArrayValue(props.initialValue)
    );
    const constraints = useAppSelector(selectConstraints);
    const constraint = props.constraint ? props.constraint : props.rdn ? constraints.get(props.rdn) : undefined;
    if (constraint instanceof SelectionConstraints) {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const name = event.target.name;
            let values = value ?? [];
            if (event.target.checked) {
                if (!values.includes(name)) {
                    values.push(name);
                }
            } else {
                values = values.filter((v) => v !== name);
            }
            const newValues = [...values];
            setValue(newValues);
            if (constraint.max === undefined || constraint.max > 1) {
                props.onChange(newValues);
            } else {
                props.onChange(newValues.length > 0 ? newValues[0] : undefined);
            }
        };

        return (<FormControl fullWidth={true}>
            <FormLabel style={{paddingBottom:"12px"}}>{props.label}</FormLabel>
            <FormGroup row={props.isRow}>
                {constraint.items.map((item, index) =>
                    (<FormControlLabel
                        disabled={
                            constraint.max !== undefined &&
                            value?.length !== undefined &&
                            value?.length >= constraint.max &&
                            !value?.includes(item.rdn)}
                        className={props.isRow ? classes.rowItem : ''}
                        key={`select${item.rdn}${index}`}
                        style={{padding:"2px"}}
                        control={<Checkbox checked={value?.includes(item.rdn)} onChange={handleChange}
                                           name={item.rdn}/>}
                        label={item.title ?? getLabel(item.rdn)}
                    />)
                )}
            </FormGroup>
        </FormControl>)
    } else {
        return <div/>;
    }
};

// Multi selection

const MultiSelectionComponent: React.FC<SelectionProps> = (props) => {
    const getLabel = useTranslationLabel();
    const [value, setValue] = useState<string[] | undefined>(getInitialArrayValue(props.initialValue));
    const constraints = useAppSelector(selectConstraints);
    const [labelWidth, setLabelWidth] = useState<number | undefined>(0);
    const inputLabelRef = useRef<HTMLLabelElement>(null);

    useEffect(() => {
        const width = inputLabelRef.current?.offsetWidth;
        if (width !== undefined) {
            setLabelWidth(width);
        }
    }, [inputLabelRef]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const values = event.target.value as string[];
        setValue(values);
        props.onChange(values);
    };

    const constraint = props.constraint ? props.constraint : props.rdn ? constraints.get(props.rdn) : undefined;
    if (constraint instanceof SelectionConstraints) {
        const emptyArray: string[] = [];
        const getRenderedValue = (selectedRdns: string[]): string => {
            if (selectedRdns.length === 0) {
                return "";
                // return "Please select an option";
            }

            return selectedRdns
                .map((rdn) => constraint.items.find((item) => item.rdn === rdn))
                .map((item) => item?.title ?? getLabel(item?.rdn))
                .join(', ');
        };

        return (<FormControl variant="outlined" fullWidth={true}>
            <InputLabel
                ref={inputLabelRef}
                htmlFor={`${props.rdn}-label`}
            >{props.label}</InputLabel>
            <Select
                labelId={`${props.rdn}-label`}
                id={props.rdn}
                multiple
                displayEmpty
                value={value}
                onChange={handleChange}
                input={<OutlinedInput
                    labelWidth={labelWidth}
                />}
                renderValue={(selected) => getRenderedValue((selected ?? []) as string[])}
                MenuProps={MenuProps}
            >
                <MenuItem key="placeholder" value={emptyArray} disabled>
                    Please select an option
                </MenuItem>
                {constraint.items.map((item) =>
                    (<MenuItem
                        disabled={
                            constraint.max !== undefined &&
                            value?.length !== undefined &&
                            value?.length >= constraint.max &&
                            !value?.includes(item.rdn)}
                        key={item.rdn}
                        value={item.rdn}
                    >
                        <Checkbox checked={value?.includes(item.rdn)}/>
                        <ListItemText primary={ item?.title ?? getLabel(item?.rdn)}/>
                    </MenuItem>)
                )}
            </Select>
        </FormControl>);
    } else {
        return <div/>;
    }
};

export default SelectionComponent;

