import React from 'react';
import {match, Redirect} from "react-router-dom";
import {isEmbedded} from "../embedded";
import {useAppSelector} from "../redux/reduxHooks";

interface TokenProps {
    match: match<{ token: string }>;
}

const LoginWithToken: React.FC<TokenProps> = (props) => {
//    const user = Parse.User.current();
    const user = useAppSelector((state) => state.user.user);

    if (user) {
        if (isEmbedded()) {
            return null; // <div>Logged in</div>
        }
        return <Redirect to="/home"/>
    }
    return null; // <div>Logging in...</div>;
};

export default LoginWithToken;
