import {SoleText} from "../data/SoleText";
import {useAppSelector} from "../redux/reduxHooks";
import {selectTexts} from "../redux/textSlice";
import {useCallback} from "react";

const findText = (texts: SoleText[], rdn: string): SoleText | undefined => {
    const lngArray = window.navigator.language.split('-');
    // certain demo accounts set an override language in the local storage
    const override = localStorage.getItem('languageOverride') ?? undefined;

    let userLanguage = lngArray[0] + (lngArray[1] ? (`_${lngArray[1].toUpperCase()}`) : '');
    // for now all Spanish versions use Colombian Spanish
    if (lngArray[0] === 'es') {
        userLanguage = 'es_CO'
    }

    if (lngArray[0] === 'ja') {
        userLanguage = 'ja_JP'
    }

    let text = texts.find((text:SoleText) => text.rdn === rdn && text.language === (override ?? userLanguage));

    if (!text && userLanguage !== 'en_US') {
        text = texts.find((text:SoleText) => text.rdn === rdn && text.language === "en_US");
    }

    return text;
};

export const useTranslationLabel = (): (rdn: string | undefined) => string | undefined => {
    const texts = useAppSelector(selectTexts);

    const getLabel = useCallback((rdn: string | undefined): string | undefined => {
        return rdn !== undefined ? findText(texts, rdn)?.label : 'missing label';
    }, []);

    return getLabel;
};

export const useTranslationShort = (): (rdn: string) => string | undefined => {
    const texts = useAppSelector(selectTexts);

    const getShort = useCallback((rdn: string): string | undefined => {
        return findText(texts, rdn)?.short;
    }, []);

    return getShort;
};

export const useTranslationLong = (): (rdn: string) => string | undefined => {
    const texts = useAppSelector(selectTexts);

    const getLong = useCallback((rdn: string): string | undefined => {
        return findText(texts, rdn)?.long;
    }, []);

    return getLong;
};
