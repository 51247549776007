// helper function to pluralize the text used in selection labels
export function getPluralType(type?: string): string {
    let plural = 'All ';
    const specialCases: { [key: string]: string } = {
        "Accountability Criteria": "Accountability Criteria",
        "Content": "Content",
        "Evidence of Learning": "Evidence of Learning",
        "Measurement Criteria": "Measurement Criteria",
        "Processes and Inquiry Standards": "Processes and Inquiry Standards",
        "Skills and Knowledge": "Skills and Knowledge",
        "Body of Knowledge": "Bodies of Knowledge",
        "Check for Understanding": "Checks for Understanding",
        "Skill and Concept": "Skills and Concepts",
        "Statement of Enduring Knowledge": "Statements of Enduring Knowledge"
    };
    if (type !== undefined) {
        if (type in specialCases) {
            plural += specialCases[type];
        } else if (type.slice(-1) === "y") {
            plural += `${type.slice(0, -1)}ies`;
        } else if (type.slice(-1) === "s") {
            plural += `${type}es`;
        } else if (type !== "") {
            plural += `${type}s`;
        } else {
            plural += ` Standards`;
        }
    }
    return plural;
}