import React from "react";
import Parse from "parse";
import {trainings} from "./trainings";
import {match} from "react-router-dom";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {Box} from "@material-ui/core";
import {useAppSelector} from "../../../redux/reduxHooks";
import {selectPub} from "../../../redux/userSlice";

interface Identifiable {
    id: string;
}

interface TrainingProps {
    match?: match<Identifiable>;
}

const Training: React.FC<TrainingProps> = (props) => {
    const {match} = props;
    const pub = useAppSelector(selectPub);
    const {height} = useWindowDimensions();
    const trainingId = match?.params.id;
    const trainingURL = `/trainings/${trainingId}/story.html`; // see public folder
    const eventRdn = `training.${trainingId}.done`; // must exist in the Reward table
    const trainingTitle = trainings[0].title;

    // TODO: REMOVE TIMER TO DEBUG POINTS
//    setTimeout(() => {
//        console.log("TIMER: Trigger course complete");
//        (window as any).courseCompleted();
//    }, 5000);

    (window as any).courseCompleted = () => {
     //   console.log("CALLBACK: Trigger course complete");
        Parse.Cloud.run('event.trigger', {rdn: eventRdn, isActivity: false, objectId: pub?.id, className: 'UserPub'}, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
    };

    return (<Box
        display="flex"
        width="100%" height={height - 124}
        alignItems="center"
        justifyContent="center"
    >
        <iframe width="980" height="500" src={trainingURL} title={trainingTitle} frameBorder="0" allowFullScreen/>
    </Box>);
};

export default Training;
