import React, {useState} from "react";
import {useTranslationLabel} from "../hooks/useTranslation";
import {appConfig} from "../config/config";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import moment from "moment";

const MaintenancePopup: React.FC = () => {
    const NOTIFY_TOKEN = JSON.stringify(appConfig.maintenance.start) ?? '';
    const [isOpen, setIsOpen] = useState(localStorage.getItem('didNotify') !== NOTIFY_TOKEN && new Date() <= appConfig.maintenance.start);
    const getLabel = useTranslationLabel();

    const handleClose = () => {
        localStorage.setItem('didNotify', NOTIFY_TOKEN);
        setIsOpen(false);
    };

//    const formatString = 'MMMM Do YYYY, h:mm a z';
    const formatString = 'LLLL';
//    const formatString = 'LLL';

    return (<Dialog
        open={isOpen}
        onClose={() => { handleClose() }}
        aria-labelledby="alert-maintenance-confirm-title"
        aria-describedby="alert-maintenance-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{getLabel(appConfig.maintenance.title)}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {getLabel(appConfig.maintenance.description)} {moment(appConfig.maintenance.start).format(formatString)} - {moment(appConfig.maintenance.end).format(formatString)}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {handleClose()}} color="secondary" variant="contained">
                {getLabel('ui.ok')}
            </Button>
        </DialogActions>
    </Dialog>)

    // return <Maintenance
    //     callback={handleMaintenanceCallback}
    //     scrollToFirstStep={true}
    //     disableOverlayClose={true}
    //     run={true}
    //     continuous={true}
    //     showProgress={false}
    //     showSkipButton={false}
    //     styles={{
    //         options: {
    //             zIndex: 10000,
    //         },
    //     }}
    //     steps={
    //         [{
    //             title: <h3 dangerouslySetInnerHTML={{__html: getLabel('ui.maintenance.title') ?? "title"}}/>,
    //             content: <div dangerouslySetInnerHTML={{__html: getLabel('ui.maintenance.content') ?? "content"}}/>,
    //             locale: {last: <div dangerouslySetInnerHTML={{__html: getLabel('ui.maintenance.ok') ?? "OK"}}/>},
    //             placement: 'center',
    //             target: 'body'
    //         }]
    //     }></Maintenance>;
};

export default MaintenancePopup;
