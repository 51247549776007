import {Tag} from "../../data/Tag";
import Parse from "parse";
import {allRdn} from "./StandardPickerComponent";
import {getPluralType} from "./Plurals";

/*

Simple tags cache:
- a single "tags" object is stored in local storage
- tags.tags is an object with all cached tags using the rdn as unique key {tag-rdn:tag, ....}
- tags.lists is an object with unique tag-lists stored as key:string:[]: {list-key: [rdn1, rdn2, rdn3, ...]}

*/

// global cache object
let glob_cache:any = undefined;
type CacheObj = { version:number; tags: any; lists: any };

const CACHE_VERSION = 1;

function loadCache():any {
    if (glob_cache === undefined) {
        glob_cache = {};
        // load from local storage, map tags and lists to flat cache
        const cacheJson = localStorage.getItem("tags");
        const cacheObj:CacheObj = cacheJson ? JSON.parse(cacheJson) : {version:CACHE_VERSION, tags: {}, lists: {}};
        if (cacheObj.version && cacheObj.version >= CACHE_VERSION) {
            const tags: any = cacheObj.tags;
            const lists: any = cacheObj.lists;
            Object.keys(tags).forEach((rdn) => glob_cache[rdn] = tags[rdn]); // load all tags
            Object.keys(lists).forEach((key) => glob_cache[key] = lists[key].map((rdn: string) => tags[rdn])) // load tag lists
        }
    }
    return glob_cache;
}

function writeCache(key:string, tags:Tag[]) {
    if (key === allRdn) {
        return;
    }
    // remove allRdn and store in memory
    if (tags.length > 0 && tags[0].rdn === key) {
        glob_cache[key] = tags[0]; // single tagg
    } else {
        tags = tags.filter((tag) => tag.rdn !== allRdn); // tag list
        glob_cache[key] = tags;
    }
    // update local storage, map flat cache to tags and lists
    const cacheObj:CacheObj = {version:CACHE_VERSION, tags:{}, lists:{}};
    Object.keys(glob_cache).forEach((key) => {
        const tag = glob_cache[key];
        if (tag.rdn === key) { // single tag
            cacheObj.tags[tag.rdn] = tag;
        } else { // tag lists
            cacheObj.lists[key] = glob_cache[key].map((tag:Tag) => {
                cacheObj.tags[tag.rdn] = tag;
                return tag.rdn;
            });
        }
    });
    localStorage.setItem("tags", JSON.stringify(cacheObj));
}

export async function loadGrades(subject:string):Promise<Tag[]> {
    const hasSubject = subject !== "*";
    const cache = loadCache();
    const key = `grades-${subject}`;
    let grades = cache[key];
    if (!grades) {
        try {
            grades = await Parse.Cloud.run('webapp.getGrades', {
                subject: hasSubject ? subject : false
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
            writeCache(key, grades);
        } catch (e) {
            console.log(`Error loading grades for ${subject}`);
        }
    }
    if (grades && grades.length > 0 && grades[0].rdn !== allRdn) {
        grades.unshift({
            rdn: allRdn,
            short: 'All Grades'
        });
    }

    // pre-load and cache the subject in the background
    if (hasSubject) {
        loadTagJson(subject, false);
    }

    return grades;
}

export async function loadStandard(rdn:string, gradeRdn:string, showAll:boolean):Promise<Tag[]> {
    const cache = loadCache();
    const key = `standards-${rdn}-${gradeRdn}${showAll ? "-all" : ""}`;
    let standards = cache[key];
    if (!standards) {
        standards = await Parse.Cloud.run('webapp.getStandards', {
            rdn: rdn,
            grade: gradeRdn,
            showAll: showAll
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        writeCache(key, standards);
    }
    if (standards && standards.length > 0 && standards[0].rdn !== allRdn) {
        standards.unshift({
            rdn: allRdn,
            short: getPluralType(standards[0].type)
        });
    }

    // pre-load and cache the parent standard in the background
    loadTagJson(rdn);

    return standards;
}

export interface TagJson {rdn:string; grades:string[]; partof:string[]; children:string[]; parent?:TagJson}

async function findParent(rdn:string, partOf:string[]):Promise<TagJson|undefined> {
    const tags:TagJson[] = [];
    for (const parentRdn of partOf) {
        const tag = await loadTagJson(parentRdn, false);
        if (tag) {
            tags.push(tag);
        }
    }
    const parent = tags.find((tag) => Array.isArray(tag.children) && tag.children.includes(rdn));
    if (parent) {
        return loadTagJson(parent.rdn);
    }
    return Promise.resolve(undefined);
}

export async function loadTagJson(rdn:string, loadParants = true):Promise<TagJson|undefined> {
    const cache = loadCache();
    let tag = cache[rdn];
    if (tag === undefined || tag.partof === undefined) {
        try {
            tag = (await Parse.Cloud.run('tag.getJson', {
                rdn: rdn,
                options: {noParents:true}
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            })) as Tag;
        } catch (e) {
            console.log(`Tag not found rdn=${rdn}`);
            throw (e);
        }
        writeCache(rdn, [tag]);
    }
    return {...tag, parent:loadParants && tag.type !== "doc" ? await findParent(tag.rdn, tag.partof) : undefined}
}