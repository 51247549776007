import {all, fork, takeEvery} from 'redux-saga/effects';

import {addQuestionSaga, loadMyQuestionsSaga, searchQuestionsSaga, toggleFavoriteQuestionSaga} from "./sagaQuestions";
import {
    completeOnboardingSaga,
    loadCurrentUserFromLocalStorage,
    loadSchoolFromLocalStorage,
    loginSaga,
    loginWithTokenSaga,
    resetUserSaga,
    saveSchoolSaga,
    saveUserSaga,
    setCurrentUserSaga,
    setSchoolSaga,
    signUpSaga
} from "./sagaUser";
import {addSoleSaga, deleteSoleSaga, loadSolesSaga, setSolesSaga} from "./sagaSoles";
import {loadDataPointsSaga, setDataPointsSaga} from "./sagaDataPoints";
import {loadTextSaga, textLoadingFinishedSaga} from "./sagaText";
import {setConstraintsSaga} from "./sagaConstraints";
import {loadReportSaga} from "./sagaReports";
import {loadTagsSaga} from './sagaTags';
import {addSole, deleteSole, loadSoles, setSoles} from "../redux/solesSlice";
import {setConstraints} from "../redux/constraintSlice";
import {loadDataPoints, setDataPoints} from "../redux/dataPointSlice";
import {loadMyQuestions, toggleFavoriteQuestion} from "../redux/myQuestionsSlice";
import {addQuestion} from "../redux/questionAddSlice";
import {searchQuestions} from "../redux/questionSearchSlice";
import {loadReport} from "../redux/reportSlice";
import {loadTags} from "../redux/tagsSlice";
import {loadText, textLoadingFinished} from "../redux/textSlice";
import {
    completeOnboarding,
    login,
    loginWithToken,
    resetUser,
    saveSchool,
    saveUser,
    setCurrentUser,
    setSchool,
    signUp
} from "../redux/userSlice";

const actionWatcher = function* (){
    yield takeEvery(setCurrentUser.type, setCurrentUserSaga);
    yield takeEvery(resetUser.type, resetUserSaga);
    yield takeEvery(saveUser.type, saveUserSaga);
    yield takeEvery(login.type, loginSaga);
    yield takeEvery(loginWithToken.type, loginWithTokenSaga);
    yield takeEvery(signUp.type, signUpSaga);
    yield takeEvery(setSchool.type, setSchoolSaga);
    yield takeEvery(saveSchool.type, saveSchoolSaga);
    yield takeEvery(completeOnboarding.type, completeOnboardingSaga);

    yield takeEvery(loadMyQuestions.type, loadMyQuestionsSaga);
    yield takeEvery(toggleFavoriteQuestion.type, toggleFavoriteQuestionSaga);
    yield takeEvery(addQuestion.type, addQuestionSaga);
    yield takeEvery(searchQuestions.type, searchQuestionsSaga);

    yield takeEvery(loadSoles.type, loadSolesSaga);
    yield takeEvery(setSoles.type, setSolesSaga);
    yield takeEvery(deleteSole.type, deleteSoleSaga);
    yield takeEvery(addSole.type, addSoleSaga);

    yield takeEvery(loadDataPoints.type, loadDataPointsSaga);
    yield takeEvery(setDataPoints.type, setDataPointsSaga);

    yield takeEvery(loadText.type, loadTextSaga);
    yield takeEvery(textLoadingFinished.type, textLoadingFinishedSaga);

    yield takeEvery(setConstraints.type, setConstraintsSaga);

    yield takeEvery(loadReport.type, loadReportSaga);

    yield takeEvery(loadTags.type, loadTagsSaga);
};

const initialSaga = function* (){
    yield loadCurrentUserFromLocalStorage();
    yield loadSchoolFromLocalStorage();

    yield loadTextSaga();
};

export default function* rootSaga() {
    yield fork(initialSaga);
    yield all([
        actionWatcher()
    ]);
}
