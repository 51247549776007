import React, {useEffect} from "react";
import {match} from "react-router-dom";
import SoleLoader from "./common/SoleLoader";
import usePlanSteps from "./steps/PlanSteps";

interface Props {
    match: match<{ id: string }>;
}

const PlanId: React.FC<Props> = (props) => {
    const steps = usePlanSteps();
    return <SoleLoader steps={steps} match={props.match}/>
};

export default PlanId;
