import React, {MouseEvent, useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import demoAccounts, {DemoAccount} from '../shared/demoAccounts';
import {Button, Grid} from "@material-ui/core";
import {useTranslationLabel} from "../hooks/useTranslation";
import {useAppDispatch} from "../redux/reduxHooks";
import {login} from "../redux/userSlice";

const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120
        }
    })
);

const DemoAcountSelector: React.FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const getLabel = useTranslationLabel();
    const [account, setAccount] = useState<DemoAccount | undefined>(undefined);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newAccount = demoAccounts.find((account) => account.userPub === event.target.value);
        if (newAccount?.languageOverride) {
            localStorage.setItem('languageOverride', newAccount.languageOverride);
        } else {
            localStorage.removeItem('languageOverride');
        }
        if (newAccount){
            setAccount(newAccount);
        }
    };

    const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (account) {
            dispatch(login(account.userName, 'letlearninghappen'));
        }
    };

    return (
        <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center">
            <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-account-select-label">Account</InputLabel>
                    <Select
                        labelId="demo-account-select-label"
                        id="demo-account-select"
                        value={account}
                        onChange={handleChange}
                    >
                        {demoAccounts.map((demoAccount, index) =>
                            <MenuItem value={demoAccount.userPub} key={index}>{demoAccount.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {getLabel('ui.ok')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default DemoAcountSelector;
