import React, {useEffect, useState} from "react";
import {DataGrid, GridCellParams, GridColDef, GridRowId} from "@material-ui/data-grid";
import Parse from "parse";
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    Select,
    Slider,
    TextField,
    Typography
} from "@material-ui/core";
import {useTranslationLabel} from "../../hooks/useTranslation";
import Loading from "../LoadingComponent";
import useFetchQuestions, {QuestionSearchFilterOptions, QuestionSearchParams} from "../../hooks/useFetchQuestions";
import Error from "../ErrorComponent";
import SettingsIcon from '@material-ui/icons/Settings';
import QuestionTagEditor, {TagRdnsEditor} from "../QuestionTagEditor";
import {IQuestion} from "@startsole/startsole-types/src/solejson";

const useStyles = makeStyles((theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1)
        },
        slider: {
            width: 200
        }
    })
);

const QuestionApprovalBatch: React.FC = () => {
    const [questions, isLoading, errorMessage, fetchQuestions] = useFetchQuestions();
    const classes = useStyles();
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
    const getLabel = useTranslationLabel();
    const [modifiedQuestions, setModifiedQuestions] = useState<IQuestion[]>([]);
    const [modifiedApproveIds, setModifiedApproveIds] = useState<string[]>([]);
    const [pendingApproval, setPendingApproval] = useState<boolean>(false);
    const [showAdvancedParams, setShowAdvancedParams] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useState<QuestionSearchParams>({
        filter: 'all',
        tags: [],
        term: '',
        offset: 0,
        limit: 100,
        options: {
            noUserObjects: true,
            unApproved: true,
            languages: ['en']
        }
    });

    // To add a new column, just assign a field value that matches the question property (i.e. field: 'id' would give question ID)
    const columns: GridColDef[] = [
        {
            field: 'text',
            headerName: 'Question',
            flex: 3,
            editable: true
        },
        {
            field: 'modifiedApprove',
            headerName: 'modifiedApproval',
            flex: 1,
            editable: false,
            renderCell: (params) => {
                return (<Checkbox
                    onChange={(event) => {
                        let aIds = [...modifiedApproveIds];
                        if (event.target.checked) {
                            if (!aIds.includes(params.row.id)) {
                                aIds.push(params.row.id);
                            }
                        } else {
                            aIds = aIds.filter((id) => id !== params.row.id);
                        }
                        setModifiedApproveIds(aIds);
                    }}
                />);
            }
        },
        {
            field: 'tags',
            headerName: 'Tags',
            flex: 5,
            editable: false,
            renderCell: (params) => {
                console.log(`params:`);

                if (params.row.tags.length === 0) {
                    return (
                        <Box style={{marginTop: 16, width: '100%'}}>
                            <QuestionTagEditor
                                tags={[]}
                                onChange={(tags) => {
                                    if (tags.length > 0) {
                                        params.row.tags = [tags.map((tag) => {
                                            return tag.rdn
                                        })];
                                    }
                                }}/>
                        </Box>
                    )
                } else {
                    return (
                        <Box style={{marginTop: 16, width: '100%'}}>
                            <TagRdnsEditor
                                rdns={params.row.tags}
                                onChange={(tags, index) => {
                                    params.row.tags[index] = tags.map((tag) => {
                                        return tag.rdn
                                    });
                                }}/>
                        </Box>
                    );
                }
            }
        }
        /*
        {
            field: 'sourceUrl',
            headerName: 'Source URL',
            width: 300,
            editable: true,
            renderCell: (params: GridCellParams) => (
                <>{params.value ?? 'https://www.startsole.org/'}</>
            )
        }
         */
    ];

    const approveReject = async (question: IQuestion, doApprove: boolean) => {
        try {
            // if the question has been modified, create a new version with the updated text and approve then unapprove original
            if (doApprove && question.id && modifiedApproveIds.includes(question.id)) {
                // first add the modified question
                const result = await Parse.Cloud.run('question.add', {
                    text: question.text,
                    tags: question.tags,
                    source: 'https://startsole.org'
                });
                // then save the modified question
                if (result.success === "success" && result.question.id) {
                    const newQuestion:IQuestion = {...question, id: result.question.id, parentQuestion: question.id};
                    await Parse.Cloud.run('question.save', {
                        questionJson: newQuestion
                    }, {
                        sessionToken: Parse.User.current()?.getSessionToken()
                    });

                    // approve the new question
                    await Parse.Cloud.run('question.approve', {
                        id: newQuestion.id,
                        comment: `modified from question: ${question.id}`
                    }, {
                        sessionToken: Parse.User.current()?.getSessionToken()
                    });

                    // then reject the original question
                    await Parse.Cloud.run('question.reject', {
                        id: question.id,
                        comment: ''
                    }, {
                        sessionToken: Parse.User.current()?.getSessionToken()
                    });
                }

            } else {
                await Parse.Cloud.run('question.save', {
                    questionJson: question
                }, {
                    sessionToken: Parse.User.current()?.getSessionToken()
                });
                await Parse.Cloud.run(doApprove ? 'question.approve' : 'question.reject', {
                    id: question.id,
                    comment: ''
                }, {
                    sessionToken: Parse.User.current()?.getSessionToken()
                });
            }
        } catch (e: any) {
            return <Error text={e.message}/>;
        }
    };

    useEffect(() => {
        fetchQuestions(searchParams);
    }, []);

    useEffect(() => {
        if (questions) {
            setModifiedQuestions(questions);
            setModifiedApproveIds([]);
        }
    }, [questions]);

    // const onChangedStandardPicker = (state: PickerState) => {
    //     const tags = [state.selectedSubjectRdn ?? '',state.selectedGradeRdn ?? '',...state.selectedTagRdns].filter((tag)=>{return tag!=='' && tag!=='all'});
    //     setSearchParams({...searchParams, tags: tags});
    //     setPickerState(state);
    // };

    if (isLoading || pendingApproval) {
        return <Loading/>;
    } else if (questions) {
        return (
            <>
                <Button variant="contained" color="primary" className={classes.button} onClick={async () => {
                    setPendingApproval(true);
                    for (const id of selectionModel) {
                        const question = modifiedQuestions.find((q) => q.id === id);
                        if (question !== undefined) {
                            await approveReject(question, true);
                        }
                    }
                    setPendingApproval(false);
                    setSelectionModel([]);
                    fetchQuestions(searchParams);
                }}>
                    {getLabel('ui.approve')}
                </Button>
                <Button variant="contained" color="secondary" className={classes.button} onClick={async () => {
                    setPendingApproval(true);
                    for (const id of selectionModel) {
                        const question = modifiedQuestions.find((q) => q.id === id);
                        if (question !== undefined) {
                            await approveReject(question, false);
                        }
                    }
                    setPendingApproval(false);
                    setSelectionModel([]);
                    fetchQuestions(searchParams);
                }}>
                    {getLabel('ui.reject')}
                </Button>
                <IconButton aria-label="delete" onClick={() => {
                    setShowAdvancedParams(!showAdvancedParams)
                }}>
                    <SettingsIcon/>
                </IconButton>
                <Collapse in={showAdvancedParams}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography id="question-search-limit" gutterBottom>
                                Limit
                            </Typography>
                            <Slider
                                className={classes.slider}
                                value={searchParams.limit}
                                getAriaValueText={(value: number) => {
                                    return `${value} questions`;
                                }}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={10}
                                marks
                                min={10}
                                max={110}
                                onChange={(event: object, value: number | number[]) => {
                                    setSearchParams({...searchParams, limit: Array.isArray(value) ? value[0] : value})
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Select
                                labelId="filter-select"
                                id="filter-select"
                                value={searchParams.filter}
                                onChange={(event) => {
                                    const value = event.target.value as QuestionSearchFilterOptions;
                                    if (['all', 'mine', 'fav', 'new'].includes(value)) {
                                        setSearchParams({...searchParams, filter: value})
                                    }
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="mine">Mine</MenuItem>
                                <MenuItem value="fav">Fav</MenuItem>
                                <MenuItem value="new">New</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField id="term" label="Search Terms" onChange={(e) => {
                                setSearchParams({...searchParams, term: e.target.value});
                            }}/>
                        </Grid>
                        {/* <Grid item xs={12}>*/}
                        {/*    <StandardPicker callback={onChangedStandardPicker} onlyStandardsWithQuestions={false} hasSessionContext={false} pickerState={pickerState}/>*/}
                        {/* </Grid>*/}
                    </Grid>
                    <Button onClick={() => {
                        fetchQuestions(searchParams);
                    }}>
                        DO IT!
                    </Button>
                </Collapse>
                <Box style={{height: '1000px'}}>
                    <DataGrid
                        rowHeight={100}
                        rows={questions}
                        columns={columns}
                        pageSize={10}
                        checkboxSelection
                        selectionModel={selectionModel}
                        onSelectionModelChange={(newSelection) => {
                            setSelectionModel(newSelection);
                        }}
                        onCellEditCommit={(e) => {
                            const newQuestions = [...questions];
                            const question = newQuestions.find((q) => q.id === e.id);
                            if (question !== undefined) {
                                if (e.field === 'text' && e.value !== undefined) {
                                    question.text = e.value as string;
                                }
                                if (e.field === 'sourceUrl' && e.value !== undefined) {
                                    question.sourceUrl = e.value as string;
                                }
                                setModifiedQuestions(newQuestions);
                            }
                        }}
                        disableSelectionOnClick
                    />
                </Box>
            </>
        );
    } else if (errorMessage) {
        return (<Error text={errorMessage}/>);
    } else {
        return (<Error text={getLabel('error.fetching_questions')}/>);
    }
};

export default QuestionApprovalBatch;
