import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface QuestionAddState {
    isSaving: boolean;
    addedQuestionId?: string;
    errorMessage?: string;
}

const initialState: QuestionAddState = {
    isSaving: false,
    addedQuestionId: undefined,
    errorMessage: undefined
};

export const questionAddSlice = createSlice({
    name: 'questionAdd',
    initialState: initialState,
    reducers: {
        addQuestion: {
            reducer: (
                state,
                    action: PayloadAction<{questionText: string; tags: string[]}>
            ) => {
                state.isSaving = true;
                state.addedQuestionId = undefined;
                state.errorMessage = undefined;
            },
            prepare: (questionText: string, tags: string[]) => {
                return {payload: {questionText: questionText, tags: tags}}
            }
        },
        addQuestionFailed: (state, action: PayloadAction<string>) => {
            state.isSaving = false;
            state.addedQuestionId = undefined;
            state.errorMessage = action.payload;
        },
        questionAdded: (state, action: PayloadAction<string>) => {
            state.isSaving = false;
            state.addedQuestionId = action.payload;
            state.errorMessage = undefined;
        },
        addQuestionReset: (state) => {
            state.isSaving = false;
            state.addedQuestionId = undefined;
            state.errorMessage = undefined;
        }
    }
});

export const {addQuestion, addQuestionFailed, questionAdded, addQuestionReset} = questionAddSlice.actions;

export default questionAddSlice.reducer;


