import React, {useMemo, useState} from "react";
import {ComposableMap, Geographies, Geography, ProjectionConfig} from "react-simple-maps";
import {scaleQuantize} from "d3-scale";
import {useAppDispatch, useAppSelector} from "../../redux/reduxHooks";
import {ohMap} from "./oh-map"
import {paMap} from "./pa-map";
import ReactTooltip from "react-tooltip";
import {useHistory} from "react-router-dom";
import {loadReport} from "../../redux/reportSlice";
import {selectPub} from "../../redux/userSlice";

interface MapProps {
    ring: string;
    onChange: (event: React.ChangeEvent<{}> | undefined, newValue: number) => void;
}

const MapChart: React.FC<MapProps> = (props) => {
    const [tooltipContent, setTooltipContent] = useState("");
    const [currentGeo, setCurrentGeo] = useState({} as any | undefined);
    const pub = useAppSelector(selectPub);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const leaderRings = pub?.leaderRings ?? undefined;
    useMemo(() => {
        if (currentGeo.properties){
            setTooltipContent(`${currentGeo?.properties.name}<br />${leaderRings?.filter((ring) => (ring.rdn === currentGeo.rdn))[0]?.membershipCount} educators`);
        }
    }, [currentGeo]);
    let mapData = ohMap;
    let pConfig:ProjectionConfig = {
        scale: 5000,
        center: [-82.7, 39.5]
    };
    switch (props.ring) {
        case "us.oh":
            break;
        case "us.pa":
            mapData = paMap;
            pConfig = {
                scale: 5000,
                center: [-78, 40]
            };
            break;
        default:
        return <></>
    }

    let domainMax = 0;
    mapData.features.forEach((geo) => {
        const mCount = leaderRings?.filter((ring) => (ring.rdn === geo.rdn))[0]?.membershipCount;
        if (mCount){
            domainMax = Math.max(mCount / geo.properties.teachers, domainMax);
        }
    });

    const colorScale = scaleQuantize()
        .domain([0, domainMax])
        // @ts-ignore
        .range([
            "#ffedea",
            "#ffcec5",
            "#ffad9f",
            "#ff8a75",
            "#ff5533",
            "#e2492d",
            "#be3d26",
            "#9a311f",
            "#782618"
        ]);


    return (
        <>
            <ComposableMap
                data-tip=""
                projection="geoMercator"
                projectionConfig={pConfig}
            >
                <Geographies geography={mapData}>
                    {({geographies}) =>
                        geographies.map((geo) => {
                            // //@ts-ignore
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    stroke="#A9A9A9"
                                    onMouseEnter={() => {
                                        // updateToolTip(geo);
                                        setCurrentGeo(geo);
                                    }}
                                    onMouseLeave={() => {
                                        setTooltipContent("");
                                    }}
                                    onClick={() => {
                                        history.push(`/rings/${geo.rdn}`);
                                        // set the tab back to educators
                                        props.onChange(undefined, 0);
                                        dispatch(loadReport("report.getDashboardData", geo.rdn, {}));
                                    }}
                                    // @ts-ignore
                                    fill={colorScale(leaderRings?.filter((ring) => ring.rdn === geo.rdn)[0]?.membershipCount / geo.properties.teachers ?? 0)}
                                />
                            );
                        })
                    }
                </Geographies>
            </ComposableMap>
            <ReactTooltip html={true}>{tooltipContent}</ReactTooltip>
        </>
    );
};

export default MapChart;
