import {IQuestion} from "@startsole/startsole-types/src/solejson";
import {useEffect, useState} from "react";
import Parse from "parse";

export type QuestionSearchFilterOptions = 'all' | 'mine' | 'fav' | 'new';

export interface QuestionSearchParams {
    filter: QuestionSearchFilterOptions;
    tags: string[];
    term: string;
    offset: number;
    limit: number;
    options: {
        noUserObjects: boolean;
        unApproved: boolean;
        languages: string[];
    };
};

const useFetchQuestions = (params?: QuestionSearchParams): [IQuestion[] | undefined, boolean, string, (params: QuestionSearchParams) => void] => {
    const [questions, setQuestions] = useState<IQuestion[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        if (params){
            fetchQuestions(params);
        }
    }, []);

    const fetchQuestions = async (params: QuestionSearchParams) => {
        setIsLoading(true);
        try {
            const result = await Parse.Cloud.run('webapp.findQuestions', params,
                {
                    sessionToken: Parse.User.current()?.getSessionToken()
                });
            setQuestions(result.questions);
            setIsLoading(false);
        } catch (e:any) {
            setIsLoading(false);
            setErrorMessage(e.message);
        }
    };

    return [questions, isLoading, errorMessage, fetchQuestions];
};

export default useFetchQuestions;
