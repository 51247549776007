import {IQuestion} from "@startsole/startsole-types/src/solejson";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export interface MyQuestionsState {
    isLoading: boolean;
    errorMessage?: string;
    myQuestions: IQuestion[];
    myFavoriteQuestions: IQuestion[];
}

const initialState: MyQuestionsState = {
    isLoading: false,
    errorMessage: undefined,
    myQuestions: [],
    myFavoriteQuestions: []
};

export const myQuestionsSlice = createSlice({
    name: 'myQuestions',
    initialState: initialState,
    reducers: {
        loadMyQuestions: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        loadMyQuestionsFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        setMyQuestions: (state, action: PayloadAction<IQuestion[]>) => {
            state.myQuestions = action.payload;
        },
        setMyFavoriteQuestions: (state, action: PayloadAction<IQuestion[]>) => {
            state.myFavoriteQuestions = action.payload;
        },
        toggleFavoriteQuestion: (state, action: PayloadAction<string>) => {
            // handled by saga
        },
        loadMyQuestionsFinished: (state) => {
            state.isLoading = false;
            state.errorMessage = undefined;
        }
    }
});

export const {
    loadMyQuestions, loadMyQuestionsFailed, setMyQuestions,
    toggleFavoriteQuestion, setMyFavoriteQuestions,
    loadMyQuestionsFinished} = myQuestionsSlice.actions;
export const selectMyQuestions = (state: RootState) => state.myQuestions.myQuestions;
export const selectMyFavoriteQuestions = (state: RootState) => state.myQuestions.myFavoriteQuestions;

export default myQuestionsSlice.reducer;
