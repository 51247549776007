import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export interface SessionContextItem {
    text: string;
    callback?: () => void;
}

export interface SessionState {
    context: SessionContextItem[];
}

const initialState: SessionState = {
    context: []
};

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSessionContext: (state, action: PayloadAction<SessionContextItem[]>) => {
            state.context = action.payload;
        }
    }
});

export const {setSessionContext} = sessionSlice.actions;
export const selectSessionContext = (state: RootState) => state.session.context;

export default sessionSlice.reducer;
