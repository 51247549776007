import {ISole, SoleState} from "../../../shared/soleTypes";
import React from "react";
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import {Link as RouterLink} from 'react-router-dom'
import {useTranslationLabel} from "../../../hooks/useTranslation";
import Parse from "parse";
import {deleteSole} from "../../../redux/solesSlice";
import {isMobile} from 'react-device-detect';
import {HidableComponent} from "../../hoc/Hidable";
import {useAppDispatch, useAppSelector} from "../../../redux/reduxHooks";
import {selectPub} from "../../../redux/userSlice";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginBottom: "6px"
        },
        action: {
            backgroundColor: theme.palette.primary.main
        },
        title: {
            color: theme.palette.common.white
        },
        flipped: {
            direction: 'rtl'
        }
    })
);

export const getDocumentUrl = async (id: string, type: string): Promise<string> => {
    const baseUrl = process.env.REACT_APP_FILE_BASE_URL ?? '';
    const url: string = await Parse.Cloud.run('webapp.getDownloadLink', {
        id: id,
        type: type
    }, {
        sessionToken: Parse.User.current()?.getSessionToken()
    });
    return baseUrl + url;
};

interface SoleCardProps {
    sole: ISole;
}

const SoleCard: React.FC<SoleCardProps> = (props) => {
    const classes = useStyles();
    const pub = useAppSelector(selectPub);
    const getLabel = useTranslationLabel();
    const dispatch = useAppDispatch();
    const [openDelete, setOpenDelete] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleClickOpenDelete = () => {
        setOpenDelete(true);
    };

    const sole = props.sole;
    const state = sole.state;

    const getDefaultRoute = (sole: ISole): string => {
        let route = `/soles/${sole.id}`;
        if (sole.state === SoleState.StatePlanned || sole.state === SoleState.StateFacilitate) {
            route += '/plan';
        } else if (sole.state === SoleState.StateReflect) {
            route += '/reflect';
        }
        return route;
    };

    const onClickDelete = () => {
        if (sole.id) {
            dispatch(deleteSole(sole.id));
        }
    };

    const onClickDownloadPlan = async () => {
        const url = await getDocumentUrl(sole?.id ?? '', 'plan');
        window.open(url);
    };

    const onClickDownloadSummary = async () => {
        const url = await getDocumentUrl(sole?.id ?? '', 'summary');
        window.open(url);
    };

    const renderActions = () => {
        return (<CardActions>
            {/* TODO: maybe use this for sharing: https://github.com/nygardk/react-share*/}
            {/* <IconButton onClick={onClickShare}  disabled={true}>*/}
            {/*    <ShareOutlinedIcon/>*/}
            {/* </IconButton>*/}

            <Grid
                container
                direction={isMobile ? "column" : "row"}
                justifyContent={isMobile ? "center" : "flex-end"}
                alignItems="flex-start"
                spacing={2}
            >
                <HidableComponent isVisible={pub?.roles?.includes('tester_search') ?? false}>
                    <Grid item>
                        <Button color="primary" variant="contained" component={RouterLink} to={{
                            pathname: '/search-teacher/',
                            state: {
                                sole: sole
                            }
                        }}>{getLabel('ui.search')}</Button>
                    </Grid>
                </HidableComponent>

                <HidableComponent isVisible={isMobile}>
                    <Grid item>
                        <Button color="primary" variant="contained" component={RouterLink} to={{
                            pathname: `/soles/${sole.id}/plan`
                        }}>{getLabel('ui.sole-card.edit')}</Button>
                    </Grid>
                </HidableComponent>
                <Grid item>
                    {(state === SoleState.StateFacilitate || state === SoleState.StatePlanned) &&
                    <Button color="secondary" variant="contained" component={RouterLink} to={{
                        pathname: '/soles/virtual',
                        search: `?sole=${sole.id}`
                    }}>
                        {getLabel('ui.sole-card.virtual')}
                    </Button>
                    }
                </Grid>
                <HidableComponent isVisible={state === SoleState.StateCompleted}>
                    <Grid item>
                        <Button onClick={onClickDownloadSummary}>
                            {getLabel('ui.sole-card.download_summary')}
                        </Button>
                    </Grid>
                </HidableComponent>
                <Grid item>
                    <Button color={isMobile ? "default" : "primary"} variant="contained" onClick={onClickDownloadPlan}>
                        {getLabel('ui.sole-card.download_plan')}
                    </Button>
                </Grid>
                <Grid item>
                    {state !== SoleState.StateCompleted &&
                    <Button color="default" variant="contained" component={RouterLink} to={{
                        pathname: `/soles/${sole.id}/reflect`
                    }}>{getLabel('ui.sole-card.reflect')}</Button>
                    }
                </Grid>
                <HidableComponent isVisible={(state === SoleState.StateFacilitate || state === SoleState.StatePlanned)}>
                    <Grid item>
                        {isMobile ?
                            <Button
                                component={RouterLink}
                                to={{pathname: `/soles/${sole.id}/copy`}}>
                                {getLabel('ui.copy')}
                            </Button> :
                            <IconButton component={RouterLink} to={{
                                pathname: `/soles/${sole.id}/copy`
                            }}>
                                <FileCopyOutlinedIcon/>
                            </IconButton>
                        }

                    </Grid>
                </HidableComponent>
                <Grid item>
                    {isMobile ?
                        <Button onClick={handleClickOpenDelete}>{getLabel('ui.delete')}</Button> :
                        <IconButton onClick={handleClickOpenDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    }
                </Grid>
            </Grid>
        </CardActions>);
    };

    return (
        <Card variant="outlined" className={classes.root}>
            <CardActionArea
                component={isMobile ? Box : RouterLink}
                to={isMobile ? undefined : {pathname: getDefaultRoute(sole)}}
                onClick={isMobile ? handleExpandClick : undefined}
            >
                <CardContent>
                    <Typography variant="h5">
                        {sole.question?.text}
                    </Typography>
                    {sole.tag ? <Typography variant="h6" color="textSecondary">
                        {sole.tag}
                    </Typography> : <></>}
                </CardContent>
            </CardActionArea>
            {isMobile ? <Collapse in={expanded} timeout="auto" unmountOnExit>{renderActions()}
                </Collapse> :
                renderActions()
            }
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{getLabel('ui.sole-card.delete_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {getLabel('ui.sole-card.delete_body')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete} color="primary">
                        {getLabel('ui.cancel')}
                    </Button>
                    <Button onClick={onClickDelete} color="primary" autoFocus>
                        {getLabel('ui.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
};

export default SoleCard;
