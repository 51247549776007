import {Redirect, Route, RouteProps} from "react-router-dom";
import {useAppSelector} from "../redux/reduxHooks";
import {selectPub} from "../redux/userSlice";
import React from "react";

const RoleRoute = ({role, ...rest}: { role: string } & RouteProps<string>) => {
    const pub = useAppSelector(selectPub);

    if (pub?.roles?.includes(role) || (role == "ringleader" && pub?.rings && pub?.rings?.length > 0)) {
        return <Route {...rest}/>;
    } else {
        return <Redirect to="/home"/>;
    }
};

export default RoleRoute;