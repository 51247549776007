import {IResource, IResourceTag} from "../shared/soleTypes";
import {resources, resourceTags} from "./resources";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ResourceState {
    resources: IResource[];
    resourceTags: IResourceTag[];
}

const initialState: ResourceState = {
    resources: resources,
    resourceTags: resourceTags
};

export const resourceSlice = createSlice({
    name: 'resource',
    initialState,
    reducers: {
        setResources: (state, action: PayloadAction<IResource[]>) => {
            state.resources = action.payload;
        },
        setResourceTags: (state, action: PayloadAction<IResourceTag[]>) => {
            state.resourceTags = action.payload;
        }
    }
});

export const {setResources, setResourceTags} = resourceSlice.actions;

export default resourceSlice.reducer;
