import {useEffect, useState} from "react";
import Parse from "parse";
import {ISole} from "../shared/soleTypes";

const useSoleApproval = (): [ISole[] | undefined, (soleId: string, comment: string, requestSocialMedia: boolean) => void, (soleId: string, comment: string, requestSocialMedia: boolean) => void, boolean, string] => {
    const [soles, setSoles] = useState<ISole[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        fetchUnapprovedSoles();
    }, []);

    const fetchUnapprovedSoles = async () => {
        setIsLoading(true);
        try {
            const result = await Parse.Cloud.run('webapp.getUnapprovedSoles', {limit: 999},
                {sessionToken: Parse.User.current()?.getSessionToken()});
            setSoles(result);
            setIsLoading(false);
        } catch (error:any) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    const approveSole = async (soleId: string, comment: string, requestSocialMedia: boolean) => {
        setIsLoading(true);
        try {
            const newSoles = soles.filter((sole) => {return sole.id !== soleId});
            setSoles(newSoles);
            const result = await Parse.Cloud.run('webapp.approveSole', {
                    id: soleId,
                    comment: comment,
                    requestSocialMedia: requestSocialMedia
                },
                {sessionToken: Parse.User.current()?.getSessionToken()});
            setIsLoading(false);
        } catch (error:any) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    const rejectSole = async (soleId: string, comment:string) => {
        setIsLoading(true);
        try {
            const newSoles = soles.filter((sole) => {return sole.id !== soleId});
            setSoles(newSoles);
            const result = await Parse.Cloud.run('webapp.rejectSole', {
                    id: soleId,
                    comment: comment
                },
                {sessionToken: Parse.User.current()?.getSessionToken()});
            setIsLoading(false);
        } catch (error:any) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    return [soles, approveSole, rejectSole, isLoading, errorMessage];
};

export default useSoleApproval;
