import React from "react";
import {Grid, makeStyles} from "@material-ui/core";
import {useTranslationLabel} from "../hooks/useTranslation";

interface LoadingProps {
    rdn?: string;
    fullHeight?: boolean;
}

const useStyles = makeStyles((theme) => ({
    marginAutoContainer: {
        height: `calc(100vh - ${theme.spacing(6)}px - ${theme.mixins.toolbar.minHeight}px - 8px)`
    },
    marginAutoItem: {
        margin: 'auto',
        textAlign: 'center'
    }
}));


const Loading: React.FC<LoadingProps> = (props) => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();

    const caption = props.rdn ? getLabel(props.rdn!) : "";
    const fullHeight = props.fullHeight ?? true;

    return (<Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={fullHeight ? classes.marginAutoContainer : ""}
    >
        <Grid item xs={8} className={classes.marginAutoItem}>
            <img src="/images/logos/animated-logo-small.gif" alt="logo"/>
            <h1>{caption}</h1>
        </Grid>
    </Grid>);
};

export default Loading;
