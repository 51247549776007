import React, {useEffect} from "react";
import {match} from "react-router-dom";
import SoleLoader from "./common/SoleLoader";
import useReflectSteps from "./steps/ReflectSteps";

interface Props {
    match: match<{ id: string }>;
}

const ReflectId: React.FC<Props> = (props) => {
    const steps = useReflectSteps();
    return <SoleLoader steps={steps} match={props.match}/>
};

export default ReflectId;
