import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import {useTranslationLabel} from "../../hooks/useTranslation";
import useFetchQuestions, {QuestionSearchParams} from "../../hooks/useFetchQuestions";
import ReactWordcloud, {CallbacksProp, OptionsProp} from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import * as sw from 'stopword';
import Loading from "../LoadingComponent";
import Error from "../ErrorComponent";
import {MemoizedQuestionCards} from "./QuestionCardsComponent";

interface CloudWord {
    text: string;
    value: number;
}

const QuestionsExplore: React.FC = () => {
    const [questions, isLoading, errorMessage, fetchQuestions] = useFetchQuestions();
    // const [pickerState, setPickerState] = useState<PickerState >(initialPickerState);
    const getLabel = useTranslationLabel();
    const [words, setWords] = useState<CloudWord[]>([]);
    const [searchParams, setSearchParams] = useState<QuestionSearchParams>({
        filter: 'all',
        tags: [],
        term: '',
        offset: 0,
        limit: 200,
        options: {
            noUserObjects: true,
            unApproved: false,
            languages: ['en']
        }
    });


    useEffect(() => {
        fetchQuestions(searchParams);
    }, [searchParams]);

    useEffect(() => {
        if (questions){
            const newWords:CloudWord[] = [];
            questions?.forEach((q) => {
                let questionText = q.text;
                questionText = questionText?.replace(/[\?\.\!\,\;]/g, " ");
                let arr = questionText?.split(" ") ?? [];
                arr = sw.removeStopwords(arr);
                arr = sw.removeStopwords(arr, ['Who', 'who', 'What', 'what', 'Why', 'why', 'does', 'when', 'How', 'how', 'Us', 'us', ' ', '']);
                arr?.forEach((w) => {
                    let cloudWord = newWords.find((cloudWord) => cloudWord.text === w);
                    if (cloudWord === undefined){
                        cloudWord = {
                            text: w,
                            value: 0
                        };
                        newWords.push(cloudWord);
                    }
                    cloudWord.value++;
                })
            });
            setWords(newWords);
        }
    }, [questions]);

    const callbacks:CallbacksProp = {
        onWordClick: (e) => {
            setSearchParams({...searchParams, term: e.text});
        },
        getWordTooltip: (word) => `${word.text} (${word.value} questions)`
    };

    const options:OptionsProp = {
        colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
        enableTooltip: true,
        deterministic: false,
        fontFamily: "impact",
        fontSizes: [18, 60],
        fontStyle: "normal",
        fontWeight: "normal",
        padding: 1,
        rotations: 3,
        rotationAngles: [0, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 1000
    };

    // const onChangedStandardPicker = (state: PickerState) => {
    //     if(JSON.stringify(state) !== JSON.stringify(pickerState)){
    //         const tags = [state.selectedSubjectRdn ?? '',state.selectedGradeRdn ?? '',...state.selectedTagRdns].filter((tag)=>{return tag!=='' && tag!=='all'});
    //         setSearchParams({...searchParams, tags: tags});
    //         setPickerState(state);
    //     }
    // };

    if (isLoading) {
        return <Loading/>;
    } else if (questions) {
        return (
            <Grid container>
                <Grid item xs={12} sm={9} alignItems="center">
                    <div style={{width: "100%", height: "800px"}}>
                        <ReactWordcloud
                            callbacks={callbacks}
                            options={options}
                            // size={size}
                            words={words}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    {/* <StandardPicker callback={onChangedStandardPicker} onlyStandardsWithQuestions={false} hasSessionContext={false} pickerState={pickerState}/>*/}
                    <MemoizedQuestionCards questions={questions}/>
                </Grid>
            </Grid>
        );
    } else if (errorMessage) {
        return <Error text={errorMessage}/>;
    } else {
        return <Error text={getLabel('error.fetching_questions')}/>;
    }
};

export default QuestionsExplore;
