import React, {useEffect} from 'react';
import Login from "./LoginComponent";
import {useAppDispatch} from "../redux/reduxHooks";
import {resetUser} from "../redux/userSlice";

const Logout: React.FC = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(resetUser());
    });

    return <Login/>;
};

export default Logout;
