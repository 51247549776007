export class Constraint {
}

export class StringConstraints extends Constraint {
    isRequired?: boolean
}

export class NumberConstraints extends Constraint {
    min?: number;
    max?: number;
    step?: number;
    defaultValue?: number;
    unit?: 'percent' | 'min';
}

export class BooleanConstraints extends Constraint {
    defaultValue?: boolean;
}

export class SelectionConstraints extends Constraint {
    min?: number;
    max?: number;
    items: {
        rdn: string;
        /**
         * Optional item title. Use the text from the text redux store if it's
         * undefined
         */
        title?: string;
    }[] = [];
}

export type ConstraintClassName =
    "StringConstraints" |
    "NumberConstraints" |
    "BooleanConstraints" |
    "SelectionConstraints";

// Returns the class name of a constraint. We don't use
// C.constructor.name because this doesn't work with minification
export const getConstraintClassName = (object: Constraint): ConstraintClassName | undefined => {
    if (object instanceof StringConstraints) {
        return "StringConstraints";
    } else if (object instanceof NumberConstraints) {
        return "NumberConstraints";
    } else if (object instanceof BooleanConstraints) {
        return "BooleanConstraints";
    } else if (object instanceof SelectionConstraints) {
        return "SelectionConstraints";
    } else {
        return undefined;
    }
};

// Creates a constraint instance of the given type
export const createConstraint = (className: ConstraintClassName): Constraint => {
    let c: Constraint;
    switch (className) {
        case "SelectionConstraints":
            c = new SelectionConstraints();
            break;
        case "StringConstraints":
            c = new StringConstraints();
            break;
        case "NumberConstraints":
            c = new NumberConstraints();
            break;
        case "BooleanConstraints":
            c = new BooleanConstraints();
            break;
    }
    return c;
};
