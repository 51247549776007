import {IQuestion} from "@startsole/startsole-types/src/solejson";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export interface QuestionsSearchState {
    isLoading: boolean;
    questions: IQuestion[];
    errorMessage?: string;
}

const initialState: QuestionsSearchState = {
    isLoading: false,
    questions: [],
    errorMessage: undefined
};

export const questionSearchSlice = createSlice({
    name: 'questionSearch',
    initialState: initialState,
    reducers: {
        searchQuestions: {
            reducer: (
                state,
                action: PayloadAction<{ keywords: string; tags: string[] }>
            ) => {
                // will be handled by saga
            },
            prepare: (keywords: string, tags: string[]) => {
                return {payload: {keywords: keywords, tags: tags}}
            }
        },
        questionsLoading: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
            state.questions = [];
        },
        loadQuestionsFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
            state.questions = [];
        },
        setSearchQuestions: (state, action: PayloadAction<IQuestion[]>) => {
            state.isLoading = false;
            state.errorMessage = undefined;
            state.questions = action.payload;
        }
    }
});

export const {searchQuestions, questionsLoading, loadQuestionsFailed, setSearchQuestions} = questionSearchSlice.actions;
export const selectSearchQuestions = (state: RootState) => state.questionsSearch.questions;

export default questionSearchSlice.reducer;
