import {Grid} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import StandardPicker, {StandardPickerState} from "../../standards/StandardPickerComponent";
import {
    soleFromStandardPickerState,
    standardPickerStateFromSole,
    willUpdateSoleWithPickerstate
} from "../../standards/StandardsHelper";
import {Props, ValidatePart} from "./00_PartProps";
import Typography from "@material-ui/core/Typography";

export const validateStandardsPart:ValidatePart = (_props) => {
    return null;
};

export const StandardsPart: React.FC<Props> = ({props}) => {

    const [, setForceRender] = useState(0);
    const forceRenderRef = useRef(() => setForceRender((current) => current + 1));

    useEffect(() => {
        (async () => { // mount => copy state from sole
            const newState = await standardPickerStateFromSole(props.sole, props.standardPickerState);
            setNewstate(newState);
            forceRenderRef.current();
        })();
    }, []);

    const setNewstate = (newState:StandardPickerState) => {
        props.standardPickerState = newState;
        if (willUpdateSoleWithPickerstate(props.standardPickerState, props.sole)) {
            props.setSole(soleFromStandardPickerState(props.standardPickerState, {...props.sole}));
        }
    };

    return (
        <Grid container justify="space-around" spacing={3}>
            <Grid item xs={12} sm={8}>
                <Typography paragraph>{props.getLabel("ui.plan.standard")}:</Typography>
                <StandardPicker
                    hasSessionContext={false}
                    onChange={ (newState) => setNewstate({...newState})}
                    onlyStandardsWithQuestions={false}
                    standardPickerState={props.standardPickerState}/>
            </Grid>
        </Grid>
    );
};
