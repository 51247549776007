import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {match, useHistory} from "react-router-dom";
import Loading from "../LoadingComponent";
import {useTranslationLabel} from "../../hooks/useTranslation";
import useFetchQuestion from "../../hooks/useFetchQuestion";
import QuestionTagEditor from "../QuestionTagEditor";
import {Tag} from "../../data/Tag";
import Parse from "parse";
import Error from "../ErrorComponent";

interface QuestionEditProps {
    match: match<{ id: string }>;
}

const QuestionEdit: React.FC<QuestionEditProps> = ({match}) => {
    const [question, isLoading, loadingErrorMessage] = useFetchQuestion(match.params.id);
    const [tags, setTags] = useState<Tag[][]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoadingTags, setIsLoadingTags] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const getLabel = useTranslationLabel();
    const history = useHistory();
    const [text, setText] = useState('');

    useEffect(() => {
        (async () => {
            setText(question?.text ?? '');
            if (question !== undefined && question.tags !== undefined && question.tags.length > 0) {
                setIsLoadingTags(true);
                const newTagTags: Tag[][] = [];
                for (const tagRdns of question.tags) {
                    const newTags: Tag[] = [];
                    for (const tagRdn of tagRdns) {
                        const tag = await Parse.Cloud.run(
                            'tag.getJson',
                            {
                                rdn: tagRdn,
                                options: {}
                            },
                            {
                                sessionToken: Parse.User.current()?.getSessionToken()
                            });
                        newTags.push(tag);
                    }
                    newTagTags.push(newTags);
                }
                setTags(newTagTags);
                setIsLoadingTags(false);
            } else {
                setTags([]);
            }
        })();
    }, [question]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (question != undefined) {
            const newQuestion = {...question};
            newQuestion.text = text;
            if (tags.length > 0 && tags[0].length > 0) {
                newQuestion.tags = tags.map((tagArr) => {
                    return tagArr.map((tag) => {
                        return tag.rdn
                    })
                });
            } else {
                newQuestion.tags = [];
            }
            await Parse.Cloud.run('question.save', {
                questionJson: newQuestion
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
            history.push(`/questions/${question.id}`);
        }
    };

    if (isSaving) {
        return (<div>
            <Loading rdn="ui.adding_question"/>
        </div>)
    } else if (isLoadingTags) {
        return <Loading rdn="ui.loading_tags"/>
    } else if (errorMessage) {
        return <Error text={errorMessage}/>
    } else {
        return (<Container component="main">
            <form onSubmit={handleSubmit} noValidate>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    maxRows={4}
                    id="question"
                    label={getLabel('ui.enter_your_question')}
                    name="question"
                    autoComplete="question"
                    autoFocus
                    value={text}
                    onChange={(e) => {
                        setText(e.target.value)
                    }}
                />

                <Button
                    disabled={(question?.text?.length ?? 0) === 0}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    {getLabel('ui.save')}
                </Button>
            </form>
            {tags.map((t, index) => {
                return (<QuestionTagEditor
                    key={`question-tag-editor-${index}`}
                    tags={t}
                    onChange={(changedTags) => {
                        const newTags = [...tags];
                        newTags[index] = changedTags;
                        setTags(newTags);
                    }}
                />);
            })}
        </Container>);
    }
};

export default QuestionEdit;
