import React, {useEffect, useState} from 'react';
import Loading from "../LoadingComponent";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {Button, Grid, Typography} from '@material-ui/core';
import ReactCodeInput from 'react-code-input';
import Parse from "parse";
import {IPublicSole} from "@startsole/startsole-types/src/publictypes";
import Error from "../ErrorComponent";
import {Redirect, useLocation} from "react-router-dom";

const tokenLength = 6;

const TokenInput: React.FC = () => {
    const getLabel = useTranslationLabel();
    const query = new URLSearchParams(useLocation().search);
    const queryToken = query.get('token');
    const [isLoading, setIsLoading] = useState(false);
    const [publicToken, setPublicToken] = useState(queryToken ?? '');
    const [publicSole, setPublicSole] = useState<IPublicSole | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (queryToken !== null && queryToken.length === tokenLength) {
            onClickJoin();
        }
    }, []);

    const onClickJoin = () => {
        (async () => {
            try {
                setIsLoading(true);
                const publicSole: IPublicSole | undefined = await Parse.Cloud.run('sole.getPublicSole', {
                    publicToken: publicToken
                }, { });
                setErrorMessage(undefined);
                setPublicSole(publicSole);
                setIsLoading(false);
            } catch (error:any) {
                setErrorMessage(error.message);
                setIsLoading(false);
            }
        })();
    };

    if (isLoading) {
        return <Loading/>
    } else if (errorMessage !== undefined) {
        return <Error text={errorMessage}/>
    } else if (publicSole !== undefined) {
        return (<Redirect to={{
            pathname: '/search',
            state: {
                publicSole: publicSole
            }
        }}/>);
    } else {
        return (
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={4}
            >
                <Grid item>
                    <img src="/images/logos/animated-logo-no-repeat.gif" alt="logo"/>
                </Grid>
                <Grid item>
                    <Typography variant="body2" gutterBottom align="center">
                        {getLabel('ui.enter_code')}
                    </Typography>
                    <ReactCodeInput
                        value={publicToken}
                        onChange={(value) => { setPublicToken(value) }}
                        fields={tokenLength}
                        name="publicToken"
                        inputMode="numeric"/>
                </Grid>
                <Grid item>
                    <Button disabled={publicToken.length < tokenLength} variant="contained" color="secondary" onClick={() => onClickJoin()}>
                        {getLabel('ui.join')}
                    </Button>
                </Grid>
            </Grid>);
    }
};

export default TokenInput;
