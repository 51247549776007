import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Copyright from "./CopyrightComponent";
import {IconButton, InputAdornment} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {Alert} from "@material-ui/lab";
import GoogleLogin from "react-google-login";
import Parse from "parse";
import {useTranslationLabel} from "../hooks/useTranslation";
import Loading from "./LoadingComponent";
import {useAppDispatch, useAppSelector} from "../redux/reduxHooks";
import {loginWithToken, signUp} from "../redux/userSlice";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

const Register: React.FC = () => {
    const getLabel = useTranslationLabel();
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const isLoadingText = useAppSelector((state) => state.text.isLoading);
    const [googleError, setGoogleError] = useState('');
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    const parsed = queryString.parse(location.search);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        dispatch(signUp(email.toLowerCase(), password));
    };

    const responseGoogle = (response: any) => {
        // TODO: @Frans we should have a test for this
        // const googleToken = response.getAuthResponse(true).access_token;
        const googleToken = response.accessToken;
        if (googleToken) {
            (async () => {
                const sessionToken = await Parse.Cloud.run('loginGoogleUser', {
                    token: googleToken
                });
                dispatch(loginWithToken(sessionToken, response.profileObj.email));
            })();
        } else if (response.error) {
            setGoogleError(response.details);
        }
    };

    if (isLoadingText) {
        return <Loading/>;
    } else {
        return (<Container component="main" maxWidth="xs">
            <div className={classes.root} id="register">
                {parsed.ring === "colombia" ? <img src="/images/logos/colombia-logo.png" alt="logo"/> : <img src="/images/logos/animated-logo-no-repeat.gif" alt="logo"/>}
                <Typography component="h1" variant="h5">
                    {getLabel('ui.register')}
                </Typography>
                <Box m={3}>
                    {googleError ?
                        <Alert
                            severity="error">
                            {getLabel('ui.login.google_unavailable')} - {googleError}
                        </Alert>
                        : <div/>
                    }
                    {user.errorMessage ?
                        <Alert
                            severity="error">
                            {user.errorMessage}
                        </Alert>
                        : <div/>
                    }
                </Box>
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={getLabel('ui.profile.email')}
                        name="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={getLabel('ui.profile.password')}
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {getLabel('ui.register.sign_up')}
                    </Button>
                </form>
                <Grid container justifyContent="center">
                    <Typography variant="h6" gutterBottom>
                        {getLabel('ui.login.or')}
                    </Typography>
                </Grid>
                <Grid container justifyContent="center">
                    {/* TODO: there shouldn't be a client ID in here*/}
                    <GoogleLogin
                        clientId="824307770319-v35co4sbfui42q2a93hcccvk68hfpam7.apps.googleusercontent.com"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        responseType="token"
                        cookiePolicy="single_host_origin"
                        theme="dark"
                        buttonText={getLabel('ui.register.register_with_google')}
                    />

                    <Grid item xs={12} justifyContent="center" alignContent="center" alignItems="center">
                        <Box mt={5}>
                            <Typography align="center" variant="body2">
                                <Link href="/login">
                                    {getLabel('ui.register.already_have_account')}
                                </Link>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container/>
            </div>
            <Box mt={3}>
                <Copyright/>
            </Box>
        </Container>)
    }
};

export default Register;
