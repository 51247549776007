import React, {useEffect, useState} from "react";
import {ISole} from "../../shared/soleTypes";
import {
    Box, Button,
    createStyles,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles, Snackbar,
    TextField,
    Theme,
    Typography
} from "@material-ui/core";
import {useTranslationLabel} from "../../hooks/useTranslation";
import Parse from "parse";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {QRCode} from "react-qrcode-logo";
import {HidableComponent} from "../hoc/Hidable";

interface SearchTeacherProps {
    location: {
        state: {
            sole: ISole;
        };
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'center',
            '& .MuiTextField-root': {
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(1),
                width: 380
            }
        },
        snackbar: {
            "& .MuiSnackbarContent-message": {
                margin: 'auto',
                textAlign: 'center'
            }
        }
    })
);

const SearchTeacher: React.FC<SearchTeacherProps> = (props) => {
    const classes = useStyles();
    const sole = props.location.state.sole;
    const getLabel = useTranslationLabel();
    const [publicToken, setPublicToken] = useState<string | undefined>(undefined);
    const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
    const shareUrl = publicToken ? `${window.location.origin}/join?token=${publicToken}` : '';

    useEffect(() => {
        (async () => {
            try {
                const token = await Parse.Cloud.run('sole.getPublicToken', {
                    soleId: sole.id
                });
                setPublicToken(token);
            } catch {
                console.log('SearchTeacher: missing token');
            }

        })();
    }, [sole]);

    return (<Grid container alignItems="center" direction="column">
        <Grid item sm={12}>
            <Typography variant="h4" align="center" gutterBottom>
                {sole.question?.text}
            </Typography>
            <Typography variant="h5" align="center" gutterBottom>
                {getLabel('ui.public.title')}
            </Typography>
            <Typography variant="body1" align="center">
                {getLabel('ui.public.copy_link')}
            </Typography>
        </Grid>
        <Grid item sm={12}>
            <form className={classes.root} noValidate autoComplete="off">
                <TextField
                    id="outlined-read-only-input"
                    value={shareUrl}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <CopyToClipboard
                                    text={shareUrl ?? ''}
                                    onCopy={() => setIsCopiedToClipboard(true)}
                                >
                                    <IconButton aria-label="copy">
                                        <FileCopyIcon/>
                                    </IconButton>
                                </CopyToClipboard>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{
                        style: {
                            textAlign: 'center'
                        }
                    }}
                    fullWidth
                    variant="outlined"
                />
            </form>
            <Box display="flex" mb={5}>
                <Box m="auto">
                    <Button variant="contained"
                            href={`https://classroom.google.com/share?url=${shareUrl}&title=`}
                            target="_blank">
                        {getLabel('ui.virtual.share_to_classroom')}
                    </Button>
                </Box>
            </Box>
        </Grid>
        <Grid item sm={12} alignContent="center">
            <HidableComponent isVisible={shareUrl !== ''}>
                <QRCode value={shareUrl}/>
            </HidableComponent>
        </Grid>
        <Snackbar
            open={isCopiedToClipboard}
            message={getLabel('ui.copied_to_clipboard')}
            onClose={() => {
                setIsCopiedToClipboard(false)
            }}
            autoHideDuration={300000}
            className={classes.snackbar}
        />
    </Grid>);
};

export default SearchTeacher;
