import Parse from "parse";
import {put} from "redux-saga/effects";
import {IReport} from "../shared/soleTypes";
import {PayloadAction} from "@reduxjs/toolkit";
import {loadReportFailed, loadReportFinished, setReport} from "../redux/reportSlice";

export const loadReportSaga = function*(action: PayloadAction<{name: string; ring: string; parms: {}}>) {
    try {
        const report: IReport = {
            name: action.payload.name,
            ring: action.payload.ring,
            parms: action.payload.parms,
            data: undefined
        };

        // trigger a background update for the dashboarddata
        if (report.name === "report.getDashboardData") {

            Parse.Cloud.run("ring.updateDashboardDataForRingRdn", {ringRdn:report.ring}, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
        }

        report.data = yield Parse.Cloud.run(report.name, {ring:report.ring, ...report.parms}, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });

        yield put(setReport(report));
        yield put(loadReportFinished());
    } catch (error:any) {
        yield put(loadReportFailed(error.message));
    }
};
