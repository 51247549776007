import React from 'react';
import './App.css';
import './shared/ParseUtil';
import {BrowserRouter} from "react-router-dom";
import Main from "./components/MainComponent";
import {Provider} from "react-redux";
import store from "./redux/store";

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Main/>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
