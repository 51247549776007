import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {useLocation} from "react-router-dom";
import Loading from "../LoadingComponent";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {Container, Grid, Link, List, ListItem} from '@material-ui/core';
import {GoogleCustomSearchResult} from '../../shared/GoogleCustomSearch';
import {HidableComponent} from "../hoc/Hidable";
import {IPublicSole} from "@startsole/startsole-types/src/publictypes";
import ReactPlayer from "react-player";
import {useAppSelector} from "../../redux/reduxHooks";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    error: {
        color: 'red',
        marginBottom: theme.spacing(4)
    },
    player: {
        position: 'absolute',
        top: 0,
        left: 0
    },
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */
    }
}));

interface SearchProps {
    location: {
        state?: {
            publicSole: IPublicSole;
        };
    };
}

const Search: React.FC<SearchProps> = (props) => {
    const getLabel = useTranslationLabel();
    const classes = useStyles();
    const query = new URLSearchParams(useLocation().search);
    const [terms, setTerms] = useState('');
    const [showDummy, setShowDummy] = useState(false);
    const [results, setResults] = useState<GoogleCustomSearchResult[]>([]);
    const isLoading = useAppSelector((state) => state.user.isLoading);
    const isLoadingText = useAppSelector((state) => state.text.isLoading);
    const publicSole = props.location.state?.publicSole;

    const handleSubmit = (terms: string) => {
        sendQuery(terms);
        setShowDummy(true);
    };

    const renderSole = () => {
        if (publicSole === undefined) {
            return <div/>;
        } else {
            return (
                <>
                    <Typography component="h1" variant="h5" align="center" gutterBottom={true}>
                        {publicSole.question.text}
                    </Typography>
                    <Container maxWidth="sm">
                        <Grid container spacing={3}>
                            {
                                publicSole.question.resources?.map((resource, index) => {
                                    return (
                                        <Grid item xs={12} key={`resource-grid-${index}`}>
                                            <div className={classes.playerWrapper}>
                                                <ReactPlayer
                                                    className={classes.player}
                                                    height="100%"
                                                    width="100%"
                                                    key={`resource-${index}`}
                                                    url={resource.url}/>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Container>
                </>
            );
        }
    };

    // @ts-ignore
    const sendQuery = (query) => {
        (async () => {
            try {
                const res = await fetch(`https://customsearch.googleapis.com/customsearch/v1?key=AIzaSyAPiatS7uVrcQx2F-MNZPGeBiPKUxK_B4E&cx=1dab4c236ad2ecff3&q=${query}`);
                const json = await res.json();
                setResults(json.items);
            } catch (error) {
                console.log(error);
            }
        })();
    };

    if (isLoading) {
        return <Loading rdn="ui.login.logging_in"/>
    } else if (isLoadingText) {
        return <Loading/>
    } else {
        return (
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={4}
            >
                <Grid item>
                    <img src="/images/logos/animated-logo-no-repeat.gif" alt="logo"/>
                </Grid>
                {renderSole()}
                <Grid item>
                    <Typography component="h1" variant="h5" align="center" gutterBottom={true}>
                        {query.get("q")}
                    </Typography>
                </Grid>
                <Grid item>
                    <HidableComponent isVisible={showDummy}>
                        <img src="/images/dummy-images/dummy-results.png"/>
                    </HidableComponent>
                </Grid>
                <Grid item>
                    <List dense={true}>
                        {results?.map((result: GoogleCustomSearchResult, index) => {
                                const attachment: any = {
                                    type: "GoogleCustomSearchResult",
                                    title: result.title,
                                    link: result.link
                                };

                                return (
                                    <ListItem key={`list-result-${index}`} onClick={() => {
              //                          console.log('clicked on me!');
                                    }}>
                                        <Typography>{result.title}</Typography>
                                        <Link
                                            href={result.link}
                                            target="_blank"
                                            noWrap={true}
                                        >
                                            open site</Link>
                                    </ListItem>
                                )
                            }
                        )}
                    </List>
                </Grid>
            </Grid>);
    }
};

export default Search;
