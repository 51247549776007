import {DataPoint} from "../data/DataPoint";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export interface DataPointsState {
    isLoading: boolean;
    errorMessage?: string;
    dataPoints: DataPoint[];
}

const initialState: DataPointsState = {
    isLoading: false,
    errorMessage: undefined,
    dataPoints: []
};

export const dataPointSlice = createSlice({
    name: 'datapoints',
    initialState,
    reducers: {
        loadDataPoints: (state) => {
            // will be handled by saga
        },
        dataPointsLoading: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
            state.dataPoints = [];
        },
        dataPointsLoadingFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
            state.dataPoints = [];
        },
        setDataPoints: (state, action: PayloadAction<DataPoint[]>) => {
            state.isLoading = false;
            state.errorMessage = undefined;
            state.dataPoints = action.payload;
        }
    }
});

export const {loadDataPoints, dataPointsLoading, dataPointsLoadingFailed, setDataPoints} = dataPointSlice.actions;

export const selectDataPoints = (state: RootState) => state.dataPoints.dataPoints;

export default dataPointSlice.reducer;
