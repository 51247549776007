import {put} from "redux-saga/effects";
import Parse from "parse";
import {loadSoles, setSoles, solesLoading, solesLoadingFailed} from "../redux/solesSlice";
import {PayloadAction} from "@reduxjs/toolkit";
import {ISole} from "../shared/soleTypes";

export const loadSolesSaga = function* (){
    yield put(solesLoading());
    try {
        const solesJson = localStorage.getItem('soles') as string;
        if (solesJson) {
            try {
                yield put(setSoles(JSON.parse(solesJson)));
            } catch (error:any) {
                // ignore error
            }
        }

        // TODO: @Steffen handle cases where users have a lot of SOLEs
        // @ts-ignore
        const soles = yield Parse.Cloud.run("webapp.getSolesWithQuestions", {
            offset: 0,
            limit: 100
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(setSoles(soles));
    } catch (error:any) {
        yield put(solesLoadingFailed(error.message));
    }
};

export const setSolesSaga = (action: PayloadAction<ISole[]>) => {
    localStorage.setItem('soles', JSON.stringify(action.payload));
};

export const deleteSoleSaga = function*(action: PayloadAction<string>) {
    try {
        yield Parse.Cloud.run("webapp.deleteSole", {
            id: action.payload
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(loadSoles());
    } catch (error:any) {
        yield put(solesLoadingFailed(error.message));
    }
};

export const addSoleSaga = function*(action: PayloadAction<string>) {
    yield Parse.Cloud.run('event.trigger', {rdn: 'session.plan.start', isActivity:false, objectId: action.payload, className:'Sole'}, {
        sessionToken: Parse.User.current()?.getSessionToken()
    });
    yield Parse.Cloud.run('event.trigger', {rdn: 'session.plan.done', isActivity:false, objectId: action.payload, className:'Sole'}, {
        sessionToken: Parse.User.current()?.getSessionToken()
    });
};
