import React from 'react';
import RingTable from "./RingTable";
import {useAppSelector} from "../../redux/reduxHooks";
import {selectPub} from "../../redux/userSlice";

const Admin: React.FC = () => {
    const pub = useAppSelector(selectPub);
    return pub?.leaderRings ? <RingTable rings={pub?.leaderRings}/> : <div/>;
};

export default Admin;
