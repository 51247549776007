import Parse from "parse";
import {ISchool, IUserPub} from "../shared/soleTypes";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export interface UserState {
    isLoading: boolean;
    errorMessage?: string;
    /**
     * Todo: get rid of user in the redux store because it's not serializable.
     * Use Parse.User.current() instead.
     */
    user?: Parse.User;
    pub?: IUserPub;
    school?: ISchool;
}

const initialState: UserState = {
    isLoading: false,
    errorMessage: undefined,
    user: undefined,
    pub: undefined,
    school: undefined
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        signUp: {
            reducer: (state, action: PayloadAction<{ email: string; password: string}>) => {
                // handled by saga
            },
            prepare: (email: string, password: string) => {
                return {payload: {email: email, password: password}}
            }
        },
        login: {
            reducer: (state, action: PayloadAction<{ email: string; password: string }>) => {
                // handled by saga
            },
            prepare: (email: string, password: string) => {
                return {payload: {email: email, password: password}}
            }
        },
        loginWithToken: {
            reducer: (state, action: PayloadAction<{ sessionToken: string; email: string }>) => {
                // handled by saga
            },
            prepare: (sessionToken: string, email: string) => {
                return {payload: {sessionToken: sessionToken, email: email}}
            }
        },
        loginPending: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
            state.user = undefined;
            state.pub = undefined;
            state.school = undefined;
        },
        loginFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
            state.user = undefined;
            state.pub = undefined;
            state.school = undefined;
        },
        setCurrentUser: {
            reducer: (state, action: PayloadAction<{user: Parse.User; pub: IUserPub}>) => {
                state.isLoading = false;
                state.errorMessage = undefined;
                state.user = action.payload.user;
                state.pub = action.payload.pub;
            },
            prepare: (user: Parse.User, pub: IUserPub) => {
                return {payload: {user: user, pub: pub}}
            }
        },
        saveUser: (state, action: PayloadAction<IUserPub>) => {
            // handled by saga
        },
        saveUserFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        setSchool: (state, action: PayloadAction<ISchool>) => {
            state.school = action.payload;
        },
        saveSchool: (state, action: PayloadAction<string>) => {
            // handled by saga
        },
        saveSchoolFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        resetUser: (state) => {
            state.isLoading = false;
            state.errorMessage = undefined;
            state.user = undefined;
            state.pub = undefined;
            state.school = undefined;
        },
        completeOnboarding: (state, action: PayloadAction<IUserPub>) => {
            // handled by saga
        }
    }
});

export const {
    signUp,
    login, loginFailed, loginPending, loginWithToken,
    saveUser, saveUserFailed,
    setCurrentUser, resetUser,
    saveSchoolFailed, saveSchool, setSchool,
    completeOnboarding
} = userSlice.actions;

export const selectPub = (state: RootState) => state.user.pub;

export default userSlice.reducer;
