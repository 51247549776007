import {put, select} from "redux-saga/effects";
import {loadSoles, SolesState} from "../redux/solesSlice";
import Parse from "parse";
import {ISchool, IUserPub} from "../shared/soleTypes";
import {isEmbedded, notifyHost} from "../embedded";
import {RootState} from "../redux/store";
import {DataPointsState, loadDataPoints} from "../redux/dataPointSlice";
import {loadTags} from "../redux/tagsSlice";
import {
    loginFailed,
    loginPending,
    resetUser,
    saveSchoolFailed,
    saveUser,
    saveUserFailed,
    setCurrentUser,
    setSchool
} from "../redux/userSlice";
import {PayloadAction} from "@reduxjs/toolkit";

const loadUserPub = async (): Promise<IUserPub | undefined> => {
    try {
        notifyHost(`loading userPub...`);
        return await Parse.Cloud.run('userpub.getJson', {}, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
    } catch (error: any) {
        notifyHost(`failed loading user error=${JSON.stringify(error)}`);
        return undefined;
    }
};

const loadSchool = async (id: string): Promise<ISchool | undefined> => {
    try {
        return await Parse.Cloud.run('school.getByIdJson', {id: id}, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
    } catch (error: any) {
        return undefined;
    }
};

export const loadSchoolFromLocalStorage = function* () {
    const schoolJson = localStorage.getItem('school') as string;
    if (schoolJson) {
        try {
            const school = JSON.parse(schoolJson) as ISchool;
            yield put(setSchool(school));
        } catch (error: any) {
            console.error(error);
        }
    }
};

async function become(token: string) {
    notifyHost(`start become token=${token}`);
    const user = await Parse.User.become(token);
    notifyHost(`ready become user=${user?.id ?? "unknown"} userToken=${user ? user.getSessionToken() : "unknown"}`);
    return user
}

const loadPubFromLocalStorage = function* (user: Parse.User) {
    const pubJson = localStorage.getItem('pub') as string;
    if (pubJson) {
        try {
            const pub = JSON.parse(pubJson);
            notifyHost(`loadCurrentUserFromLocalStorage pub=${pub ? pub.id : "unknown"}`);
            yield put(setCurrentUser(user, pub));
        } catch (error: any) {
            notifyHost(`error loadCurrentUserFromLocalStorage pubJson "${JSON.stringify(error)}"`);
        }
    }
};

const loadAndSetUserPub = function* (user: Parse.User, reset: boolean) {
    notifyHost(`loadUserPub start user=${user?.id ?? "unknown"} token=${user?.getSessionToken() ?? "unknown"}`);
    // @ts-ignore
    const pub = yield loadUserPub();
    notifyHost(`loadUserPub ready pub=${pub?.id ?? "unknown"}`);
    if (pub) {
        yield put(setCurrentUser(user, pub));
    } else if (reset) { // logout on error
        notifyHost(`loadUserPub reset"}`);
        // TODO: maybe include some contextual message here so users can see what's up
        yield put(resetUser());
    }
};

export const loadCurrentUserFromLocalStorage = function* () {
    let user = Parse.User.current();

    notifyHost(`start loadCurrentUserFromLocalStorage path=${window.location.pathname} user=${user?.id ?? "unknown"} userToken=${user ? user.getSessionToken() : "unknown"}`);

    // optionally perform impersonation
    if (window.location.pathname.includes("/become")) {
        try {
            const sessionToken = window.location.pathname.split("/").pop();
            notifyHost(`loadCurrentUserFromLocalStorage token=${sessionToken} userToken=${user ? user.getSessionToken() : "unknown"}`);
            if (sessionToken) {
                if (!user || sessionToken !== user.getSessionToken()) { // different token => perform become
                    user = yield become(sessionToken);
                } else { // no user or same token => reload pub
                    yield loadPubFromLocalStorage(user);
                }
                yield loadAndSetUserPub(user!, false); // reload from server, ignore error
                notifyHost('loggedin');
            }
        } catch (error: any) {
            notifyHost(`error loadCurrentUserFromLocalStorage become failed => "${JSON.stringify(error)}"`);
        }
    } else if (user) { // try load current user from cache
        yield loadPubFromLocalStorage(user);
        if (!isEmbedded()) { // update user from server (on embedded this is done in initial 'become')
            yield loadAndSetUserPub(user, true);
        }
    } else {
        notifyHost(`error loadCurrentUserFromLocalStorage => no current parse user"`);
    }
    notifyHost(`ready loadCurrentUserFromLocalStorage user=${user?.id ?? "unknown"}`);
};

export const setSchoolSaga = (action: PayloadAction<ISchool>) => {
    localStorage.setItem('school', JSON.stringify(action.payload));
};

export const setCurrentUserSaga = function* (action: PayloadAction<{user: Parse.User; pub: IUserPub}>) {

    notifyHost(`setCurrentUser: user=${action.payload.user.id}`);

    localStorage.setItem('pub', JSON.stringify(action.payload.pub));

    const dpState: DataPointsState = yield select((state: RootState) => state.dataPoints);
    if (dpState.dataPoints.length === 0 && !dpState.isLoading) {
        yield put(loadDataPoints());
    }

    if (!isEmbedded()) {
        const solesState: SolesState = yield select((state: RootState) => state.soles);
        if (solesState.soles.length === 0 && !solesState.isLoading) {
            yield put(loadSoles());

        }
        yield put(loadTags());
    }
};

export const signUpSaga = function* (action: PayloadAction<{ email: string; password: string}>) {
    try {
        const user = new Parse.User();
        user.setUsername(action.payload.email);
        user.setEmail(action.payload.email);
        user.setPassword(action.payload.password);
        user.set('accountType', 'parse');

        yield user.signUp();
        // @ts-ignore
        const pub = yield loadUserPub();
        // forcing email into pub
        pub.email = action.payload.email;
        yield put(saveUser(pub));
        yield put(setCurrentUser(user, pub));
    } catch (error: any) {
        yield put(loginFailed(error.message));
    }
};

export const saveUserSaga = function* (action: PayloadAction<IUserPub>) {
    let current = Parse.User.current();
    if (current) {
        try {
            const sessionToken = current.getSessionToken();
            // @ts-ignore
            const pub = yield Parse.Cloud.run('userpub.save', {
                pubJson: action.payload
            }, {
                sessionToken: sessionToken
            });
            current = yield current.fetch({
                sessionToken: sessionToken
            });

            if (current) {
                yield put(setCurrentUser(current, pub));
            }
        } catch (error: any) {
            yield put(saveUserFailed(error.message));
        }
    }
};

export const saveSchoolSaga = function* (action: PayloadAction<string>) {
    // @ts-ignore
    let pub = yield select((state: RootState) => state.user.pub);
    const current = Parse.User.current();
    if (current && pub) {
        try {
            // @ts-ignore
            const schoolParse = yield Parse.Cloud.run("school.getByPlaceId", {
                placeId: action.payload
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
            // @ts-ignore
            const school = yield loadSchool(schoolParse.id);
            if (school) {
                // @ts-ignore
                pub = yield select((state: RootState) => state.user.pub);
                yield put(saveUser({...pub, schoolId: school.id}));
                yield put(setSchool(school));
            }
        } catch (error: any) {
            yield put(saveSchoolFailed(error.message));
        }
    }
};

export const resetUserSaga = function* () {

    notifyHost(`resetUserSaga`);
    yield Parse.User.logOut();

    // Clear local storage
    localStorage.removeItem('pub');
    localStorage.removeItem('soles');
    localStorage.removeItem('school');
    localStorage.clear();
};

export const loginWithTokenSaga = function* (action: PayloadAction<{ sessionToken: string; email: string }>) {
    notifyHost('start loginWithTokenSaga');
    yield put(loginPending());
    try {
        notifyHost('loginWithTokenSaga start become... token=${action.sessionToken}');
        // @ts-ignore
        const user = yield Parse.User.become(action.payload.sessionToken);
        notifyHost('loginWithTokenSaga ready become user=${user?.id ?? "unknown"}');

        // @ts-ignore
        const pub = yield loadUserPub();
        // if Google user doesn't have the email DPV written set, save email.
        if (!pub.email) {
            pub.email = action.payload.email;
            yield put(saveUser(pub));
        }
        yield put(setCurrentUser(user, pub));

        if (pub) {
            // @ts-ignore
            const school = yield loadSchool(pub.schoolId);
            yield put(setSchool(school));
        }

    } catch (error: any) {
        yield put(loginFailed(error.message));
    }
    notifyHost('ready loginWithTokenSaga');
};

export const loginSaga = function* (action: PayloadAction<{ email: string; password: string }>) {
    yield put(loginPending());
    try {
        // @ts-ignore
        const user = yield Parse.User.logIn(action.payload.email, action.payload.password);
        console.log(`user logged in: ${user.getUsername()}`);
        // @ts-ignore
        const pub = yield loadUserPub();
        yield put(setCurrentUser(user, pub));

        if (pub) {
            // @ts-ignore
            const school = yield loadSchool(pub.schoolId);
            yield put(setSchool(school));
        }

    } catch (error: any) {
        yield put(loginFailed(error.message));
    }
};

export const completeOnboardingSaga = function* (action: PayloadAction<IUserPub>) {
    // this is where we should fire the onboarding.done event for Parse I think
    try {
        yield put(saveUser(action.payload));
        yield Parse.Cloud.run('event.trigger', {
            rdn: 'onboarding.done',
            isActivity: false,
            objectId: action.payload.id,
            className: 'UserPub'
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
    } catch (error: any) {
        return undefined;
    }
};