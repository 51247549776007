import {put} from "redux-saga/effects";
import Parse from "parse";
import {Tag} from "../data/Tag";
import {loadTagsFailed, setGrades, setTags} from "../redux/tagsSlice";

export const loadTagsSaga = function* () {
    try {
        const tags: Tag[] = yield Parse.Cloud.run('tag.topTagsJson', {}, {sessionToken: Parse.User.current()?.getSessionToken()});
        yield put(setTags(tags));

        const grades: Tag[] = yield Parse.Cloud.run('webapp.getGrades', {
            subject: false
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });
        yield put(setGrades(grades));
    } catch (error: any) {
        yield put(loadTagsFailed(error.message));
    }
};
