export class DataPoint {
    rdn: string;
    type: string;
    rules: { [key: string]: any } = {};

    constructor(dpParse: Parse.Object) {
        const rulesString: string | undefined = dpParse.get('rules');
        if (rulesString) {
            this.rules = JSON.parse(rulesString);
        }

        this.rdn = dpParse.get('rdn');
        this.type = dpParse.get('type');
    }
}
