export class SoleText {
    rdn: string;
    language: string;
    label?: string;
    short?: string;
    long?: string;
    weight?: number;

    constructor(rdn: string, language: string, label?: string, short?: string, long?: string, weight?: number) {
        this.rdn = rdn;
        this.language = language;
        this.label = label;
        this.short = short;
        this.long = long;
        this.weight = weight;
    }

    static createFromParse(textParse: Parse.Object): SoleText {
        return new SoleText(
            textParse.get('rdn'),
            textParse.get('lng'),
            SoleText.unescape(textParse.get('label')),
            SoleText.unescape(textParse.get('short')),
            SoleText.unescape(textParse.get('long')),
            textParse.get('weight') ? Number(textParse.get('weight')) : undefined
        );
    }

    private static unescape(text: string): string {
        if (text) {
            text = text.replace(/\\&/g, '\u00a0');
        }
        return text;
    }
}
