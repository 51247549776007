import React from "react";
import {
    Avatar,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslationLabel} from "../../hooks/useTranslation";
import GetAppIcon from '@material-ui/icons/GetApp';

interface TrainerProps {
    trainerMaterials?: TrainerMaterial[];
}

export interface TrainerMaterial {
    name: string;
    id: string;
    type: 'document' | 'presentation';
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    avatar: {
        width: theme.spacing(16),
        height: theme.spacing(16),
        margin: "auto",
        backgroundColor: "#fff3e0"
    },
    table: {
        minWidth: 150
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        margin: 2
    },
    flex: {
        display: "flex"
    }
}));

const createData = (name: string, id: string, type: 'document' | 'presentation'): TrainerMaterial => ({name, id, type});

const baseMaterials: TrainerMaterial[] = [
    createData('SOLE 101 Training Manual', '1G2ErNUcRkyHsFP6srtEqlT6q9CnyMNegDOR2OKa3chI', 'document'),
    createData('StartSOLE 101 Presentation', '1ou5fV2BjTT69-H_rQPimMWGcZS1ucZOvh1drzjGqNP4', 'presentation'),
    createData('StartSOLE 101 Presentation (1 hour version)', '1nby61PWTdZjsP9caLB5M8rLyT5NMTYzoI5V0fJHth3w', 'presentation'),
    createData('Sample Advertising Flyer', '1A5Sn_C8bRzOdrUVPFhe0kFiWpVsKIy1PHbKJavHQNg4', 'document'),
    createData('Using the SOLE website', '1-7-k4hJSiULwdUsWtAVXAWBBG9Dea3K1Ss9RdZT9OIg', 'presentation'),
    createData('Intro to StartSOLE Virtual', '1d6gxUF-k-SNBT4Q8OHJ6g8UV_acpoxXNXWFU-DRp6DY', 'presentation'),
    createData('Using the SOLE mobile app', '1SgoE_5jEkiK7ev_eHiWYLNQPjmU4NdI5PwvJvyTzInA', 'presentation')
];

const TrainerMaterials: React.FC<TrainerProps> = ({trainerMaterials}) => {
    const getLabel = useTranslationLabel();
    const classes = useStyles();
    const materials = baseMaterials.concat(trainerMaterials ?? []);

    return (<div className={classes.root}>
        <Avatar alt="Certificate Image" src="/images/profile/trainer-avatar.svg" className={classes.avatar}/>
        <Typography variant="body1" gutterBottom align="center">
            {getLabel('ui.profile.trainer_explanation')}
        </Typography>

        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableBody>
                    {materials.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell>
                                <Link href={`https://docs.google.com/${row.type}/d/${row.id}/edit?usp=sharing`}
                                      target="_blank">
                                    {row.name}
                                </Link>
                            </TableCell>
                            <TableCell align="right">
                                <div className={classes.flex}>
                                    <Link href={`https://docs.google.com/${row.type}/d/${row.id}/edit?usp=sharing`}
                                          target="_blank">
                                        <Avatar className={classes.small} variant="square"
                                                src="/images/profile/gdoc.svg"/>
                                    </Link>
                                    <Link href={`https://docs.google.com/${row.type}/d/${row.id}/export/pdf`}
                                          target="_blank">
                                        <Avatar className={classes.small} variant="square"
                                                src="/images/profile/pdf.svg"/>
                                    </Link>
                                    {row.type === 'document' ?
                                        <Link href={`https://docs.google.com/${row.type}/d/${row.id}/export?format=doc`}
                                              target="_blank">
                                            <Avatar className={classes.small} variant="square"
                                                    src="/images/profile/doc.svg"/>
                                        </Link>
                                        :
                                        <Link href={`https://docs.google.com/${row.type}/d/${row.id}/export/pptx`}
                                              target="_blank">
                                            <Avatar className={classes.small} variant="square"
                                                    src="/images/profile/ppt.svg"/>
                                        </Link>
                                    }
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell>
                            <Link href="/images/logos/logo-email-signature-trainer.png" download>
                                {getLabel('ui.profile.trainer_download_email_icon')}
                            </Link>
                        </TableCell>
                        <TableCell>
                            <Link href="/images/logos/logo-email-signature-trainer.png" download>
                                <GetAppIcon/>
                            </Link>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </div>);
};

export default TrainerMaterials;
