import React, {useState} from "react";
import {AboutMe, AboutMeState, createAboutMeState} from "./ProfileAboutMeComponent";
import LocationAutocomplete, {PlaceType} from "./LocationAutocompleteComponent";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import SaveIcon from "@material-ui/icons/Save";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {useAppDispatch, useAppSelector} from "../../redux/reduxHooks";
import {IUserPub} from "../../shared/soleTypes";
import Link from "@material-ui/core/Link";
import {completeOnboarding, saveSchool, selectPub} from "../../redux/userSlice";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    paper: {
        height: 140,
        width: 100
    },
    control: {
        padding: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    },
    marginAutoItem: {
        margin: 'auto'
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%"
    }
}));

const ProfileComplete: React.FC = () => {
    const pub = useAppSelector(selectPub);
    const [state, setState] = useState<AboutMeState>(createAboutMeState(pub));
    const [place, setPlace] = useState<PlaceType | null>(null);
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const [open, setOpen] = React.useState(false);

    const save = () => {
        if (pub) {
            const newPub: IUserPub = {
                ...pub,
                firstName: state.firstName,
                lastName: state.lastName,
                role: state.role,
                grades: state.grades,
                subjects: state.subjects
            };
            dispatch(completeOnboarding(newPub));
        }
        if (place) {
            dispatch(saveSchool(place.place_id));
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (<div id="complete-registration">
        <Grid container justifyContent="center" className={classes.root}>
            <Grid item xs={12} sm={10} md={6}>
                <Typography variant="h5" align="center">
                    {getLabel('ui.profile.complete_header')}
                </Typography>

                <Typography variant="body1" align="center">
                    {getLabel('ui.profile.need_more_info')}
                </Typography>
                <Box display="flex">
                    <Box m="auto">
                        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                            {getLabel('ui.learn_more')}
                        </Button>
                    </Box>
                </Box>
                <AboutMe callback={(newState: AboutMeState) => { setState(newState); }}/>
                <FormControl className={classes.formControl}>
                    <LocationAutocomplete onChange={(place: PlaceType | null) => {
                        setPlace(place);
                    }}/>
                </FormControl>
                <Box display="flex">
                    <Box m="auto">
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={clsx(classes.button, classes.marginAutoItem)}
                            onClick={() => save()}
                            startIcon={<SaveIcon/>}
                        >
                            {getLabel('ui.save')}
                        </Button>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box m="auto">
                        <Link href="/logout" variant="body2">
                            Logout
                        </Link>
                    </Box>
                </Box>
            </Grid>
        </Grid>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{getLabel('ui.profile.privacy_header')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {getLabel('ui.profile.privacy_body')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" href="https://startsole.org/privacy/" target="_blank">
                    {getLabel('ui.profile.privacy_button')}
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus>
                    {getLabel('ui.close')}
                </Button>
            </DialogActions>
        </Dialog>
    </div>)
};

export default ProfileComplete;
