import React, {useMemo} from "react";
import {Grid} from "@material-ui/core";

import SoleSelectionComponent from "../primitives/SoleSelectionComponent";
import {HidableComponent} from "../../hoc/Hidable";
import {SelectionConstraints} from "../../../data/constraints/Constraints";
import {useAppSelector} from "../../../redux/reduxHooks";
import {selectConstraints} from "../../../redux/constraintSlice";
import {selectPub} from "../../../redux/userSlice";
import {Props, ValidatePart} from "./00_PartProps";

const DEMO_USER_ID = "nOGJINYZDB"; // show/hide template.plan

export const validateTemplatePart:ValidatePart = (_props) => {
    return null;
};

export const TemplatePart: React.FC<Props> = ({props}) => {
    const constraints = useAppSelector(selectConstraints);
    const pub = useAppSelector(selectPub);

    const [templateConstraint] = useMemo(() => {
        const templateConstraint = new SelectionConstraints();
        templateConstraint.items = [
            {rdn: 'ui.plan_type.simple'},
            {rdn: 'ui.plan_type.default'}
        ];
        return [templateConstraint];
    }, [constraints]);

    return (
        <HidableComponent isVisible={pub?.id === DEMO_USER_ID}>
            <Grid item {...props.gridSizes}>
                <SoleSelectionComponent
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="templatePlan"
                    rdn="session.template.plan"
                    constraint={templateConstraint}
                />
            </Grid>
        </HidableComponent>
    );
};

