import React from "react";
import {Card, CardActionArea, CardContent, CardMedia, createStyles, makeStyles, Typography} from "@material-ui/core";
import Modal from "@material-ui/core/Modal/Modal";

const getModalStyle = () => ({
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
});

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            outline: 0,
            width: "min(100vw, 560px)",
            height: "min(80vh, 315px)"
        },
        card: {
            maxWidth: 345
        },
        media: {
            height: 200
        }
    })
);

interface VideoProps {
    url: string;
    title: string;
    image: string;
    description: string;
    autoplay?: string;
}

const VideoModal: React.FC<VideoProps> = (props) => {
    const classes = useStyles();
    const autoplay = props.autoplay ?? "1";

    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <iframe width="100%"
                    height="100%"
                    title="video-iframe"
                    src={`${props.url}?autoplay=${autoplay}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
        </div>
    );

    return (<div>
        <Card onClick={handleOpen} className={classes.card}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={props.image}
                    title={props.title}
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {props.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            {/* <CardActions>*/}
            {/* <Button size="small" color="primary">*/}
            {/* Play Video*/}
            {/* </Button>*/}
            {/* </CardActions>*/}
        </Card>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby={props.title}
            aria-describedby={props.description}
        >
            {body}
        </Modal>
    </div>);
};

export default VideoModal;
