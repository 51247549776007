import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Redirect} from "react-router-dom";
import Copyright from './CopyrightComponent';
import Loading from "./LoadingComponent";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import Parse from "parse";
import GoogleLogin from "react-google-login";
import {Alert} from "@material-ui/lab";
import {useTranslationLabel} from "../hooks/useTranslation";
import {useAppDispatch, useAppSelector} from "../redux/reduxHooks";
import {login, loginWithToken} from "../redux/userSlice";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    error: {
        color: 'red',
        marginBottom: theme.spacing(4)
    }
}));

const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const getLabel = useTranslationLabel();
    const classes = useStyles();

    const parsed = queryString.parse(location.search);
    const [showResetDialog, setShowResetDialog] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const isLoading = useAppSelector((state) => state.user.isLoading);
    const isLoadingText = useAppSelector((state) => state.text.isLoading);
    const errorMessage = useAppSelector((state) => state.user.errorMessage);
//    const user = Parse.User.current();
    const user = useAppSelector((state) => state.user.user);
    const [wasReset, setWasReset] = useState(false);
    const [googleError, setGoogleError] = useState('');

    const handleResetPassword = async () => {
        if (email) {
            setShowResetDialog(false);
            try {
                const result = await Parse.Cloud.run("webapp.resetPassword", {
                    email: email
                });
                if (result) {
                    setWasReset(true);
                }
                console.log(result);
                // Todo: show result message in a snackbar
            } catch (error: any) {
                // Todo: show error.message in a snackbar
            }
        }
    };

    const responseGoogle = (response: any) => {
        // TODO: @Frans we should have a test for this
        // const googleToken = response.getAuthResponse(true).access_token;
        const googleToken = response.accessToken;
        if (googleToken) {
            (async () => {
                const sessionToken = await Parse.Cloud.run('loginGoogleUser', {
                    token: googleToken
                });
                dispatch(loginWithToken(sessionToken, response.profileObj.email));
            })();
        } else {
            console.log(`Unable to log into Google.  Here is the response data: ${JSON.stringify(response)}`);
            if (response.error) {
                setGoogleError(response.details);
            }
        }
    };

    const sanitizeErrorMessage = (msg: string) => {
        let message = msg.match(/"((?:\\.|[^"\\])*)"/)?.slice(-1)[0];
        if (message) {
            message = message.replace("the Parse API", "StartSOLE");
        }
        if (!message) {
            message = msg;
        }
        return message;
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        dispatch(login(email.toLowerCase(), password));
    };

    if (isLoading) {
        return <Loading rdn="ui.login.logging_in"/>
    } else if (isLoadingText) {
        return <Loading/>
    } else if (user) {
        return <Redirect to="/home"/>
    } else {
        return (<Container component="main" maxWidth="xs">
            <div className={classes.root}>
                {parsed.ring === "colombia" ? <img src="/images/logos/colombia-logo.png" alt="logo"/> : <img src="/images/logos/animated-logo-no-repeat.gif" alt="logo"/>}
                <Typography component="h1" variant="h5">
                    {getLabel('ui.login.sign_in')}
                </Typography>
                <Box m={3}>
                    {googleError ?
                        <Alert
                            severity="error">
                            {getLabel('ui.login.google_unavailable')} - {googleError}
                        </Alert>
                        : <div/>
                    }
                    {errorMessage ?
                        <Alert
                            severity="error">
                            {sanitizeErrorMessage(errorMessage)}
                        </Alert>
                        : <div/>
                    }
                </Box>
                {/* <GoogleLogin*/}
                {/*    clientId="824307770319-v35co4sbfui42q2a93hcccvk68hfpam7.apps.googleusercontent.com"*/}
                {/*    onSuccess={responseGoogle}*/}
                {/*    onFailure={responseGoogle}*/}
                {/*    responseType={'token'}*/}
                {/*    cookiePolicy={'single_host_origin'}*/}
                {/*    theme='dark'*/}
                {/* />*/}
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={getLabel('ui.profile.email')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={getLabel('ui.profile.password')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {getLabel('ui.login.sign_in')}
                    </Button>
                </form>
                <Grid container justifyContent="center">
                    <Typography variant="h6" gutterBottom>
                        {getLabel('ui.login.or')}
                    </Typography>
                </Grid>
                <Box mb={5}>
                    <GoogleLogin
                        clientId="824307770319-v35co4sbfui42q2a93hcccvk68hfpam7.apps.googleusercontent.com"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        responseType="token"
                        cookiePolicy="single_host_origin"
                        theme="dark"
                    />
                </Box>

                <Grid container>
                    <Grid item xs>
                        <Link onClick={() => setShowResetDialog(true) } variant="body2">
                            {getLabel('ui.login.forgot_password')}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="/register" variant="body2">
                            {getLabel('ui.login.no_account')}
                        </Link>
                    </Grid>
                </Grid>
            </div>

            <Dialog open={showResetDialog} onClose={() => setShowResetDialog(false)}>
                <DialogTitle id="reset-title">{getLabel('ui.login.reset_password')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {getLabel('ui.login.reset_password_confirmation')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={getLabel('ui.profile.email')}
                        type="email"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowResetDialog(false)} color="primary">
                        {getLabel('ui.cancel')}
                    </Button>
                    <Button onClick={handleResetPassword} color="primary">
                        {getLabel('ui.login.reset_password_cancel')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Box mt={3}>
                <Copyright/>
            </Box>

            {wasReset ?
                <Alert
                    severity="success">
                    {getLabel('ui.login.reset_password_success')}
                </Alert>
                : <div/>
            }
        </Container>);
    }
};

export default Login;
