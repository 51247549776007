import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from '@material-ui/core/Button';
import {Link, Link as RouterLink} from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ListItemText from "@material-ui/core/ListItemText";
import ListIcon from "@material-ui/icons/List";
import Divider from "@material-ui/core/Divider";
import HelpIcon from "@material-ui/icons/Help";
import ForumIcon from "@material-ui/icons/Forum";
import EditIcon from "@material-ui/icons/Edit";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import HistoryIcon from "@material-ui/icons/History";
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from "@material-ui/icons/Assignment";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Box from '@material-ui/core/Box';
import {useTranslationLabel} from "../hooks/useTranslation";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getRingProps} from "../theme/RingProps";
import {useAppSelector} from "../redux/reduxHooks";
import {selectPub} from "../redux/userSlice";
import {HidableComponent} from "./hoc/Hidable";

const useStyles = makeStyles((theme) => ({
    appIcon: {
        width: '100px'
    }
}));

export const SidebarMenuList: React.FC = () => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const pub = useAppSelector(selectPub)!;
    const ringProps = getRingProps(pub);
    const showAdmin = (pub.leaderRings && pub.leaderRings.length > 0);

    return (<List>
        <Box textAlign="center" mx={2} mb={1}>
            <Button fullWidth component={RouterLink} to="/soles/plan"
                    variant="contained" color="primary" size="large">
                {getLabel('ui.plan_a_lesson')}
            </Button>
        </Box>
        <Box textAlign="center" mx={2} mb={1}>
            <Button fullWidth component={RouterLink} to="/soles/virtual"
                    variant="contained" color="secondary" size="large">
                {getLabel('ui.virtual')}
            </Button>
        </Box>
        <HidableComponent isVisible={pub.roles?.includes('tester_search') ?? false}>
            <Box textAlign="center" mx={2} mb={2}>
                <Button fullWidth component={RouterLink} to="/search-welcome"
                        variant="contained" color="default" size="large">
                    {getLabel('ui.search')}
                </Button>
            </Box>
        </HidableComponent>
        <Divider/>
        <ListItem component={RouterLink} to="/profile" button>
            <ListItemIcon><AccountCircleIcon/></ListItemIcon>
            <ListItemText primary={getLabel('ui.my_profile')}/>
        </ListItem>
        <ListItem component={RouterLink} to="/soles" button>
            <ListItemIcon><ListIcon/></ListItemIcon>
            <ListItemText primary={getLabel('ui.my_soles')}/>
        </ListItem>
        <Divider/>
        <ListItem component={RouterLink} to="/questions/search" button>
            <ListItemIcon><HelpIcon/></ListItemIcon>
            <ListItemText primary={getLabel('ui.explore_questions')}/>
        </ListItem>
        <ListItem component={RouterLink} to="/questions/mine" button>
            <ListItemIcon><ForumIcon/></ListItemIcon>
            <ListItemText primary={getLabel('ui.my_questions')}/>
        </ListItem>
        <ListItem component={RouterLink} to="/questions/new" button>
            <ListItemIcon><EditIcon/></ListItemIcon>
            <ListItemText primary={getLabel('ui.add_a_question')}/>
        </ListItem>
        <Divider/>
        {ringProps.externalHowTo ?
            <ListItem button component="a" href={ringProps.externalHowTo} target="_blank">
                <ListItemIcon><RecordVoiceOverIcon/></ListItemIcon>
                <ListItemText primary={getLabel('ui.how_to_sole')}/>
            </ListItem> :
            <ListItem component={RouterLink} to="/how" button>
                <ListItemIcon><RecordVoiceOverIcon/></ListItemIcon>
                <ListItemText primary={getLabel('ui.how_to_sole')}/>
            </ListItem>}
        {ringProps.isVisibleHistoryOfStartSole ?
            <ListItem component={RouterLink} to="/history" button>
                <ListItemIcon><HistoryIcon/></ListItemIcon>
                <ListItemText primary={getLabel('ui.history_of_startsole')}/>
            </ListItem>
            : <div/>}
        <ListItem component={RouterLink} to="/resources" button>
            <ListItemIcon><AssignmentIcon/></ListItemIcon>
            <ListItemText primary={getLabel('ui.teacher_resources')}/>
        </ListItem>
        {ringProps.customRingPage ?
            <ListItem component={RouterLink} to={`/community/${ringProps.customRingPage}`} button>
                <ListItemIcon>
                    <PeopleIcon/>
                </ListItemIcon>
                <ListItemText primary={getLabel('ui.sole_and_udl')}/>
            </ListItem>
            : <div/>}
        {showAdmin ? <ListItem component={RouterLink} to="/admin" button>
            <ListItemIcon><SupervisorAccountIcon/></ListItemIcon>
            <ListItemText primary={getLabel('ui.admin')}/>
        </ListItem> : <div/>}
        <Divider/>

        <Box textAlign="center" mt={2}>
            <a href="https://itunes.apple.com/us/app/startsole-self-organized-learning-environment/id1097411683?mt=8">
                <img alt="iOS App" className={classes.appIcon} src="/images/apps/ios.png"/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.startsole.mobileapp&hl=en">
                <img alt="Android App" className={classes.appIcon} src="/images/apps/android.png"/>
            </a>
        </Box>
    </List>);
};
