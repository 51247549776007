import React, {memo} from "react";
import Grid from "@material-ui/core/Grid";
import {Zoom} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import {IQuestion} from "@startsole/startsole-types/src/solejson";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {StandardPickerState} from "../standards/StandardPickerComponent";
import QuestionCard from "./QuestionCardComponent";
import {useTranslationLabel} from "../../hooks/useTranslation";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.main,
            breakInside: 'avoid',
            pageBreakInside: 'avoid',
            webkitColumnBreakInside: 'avoid',
            marginBottom: '12px'
        },
        media: {
            height: '100px'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary
        },
        cols: {
            webkitColumns: '250px',
            columns: '250px',
            webkitColumnGap: '2em',
            columnGap: '2em'
        },
        questionCard: {
            marginBottom: '12px',
            breakInside: 'avoid',
            pageBreakInside: 'avoid',
            webkitColumnBreakInside: 'avoid'
        }
    })
);

interface QuestionsProps {
    questions: IQuestion[];
    pickerState?: StandardPickerState;
}

const QuestionCards: React.FC<QuestionsProps> =
    ({
         questions, pickerState
     }) => {
        const classes = useStyles();
        const getLabel = useTranslationLabel();

        if (questions.length === 0) {
            const link = "/questions/new";

            return (<Grid key="newquestion" item xs={12} sm={6} md={3}>
                <Zoom in={true} style={{transitionDelay: '0ms'}}>
                    <Link component={RouterLink} to={{
                        pathname: link,
                        state: {
                            pickerState: pickerState
                        }
                    }}>
                        <Paper elevation={2} className={classes.paper}>{getLabel('ui.questions.nothing_here')}</Paper>
                    </Link>
                </Zoom>
            </Grid>);
        }

        return (<div>
            <div id="questioncard-container" className={classes.cols}>
                {questions.map((question, index) => {
                    return <QuestionCard key={`question-card-${index}`} question={question} pickerState={pickerState}/>;
                })}
            </div>
        </div>);
    };

export const MemoizedQuestionCards = memo(QuestionCards, (prev, next) => {
    return JSON.stringify(prev.questions) === JSON.stringify(next.questions) &&
        JSON.stringify(prev.pickerState) === JSON.stringify(prev.pickerState);
});

export default QuestionCards;
