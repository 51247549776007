import {ISole} from "../shared/soleTypes";
import {useState} from "react";
import Parse from "parse";
import {addSole, selectSoles, setSoles} from "../redux/solesSlice";
import {notifyHost} from "../embedded";
import {useAppDispatch, useAppSelector} from "../redux/reduxHooks";

const useSaveSole = (): [(sole: ISole) => void, ISole | undefined, boolean, string | undefined] => {
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [savedSole, setSavedSole] = useState<ISole | undefined>(undefined);
    const soles = useAppSelector(selectSoles);

    const save = async (sole: ISole) => {
        setIsSaving(true);
        try {
            const newSole = await Parse.Cloud.run('webapp.saveSole', {
                sole: sole
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });

            const jsonSole = await Parse.Cloud.run('webapp.getSoleJsonById', {
                id: newSole.id
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });

            dispatch(addSole(newSole.id));

            const newSoles = [...soles];
            const index = newSoles.findIndex((s) => s.id === sole.id);
            if (index >= 0) {
                newSoles[index] = jsonSole;
            } else {
                newSoles.unshift(jsonSole);
            }
            dispatch(setSoles(newSoles));

            setIsSaving(false);
            setSavedSole(newSole);

            notifyHost('saved')

        } catch (error:any) {
            setIsSaving(false);
            setErrorMessage(error.message);
        }

    };
    return [save, savedSole, isSaving, errorMessage];
};

export default useSaveSole;
