import React, {useEffect, useState} from "react";
import Parse from "parse";
import {
    Box,
    Button,
    createStyles,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    Snackbar,
    TextField,
    Typography
} from "@material-ui/core";
import {SelectionConstraints} from "../../data/constraints/Constraints";
import SelectionComponent from "../input/SelectionComponent";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {useTranslationLabel} from "../../hooks/useTranslation";
import CopyToClipboard from "react-copy-to-clipboard";

interface BbbSettings {
    guestPolicy: 'ALWAYS_ACCEPT' | 'ASK_MODERATOR';
    lockSettingsDisablePrivateChat: boolean;
    lockSettingsDisableNote: boolean;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            textAlign: 'center',
            '& .MuiTextField-root': {
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(1),
                maxWidth: 420,
                width: '100%'
            }
        },
        snackbar: {
            "& .MuiSnackbarContent-message": {
                margin: 'auto',
                textAlign: 'center'
            }
        }
    })
);

const getInitialBbbSettings = (): BbbSettings => {
    const json = localStorage.getItem('bbb-settings') as string;
    let settings: BbbSettings = {
        guestPolicy: 'ALWAYS_ACCEPT',
        lockSettingsDisablePrivateChat: false,
        lockSettingsDisableNote: false
    };
    if (json) {
        try {
            settings = JSON.parse(json);
        } catch (error: any) {
            // ignore error
        }
    }
    return settings;
};

const getInitialSelectionValues = (settings: BbbSettings): string[] => {
    const initialValues = [];
    if (settings.guestPolicy === 'ASK_MODERATOR') {
        initialValues.push('guestPolicy');
    }
    if (settings.lockSettingsDisablePrivateChat) {
        initialValues.push('lockSettingsDisablePrivateChat')
    }
    if (settings.lockSettingsDisableNote) {
        initialValues.push('lockSettingsDisableNote')
    }
    return initialValues;
};

const saveBbbSettings = (settings: BbbSettings) => {
    localStorage.setItem('bbb-settings', JSON.stringify(settings));
};

interface VirtualSoleProps {
    soleId: string;
}

const VirtualSole: React.FC<VirtualSoleProps> = (props) => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
    const [participantShareUrl, setParticipantShareUrl] = useState<string>('');
    const [settings, setSettings] = useState<BbbSettings>(getInitialBbbSettings());

    useEffect(() => {
        if (props.soleId) {
            (async () => {
                const url = await Parse.Cloud.run('bbbcontroller.getParticipantShareUrl', {
                    soleId: props.soleId
                }, {
                    sessionToken: Parse.User.current()?.getSessionToken()
                });
                setParticipantShareUrl(url);
            })();
        }
    }, [props.soleId]);

    const showBbb = async () => {
        const url = await Parse.Cloud.run('bbbcontroller.getFacilitatorUrlWithOptions', {
            soleId: props.soleId,
            options: settings
        }, {
            sessionToken: Parse.User.current()?.getSessionToken()
        });

        if (url) {
            window.location.href = url;
        }
    };

    // Settings constraint
    const settingsConstraint = new SelectionConstraints();
    settingsConstraint.items = [
        {rdn: 'ui.virtual.constraint.guestPolicy'},
        {rdn: 'ui.virtual.constraint.lockSettingsDisablePrivateChat'},
        {rdn: 'ui.virtual.constraint.lockSettingsDisableNote'}
    ];

    const onChangeSettings = (value: string | string[] | undefined) => {
        if (Array.isArray(value)) {
            const values = value as string[];
            const settings: BbbSettings = {
                guestPolicy: values.includes('guestPolicy') ? 'ASK_MODERATOR' : 'ALWAYS_ACCEPT',
                lockSettingsDisablePrivateChat: values.includes('lockSettingsDisablePrivateChat'),
                lockSettingsDisableNote: values.includes('lockSettingsDisableNote')
            };
            setSettings(settings);
            saveBbbSettings(settings);
        }
    };

    return (<Grid container alignItems="stretch" direction="column">
        <Grid item sm={12}>
            <Typography variant="h4" align="center" gutterBottom>
                {getLabel('ui.virtual.ready_to_go')}
            </Typography>
            <Typography variant="body1" align="center">
                {getLabel('ui.virtual.copy_link')}
            </Typography>
        </Grid>
        <Grid item sm={12}>
            <form className={classes.root} noValidate autoComplete="off">
                <TextField
                    id="outlined-read-only-input"
                    value={participantShareUrl}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <CopyToClipboard
                                    text={participantShareUrl ?? ''}
                                    onCopy={() => setIsCopiedToClipboard(true)}
                                >
                                    <IconButton aria-label="copy">
                                        <FileCopyIcon/>
                                    </IconButton>
                                </CopyToClipboard>
                            </InputAdornment>
                        )
                    }}
                    inputProps={{
                        style: {
                            textAlign: 'center'
                        }
                    }}
                    fullWidth
                    variant="outlined"
                />
            </form>
            <Box display="flex" mb={5}>
                <Box m="auto">
                    <Button variant="contained"
                            href={`https://classroom.google.com/share?url=${participantShareUrl}&title=`}
                            target="_blank">
                        {getLabel('ui.virtual.share_to_classroom')}
                    </Button>
                </Box>
            </Box>
        </Grid>
        <Grid item sm={12}>
            <Typography variant="body1" align="center">
                {getLabel('ui.virtual.click_the_button_explanation')}
            </Typography>
            <Box display="flex" mt={1} mb={5}>
                <Box m="auto">
                    <Button variant="contained" color="secondary" onClick={() => showBbb()}>
                        {getLabel('ui.virtual.get_started')}
                    </Button>
                </Box>
            </Box>
            <Box display="flex" mt={5} mb={5}>
                <Box m="auto">
                    <SelectionComponent
                        rdn="settings"
                        initialValue={getInitialSelectionValues(settings)}
                        onChange={onChangeSettings}
                        constraint={settingsConstraint}
                        isExpanded={true}/>
                </Box>
            </Box>
        </Grid>
        <Snackbar
            open={isCopiedToClipboard}
            message={getLabel('ui.copied_to_clipboard')}
            onClose={() => {
                setIsCopiedToClipboard(false)
            }}
            autoHideDuration={300000}
            className={classes.snackbar}
        />
    </Grid>);
};

export default VirtualSole;
