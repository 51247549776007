import {Constraint, getConstraintClassName} from "../data/constraints/Constraints";
import {PayloadAction} from "@reduxjs/toolkit";

export const setConstraintsSaga = function(action: PayloadAction<Map<string, Constraint>>) {
    const arr = Array.from(action.payload.entries());
    for (const e of arr) {
        // @ts-ignore
        e[1]['tsclass'] = getConstraintClassName(e[1]);
    }
    const json = JSON.stringify(arr);
    localStorage.setItem('constraints', json);
};
