import {IReport} from "../shared/soleTypes";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ReportState {
    isLoading: boolean;
    errorMessage?: string;
    report?: IReport;
}

const initialState: ReportState = {
    isLoading: false,
    errorMessage: undefined,
    report: undefined
};

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        loadReport: {
            reducer: (state, action: PayloadAction<{name: string; ring: string; parms: {}}>) => {
                state.isLoading = true;
                state.errorMessage = undefined;
            },
            prepare: (name: string, ring: string, parms: {}) => {
                return {payload: {name: name, ring: ring, parms: {}}}
            }
        },
        setReport: (state, action: PayloadAction<IReport>) => {
            state.report = action.payload;
        },
        loadReportFinished: (state) => {
            state.isLoading = false;
            state.errorMessage = undefined;
        },
        loadReportFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        }
    }
});

export const {loadReport, setReport, loadReportFailed, loadReportFinished} = reportSlice.actions;

export default reportSlice.reducer;
