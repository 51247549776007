import {IUserPub} from "../shared/soleTypes";
import React from "react";
import {teal} from "@material-ui/core/colors";
import {Slide} from "material-auto-rotating-carousel";
import {TrainerMaterial} from "../components/profile/ProfileTrainerComponent";

export interface IRingProps {
    logoUrl: string;
    isVisibleHistoryOfStartSole: boolean;
    language: string;
    resourceTags?: IResourceTag[];
    customRingPage?: string;
    customSlides?: JSX.Element[];
    trainerMaterials?: TrainerMaterial[];
    externalHowTo?: string;
}

export interface IResourceTag {
    key: string;
    text: string;
}

const defaultRingProps: IRingProps = {
    logoUrl: "url('/images/logos/logo-fallback.png')",
    isVisibleHistoryOfStartSole: false,
    language: 'en_US'
};

const usPaRingProps: IRingProps = {
    ...defaultRingProps,
    resourceTags: [
        {key: 'pa-udl-removing-engagement-barriers', text:'Removing Engagement Barriers'},
        {key: 'pa-udl-removing-representation-barriers', text:'Removing Representation Barriers'},
        {key: 'pa-udl-removing-action-and-expression-barriers', text:'Removing Action and Expression Barriers'},
        {key: 'pa-udl-provide-options-for-recruiting-interest', text:'Provide options for recruiting interest'},
        {key: 'pa-udl-provide-options-for-sustaining-effort-and-persistence', text:'Provide options for sustaining effort and persistence'},
        {key: 'pa-udl-provide-options-for-self-regulation', text:'Provide options for self-regulation'},
        {key: 'pa-udl-provide-options-for-perception', text:'Provide options for perception'},
        {key: 'pa-udl-provide-options-for-language,-mathematic,-expressions,-and-symbols', text:'Provide options for language, mathematic, expressions, and symbols'},
        {key: 'pa-udl-provide-options-for-comprehension', text:'Provide options for comprehension'},
        {key: 'pa-udl-provide-options-for-physical-action', text:'Provide options for physical action'},
        {key: 'pa-udl-provide-options-for-expression-and-communication', text:'Provide options for expression and communication'},
        {key: 'pa-udl-provide-options-for-executive-functioning', text:'Provide options for executive functioning'}
    ],
    trainerMaterials: [{
        id: "1K4qrlldsXlHOngbb_2klVfOrJv6PEd3ynwTXsyf_H-4",
        type: "document",
        name: "PA Work Readiness, SEL, and inquiry"
    }]
};

const usOhRingProps: IRingProps = {
    ...defaultRingProps,
    resourceTags: [
        {key: 'pa-udl-removing-engagement-barriers', text:'Removing Engagement Barriers'},
        {key: 'pa-udl-removing-representation-barriers', text:'Removing Representation Barriers'},
        {key: 'pa-udl-removing-action-and-expression-barriers', text:'Removing Action and Expression Barriers'},
        {key: 'pa-udl-provide-options-for-recruiting-interest', text:'Provide options for recruiting interest'},
        {key: 'pa-udl-provide-options-for-sustaining-effort-and-persistence', text:'Provide options for sustaining effort and persistence'},
        {key: 'pa-udl-provide-options-for-self-regulation', text:'Provide options for self-regulation'},
        {key: 'pa-udl-provide-options-for-perception', text:'Provide options for perception'},
        {key: 'pa-udl-provide-options-for-language,-mathematic,-expressions,-and-symbols', text:'Provide options for language, mathematic, expressions, and symbols'},
        {key: 'pa-udl-provide-options-for-comprehension', text:'Provide options for comprehension'},
        {key: 'pa-udl-provide-options-for-physical-action', text:'Provide options for physical action'},
        {key: 'pa-udl-provide-options-for-expression-and-communication', text:'Provide options for expression and communication'},
        {key: 'pa-udl-provide-options-for-executive-functioning', text:'Provide options for executive functioning'}
    ],
    trainerMaterials: [{
        id: "18c9YjeUULGs63CickOkevmOfoa6_60SlWQsEI6kYIxE",
        type: "document",
        name: "OH Work Readiness, SEL, and inquiry"
    }]
};

const coRingProps: IRingProps = {
    ...defaultRingProps,
    logoUrl: "url('/images/logos/sole-colombia-logo.png')",
    language: 'es_CO',
    externalHowTo: 'https://www.solecolombia.org/galeria-solea'
};

const lakeErieWestRingProps: IRingProps = {
    ...usOhRingProps,
    // logoUrl: "url('/images/logos/lake-erie-west-logo.png')",
    customRingPage: 'us.oh.lakeeriewest',
    customSlides: [<Slide
        key="lake-erie-slide"
        media={
            <img src="/images/carousel/custom-lake-erie-1.png" alt=""/>
        }
        mediaBackgroundStyle={{backgroundColor: teal[50]}}
        style={{backgroundColor: teal[600]}}
        title="StartSOLE + UDL"
        subtitle="We've partnered with Lake Erie West, your local Educational Service Center to make earning CEUs even easier.  Check out your StartSOLE + UDL page to learn more!"
        landscape
    />]
};

const iu8RingProps: IRingProps = {
    ...usPaRingProps,
    // logoUrl: "url('/images/logos/lake-erie-west-logo.png')",
    customRingPage: 'us.pa.iu8',
    customSlides: [<Slide
        key="iu-8-slide"
        media={
            <img src="/images/carousel/custom-iu8-1.png" alt=""/>
        }
        mediaBackgroundStyle={{backgroundColor: teal[50]}}
        style={{backgroundColor: teal[600]}}
        title="StartSOLE + UDL"
        subtitle="We've partnered with IU 8, your local Intermediate Unit to make earning ACT 48 even easier with UDL Trainings.  Check out your StartSOLE + UDL page to learn more!"
        landscape
    />]
};

// TODO: make an Italy ring and use ringProps for language
// const itRingProps: IRingProps = {
//     ...defaultRingProps,
//     language: 'it_IT'
// };

// always include more specfic rings above their generic parents
export const getRingProps = (pub: IUserPub): IRingProps => {
    const rings = pub.rings;
    if (rings?.find((ring) => ring.rdn === 'us.oh.lakeeriewest')) {
        return lakeErieWestRingProps;
    } else if (rings?.find((ring) => ring.rdn === 'us.pa.iu8')) {
        return iu8RingProps;
    } else if (rings?.find((ring) => ring.rdn === 'us.oh')) {
        return usOhRingProps;
    } else if (rings?.find((ring) => ring.rdn === 'us.pa')) {
        return usPaRingProps;
    } else if (rings?.find((ring) => ring.rdn === 'co')) {
        return coRingProps;
    } else {
        return defaultRingProps;
    }
};
