import React, {useMemo} from "react";
import {Grid} from "@material-ui/core";

import SoleSelectionComponent from "../primitives/SoleSelectionComponent";
import {SelectionConstraints} from "../../../data/constraints/Constraints";
import {useAppSelector} from "../../../redux/reduxHooks";
import {selectConstraints} from "../../../redux/constraintSlice";
import {selectPub} from "../../../redux/userSlice";
import {Props, ValidatePart} from "./00_PartProps";

const DEMO_USER_ID_1 = "CjKy9blD6q"; // show/hide ohmeansjobs observations

export const validateBehavioursPart:ValidatePart = ({sole, getLabel}) => {
    if (sole.targetObservations === undefined || sole.targetObservations.length === 0) {
        return getLabel('ui.plan.missing_logistics_behaviors')
    }
    return null;
};

export const BehavioursPart: React.FC<Props> = ({props}) => {
    const constraints = useAppSelector(selectConstraints);
    const pub = useAppSelector(selectPub);

    const [newConstraint] = useMemo(() => {

        const oldConstraint = constraints.get('session.target_observations') as SelectionConstraints;

        const newConstraint = new SelectionConstraints();
        newConstraint.min = 1;
        newConstraint.max = 3;

        if (pub?.id && [DEMO_USER_ID_1].includes(pub?.id)) {
            newConstraint.items = oldConstraint.items.filter((item) => item.rdn.includes('ohmeansjobs'));
        } else {
            newConstraint.items = oldConstraint.items.filter((item) => !item.rdn.includes('ohmeansjobs'));
        }

        return [newConstraint];

    }, [constraints]);

    return (
        <Grid item>
            <SoleSelectionComponent
                sole={props.sole}
                setSole={props.setSole}
                jsonKey="targetObservations"
                rdn="session.target_observations"
                isExpanded={true}
                isRow={true}
                constraint={newConstraint}
            />
        </Grid>
    );
};

