import React from "react";
import SoleApprovalCard from "./SoleApprovalCard";
import {useTranslationLabel} from "../../hooks/useTranslation";
import useSoleApproval from "../../hooks/useSoleApproval";
import Loading from "../LoadingComponent";
import Error from "../ErrorComponent";
import {Grid} from "@material-ui/core";

const SoleApproval: React.FC = () => {
    const getLabel = useTranslationLabel();
    const [soles, approveSole, rejectSole, isLoading, errorMessage] = useSoleApproval();
    if (isLoading) {
        return <Loading rdn="ui.admin.loading_soles"/>
    } else if (errorMessage) {
        return <Error text={errorMessage}/>
    } else if (soles && soles.length > 0) {
        return (<Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            {soles.map((sole, index) => {
                return (
                    <Grid item key={`sole-approval${index}`}>
                        <SoleApprovalCard sole={sole} approve={approveSole} reject={rejectSole}/>
                    </Grid>
                )
            })}
        </Grid>);
    } else if (soles && soles.length === 0) {
        return <>No SOLEs!</>
    } else {
        return <Error/>
    }
};

export default SoleApproval;
