import React from "react";
import Loading from "../../LoadingComponent";
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {SoleState} from "../../../shared/soleTypes";
import SoleCard from "../primitives/SoleCardComponent";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import TabPanel from "../../TabPanel";
import {useSessionContext} from "../../../hooks/useSessionContext";
import Error from "../../ErrorComponent";
import {selectErrorMessageSoles, selectIsLoadingSoles, selectSoles} from "../../../redux/solesSlice";
import {useAppSelector} from "../../../redux/reduxHooks";

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    }
});

const PlannedSoles: React.FC = () => {
    const soles = useAppSelector(selectSoles);

    const items = soles
        .filter((sole) => sole.state === SoleState.StatePlanned || sole.state === SoleState.StateFacilitate)
        .map((sole) => <SoleCard key={sole.id} sole={sole}/>);
    return (<>
        {items}
    </>);
};

const ReflectSoles: React.FC = () => {
    const soles = useAppSelector(selectSoles);

    const items = soles
        .filter((sole) => sole.state === SoleState.StateReflect)
        .map((sole) => <SoleCard key={sole.id} sole={sole}/>);
    return (<>
        {items}
    </>);
};

const CompletedSoles: React.FC = () => {
    const soles = useAppSelector(selectSoles);

    const items = soles
        .filter((sole) => sole.state === SoleState.StateCompleted)
        .map((sole) => <SoleCard key={sole.id} sole={sole}/>);
    return (<>
        {items}
    </>);
};

const Soles: React.FC = () => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const [value, setValue] = React.useState(0);
    useSessionContext([
        {
            callback(): void { },
            text: getLabel('ui.my_soles')!
        }
    ]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const isLoading = useAppSelector(selectIsLoadingSoles);
    const errorMessage = useAppSelector(selectErrorMessageSoles);

    if (isLoading) {
        return (<div>
            <Loading rdn="ui.soles.loading_soles"/>
        </div>)
    } else if (errorMessage) {
        return <Error text={errorMessage}/>
    } else {
        return (<div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={ getLabel('ui.soles.planned') }/>
                <Tab label={ getLabel('ui.soles.reflect') }/>
                <Tab label={ getLabel('ui.soles.completed') }/>
            </Tabs>
            <TabPanel value={value} index={0}>
                <PlannedSoles/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ReflectSoles/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <CompletedSoles/>
            </TabPanel>
        </div>)
    }
};

export default Soles;
