import React from "react";
import Loading from "../../LoadingComponent";
import {isEmbedded, notifyHost} from "../../../embedded";
import {Redirect} from "react-router-dom";
import Error from "../../ErrorComponent";
import useSaveSole from "../../../hooks/useSaveSole";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import {ISole} from "../../../shared/soleTypes";

export type OnSaveHandler = (sole:ISole)=>void;

interface SoleSaverProps {
    getContent:(onSave:OnSaveHandler)=>JSX.Element;
}

const SoleSaver: React.FC<SoleSaverProps> = ({getContent}) => {

    const [saveSole, savedSole, isSavingSole, errorMessage] = useSaveSole();

    if (isSavingSole) {
        return <Loading rdn="ui.soles.saving_sole"/>
    } else if (savedSole) {
        if (isEmbedded()) {
            notifyHost('close');
            return null;
        } else {
            return <Redirect to="/soles/"/>
        }
    } else if (errorMessage) {
        return <Error text={errorMessage}/>;
    } else {
        return (<>{getContent(
                async (sole:ISole) => {
                    await saveSole(sole);
                }
            )}</>);
    }
};

export default SoleSaver;
