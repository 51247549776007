import {useEffect, useMemo, useState} from "react";
import {Tag} from "../../data/Tag";
import {allRdn, initialStandardPickerState, StandardPickerState} from "./StandardPickerComponent";
import {getAllSubjects} from "../../shared/subjects";
import {useAppSelector} from "../../redux/reduxHooks";
import {selectGrades} from "../../redux/tagsSlice";
import {selectPub} from "../../redux/userSlice";
import {IUserPub} from "../../shared/soleTypes";
import {loadGrades, loadStandard} from "./TagCache";

/*
 state?: current or initial state
 window.location.href.searchParams.standards: "[/]subject[/grade[/tag1[/tag2[/...]]]]"

*/

function getUserIntialPickerState(pub?:IUserPub, grades?:Tag[], initialState?: StandardPickerState): StandardPickerState {

    // log initial state and url
//    console.log(`Enter useIntialPickerState state=${JSON.stringify(initialState)} URL=${window.location.href}`);

    // copy state from initialState
    const pickerState: StandardPickerState = {...initialStandardPickerState, ...initialState};

    // initialize subjects if not set
    if (pickerState.subjects.length === 0 && pub) {
        pickerState.subjects = getAllSubjects(pub);
    }

    // initialize grades if not set
    if (pickerState.grades.length === 0 && grades) {
        pickerState.grades = [{
            rdn: allRdn,
            short: 'All Grades'
        }, ...grades];
    }

    // use current selection from optional <url>?standards=....
    const url = new URL(window.location.href);
    const params = url.searchParams;
    let selectedStr = params.get('standards');
    if (selectedStr && selectedStr.length > 0) {
        if (selectedStr.charAt(0) === '/') {
            selectedStr = selectedStr.slice(1);
        }
        const selected = selectedStr.split('/').filter((s) => s !== "");
        if (selected.length >= 2) {
            pickerState.selectedSubjectRdn = selected[0];
            pickerState.selectedGradeRdn = selected[1];
            pickerState.selectedTagRdns = selected.slice(2); // will return empty if selected.length <= 2
        }
    }

//    console.log(`Exit useIntialPickerState state=${JSON.stringify(pickerState)}`);

    return pickerState;
}

export default function useStandardPickerState(
        onlyStandardsWithQuestions: boolean,
        pickerState: StandardPickerState = initialStandardPickerState):
        [
            StandardPickerState,
            (rdn: string) => void,
            (rdn: string) => void,
            (index: number, rdn: string) => void,
            (subject?: string, grade?: string, tags?: string[]) => void,
            boolean,
            string
        ] {

    const grades = useAppSelector(selectGrades);
    const pub = useAppSelector(selectPub);
    const initialPickerstate = useMemo(() => getUserIntialPickerState(pub, grades, pickerState), []);
    const [state, setState] = useState(initialPickerstate);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    async function select(subject?: string, grade?: string, tags?: string[]) {
        if (subject === state.selectedSubjectRdn &&
            (subject === undefined || state.subjects.length > 1) &&
            grade === state.selectedGradeRdn &&
            (grade === undefined || state.grades.length > 1) &&
            JSON.stringify(tags) === JSON.stringify(state.selectedTagRdns) &&
            ((tags?.length ?? 0) === 0 || state.tags.length > 0)) {
            return;
        }

        setIsLoading(true);
        try {
            const newState = {...state};
            const subjectObj = state.subjects.find((t) => subject === t.rdn || subject === t.subject);
            newState.selectedSubjectRdn = subjectObj ? subjectObj.rdn : allRdn;
            newState.grades = await loadGrades(newState.selectedSubjectRdn && newState.selectedSubjectRdn !== allRdn ? newState.selectedSubjectRdn : "*");
            if (grade && newState.grades.find((t) => t.rdn === grade)) {
                newState.selectedGradeRdn = grade ? grade : allRdn;
            } else {
                newState.selectedGradeRdn = allRdn;
                newState.selectedTagRdns = [];
            }

            if (newState.selectedGradeRdn !== allRdn && newState.selectedSubjectRdn !== allRdn) {
                const nextTags = await loadStandard(newState.selectedSubjectRdn, newState.selectedGradeRdn, !onlyStandardsWithQuestions);
                newState.tags = [nextTags];
            }

            if (newState.selectedGradeRdn && newState.selectedGradeRdn !== allRdn && tags) {
                newState.selectedTagRdns = tags;
                let i = 0;
                for (const tag of tags) {
                    if (i === tags.length - 1) {
                        break;
                    }
                    i++;
                    if (tag && tag !== allRdn) {
                        const nextTags = await loadStandard(tag, newState.selectedGradeRdn, !onlyStandardsWithQuestions);
                        newState.tags.push(nextTags);
                    }
                }
            } else {
                newState.selectedTagRdns = [];
            }
            setIsLoading(false);
            if (JSON.stringify(state) !== JSON.stringify(newState)) {
                setState(newState);
            }
        } catch (error: any) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    }

    async function selectSubject(rdn: string) {
        setIsLoading(true);

        try {
            const grades = await loadGrades(rdn && rdn !== allRdn ? rdn : "*");
            setIsLoading(false);
            const newState = {
                ...state,
                selectedSubjectRdn: rdn,
                grades: grades,
                selectedGradeRdn: allRdn,
                tags: [],
                selectedTagRdns: []
            };

            if (state.selectedGradeRdn !== undefined &&
                state.selectedGradeRdn !== allRdn &&
                grades.find((grade) => grade.rdn === state.selectedGradeRdn) !== undefined) {
                newState.selectedGradeRdn = state.selectedGradeRdn;
                await addTag(newState, newState.selectedSubjectRdn ?? allRdn, newState.selectedGradeRdn, 0);
            } else {
                setState(newState);
            }
        } catch (error: any) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    }

    async function addTag(state: StandardPickerState, previousRdn: string, gradeRdn: string, atPosition: number) {
        setIsLoading(true);
        try {
            let tags: Tag[] = [];
            if (previousRdn !== allRdn) {
                tags = await loadStandard(previousRdn, gradeRdn, !onlyStandardsWithQuestions);
            }
            if (tags && tags.length > 0) {
                state.tags[atPosition] = tags;
                state.selectedTagRdns[atPosition] = allRdn;

                state.tags.splice(atPosition + 1);
                state.selectedTagRdns.splice(atPosition + 1);
            } else {
                state.tags.splice(atPosition);
                state.selectedTagRdns.splice(atPosition);
            }

            setState(state);
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    }

    async function selectGrade(rdn: string) {
        await addTag({...state, selectedGradeRdn: rdn}, state.selectedSubjectRdn ?? allRdn, rdn, 0);
    }

    async function selectTag(index: number, rdn: string) {
        setIsLoading(true);

        const newState = {
            ...state
        };
        newState.selectedTagRdns[index] = rdn;
        await addTag(newState, rdn, state.selectedGradeRdn || "", index + 1);
    }

    useEffect(() => {
        select(state.selectedSubjectRdn, state.selectedGradeRdn, state.selectedTagRdns).then();
    }, [state]);

    return [state, selectSubject, selectGrade, selectTag, select, isLoading, errorMessage];
}
