import React from "react";
import {FormLabel, Grid, Typography} from "@material-ui/core";

import SoleBooleanComponent from "../primitives/SoleBooleanComponent";
import {Props, ValidatePart} from "./00_PartProps";
import {isMobile} from "react-device-detect";
import {useTranslationLabel} from "../../../hooks/useTranslation";

export const validateManagePart:ValidatePart = (_props) => {
    return null;
};

export const ManagePart: React.FC<Props> = ({props}) => {
    const getLabel = useTranslationLabel();
    return (
        <Grid item xs={isMobile ? 12 : 5}>
            <FormLabel><Typography gutterBottom>{getLabel('ui.plan.how_will_you_manage')}</Typography></FormLabel>

            <SoleBooleanComponent
                sole={props.sole}
                setSole={props.setSole}
                jsonKey="groupOrganization"
                rdn="session.grouporganization"
            />
            <SoleBooleanComponent
                sole={props.sole}
                setSole={props.setSole}
                jsonKey="groupsharing"
                rdn="session.groupsharing"
            />
            <SoleBooleanComponent
                sole={props.sole}
                setSole={props.setSole}
                jsonKey="self_assessment"
                rdn="session.self_assessment"
            />

        </Grid>
    );
};

