import {ISole} from "../../shared/soleTypes";
import {allRdn, initialStandardPickerState, StandardPickerState} from "./StandardPickerComponent";
import {loadTagJson, TagJson} from "./TagCache";


/*

The standardPicker is currently used in two scenarios:
1) on question search
2) during sole planning

A standard as represented by the StandardPicker has 3 components:
- [standards] => an array with multiple leaf rdns => currently only ONE rdn is set (no multi-select UI)
- subject => the root-rdn (=DOCUMENT-rdn) associated with the 1st (!) leaf rdn in [standards]
- grade => grade-rdn

A Sole also has three components but a DIFFERENT definition of "subject":
- [standards] => an array with multiple leaf rdns
- subject => the TOPIC-rdn of the document associated with the 1st (!) leaf rdn in [standards], basically the "standard document"
- grade => grade-rdn

On iOS, the user picks a grade, a topic, than one or more standards associated with the topic.
- the grade is stored in sole.grade (rdn:session.grade)
- the topic-rdn ("top.xxxxx") is stored as sole.subject (rdn:session.subject)
- one or more selected leaf standards are stored in sole.standards (rdn:session.standards)

In the WebApp, the user picks a root-document, a grade and a leaf standard document
- the grade is stored in sole.grade (rdn:session.grade)
- the topic-rdn ("top.xxxxx") associated with the root-document is stored as sole.subject (rdn:session.subject)
- the leaf-standard is stored in sole.standards

Mapping Sole => StandardPickerState:
- grade and [standards] map 1:1
- subject (dxx.xxxxx) is taken from the 1st leaf node in [standards]
- NOTE: subject when set in the sole is always top.xxx and ***doesn't*** map to a unique document!!!

StandardPickerState => Sole:
- grade and [standards] map 1:1
- subject (top.xxxxx) is taken from the topic of the 1st leaf node in [standards]
- !!! subject is ONLY updated when not empty as the "standards" subject can be different than the "sole" subject (!!!)

What to use during sole planning/updating:
1) no standard set in sole and empty InitialStandardState => copy (empty) initialStandardSate to sole and standardState
2) standard set in InitialStandardState, no standard set in sole => copy initialStandardSate to sole and standardState
3) standard set in sole and empty InitialStandardState => ignore InitialStandardState, copy sole to standardState
4) standard set in sole and in InitialStandardState => ignore (populated) InitialStandardState, copy sole to standardState

What to do when subject/grade is changed on the "Class Details" screen:
- grade is also updated in the StandardsPickerState
- subject is NOT updated in StandardsPicker state the subject in the StandardsPicker is ALWAYS taken from the leaf node in standards
- this allows for the standard on the first planning screen("question") to be different from the subject on the 2nd planning screen ("Class Details")

What to do when subject/grade is changed the "Question" screen:
- grade is always updated in the sole and will also change on the "Class Details" screen
- subject is ONLY set in the sole if it is empty => the "Class Details" screen continuous to show the "old" subject!!!

NOTE: This means, from a user perspective, the "standard-document" selected on the 1st planning screen can be different than the "topic" selected on the 2nd screen.

NOTE: With Sole planning the StandardPickerState is either 'empty' or initialised with the result from the latest question search
- standard used for search = standard used for planning

NOTE: on initialisation, the StandardPicker loads the 'subjects' based on the current user/ring profile, pre-filtering "subject" selection.

*/

export function standardsAreEqual(s1:string[], s2:string[]):boolean {
    return s1.length === s2.length && s1.every((s, idx) => s === s2[idx]);
}

export function pickerStateIsEqual(p1:StandardPickerState, p2:StandardPickerState|undefined):boolean {
    return p2 !== undefined && p1.selectedSubjectRdn === p2.selectedSubjectRdn &&
        p1.selectedGradeRdn === p2.selectedGradeRdn &&
        standardsAreEqual(p1.selectedTagRdns ?? [], p2.selectedTagRdns ?? []);
}

export async function standardPickerStateFromSole(sole: ISole, oldState:StandardPickerState): Promise<StandardPickerState> {
    const tags: string[] = [];

    // if the oldstate is not the (empty) initialState, than just keep the oldstate, ignoring whatever is in the sole
    if (!pickerStateIsEqual(initialStandardPickerState, oldState)) {
        return oldState;
    }

    // take defaults from sole
    let {grade, subject} = sole;

    // get leaf tag from sole.standards and use that to overwrite grade and subject
    const leaf = sole.standards && sole.standards.length > 0 ? sole.standards[0] : allRdn;
    if (leaf && leaf !== allRdn) {
        let leafTag = undefined;
        try {
            leafTag = await loadTagJson(leaf);
            if (leafTag) {
                // take grade from sole when included in leaf, else 1st grade from leaf
                // NOTE: this means the grade might change between initial selection in the standard picker and 'reload'
                if (grade && leafTag.grades && leafTag.grades.length) {
                    grade = leafTag.grades.includes(grade) ? grade : leafTag.grades[0];
                }
                // traverse ancestry and fill tags
                let tag:TagJson|undefined = leafTag;
                while (tag && tag.partof.length > 1) {
                    tags.unshift(tag.rdn);
                    tag = tag.parent;
                }
                // use root as subject doucment
                subject = tag!.rdn; // should never fail unless the ancestry tree is brokon...
                // add final tag
                tags.push(allRdn);
            }
        } catch (e: any) {
            console.log(`error traversing tag ancestry tree: ${e.message} leafTag=${JSON.stringify(leafTag)}`);
        }
    }

    // return newState
    return {
        ...oldState,
        selectedGradeRdn: grade ?? oldState.selectedGradeRdn ?? allRdn,
        selectedSubjectRdn: subject ?? oldState.selectedSubjectRdn ?? allRdn,
        selectedTagRdns: tags.length ? tags : oldState.selectedTagRdns ?? [allRdn]
    };
}

export function soleFromStandardPickerState(state:StandardPickerState, newSole:ISole) {

    if (state.subjects.length === 0) {
//        console.log(`SETSTANDARD: state has no subjects loaded => ignore update`);
        return newSole;
    }

    // set leaf standard
    const tags = (state.selectedTagRdns ?? []).filter((t) => t !== allRdn);
    const tag = tags.length > 0 ? tags[tags.length - 1] : undefined;
    if (tag && tag !== allRdn) {
        newSole.standards = [tag];
    }

    // set grade (default for planning, can be set seperately)
    if (newSole.grade === undefined && state.selectedGradeRdn && state.selectedGradeRdn !== allRdn) {
        newSole.grade = state.selectedGradeRdn;
    }

    // set subject (default for planning, can be set seperately)
    // subject for the selected standard is the rdn of the root standard (typically asn.dxxxxx)
    // subject saved in session.subject is the subject of the root standard (typially top.xxxx)
    if (newSole.subject === undefined && state.selectedSubjectRdn && state.selectedSubjectRdn !== allRdn) {
        const subjectObj = state.subjects.find((s) => (s.rdn === state.selectedSubjectRdn || s.subject === state.selectedSubjectRdn)); // find topic for selected subject
        if (subjectObj) {
            newSole.subject = subjectObj.subject;
        } else {
            console.log(`WARNING: topic not found for ${state.selectedSubjectRdn} => sole subject not set!`);
            console.log(`   ${JSON.stringify(state.subjects)}`);
        }
    }

    return newSole;
}

export function willUpdateSoleWithPickerstate(pickerState:StandardPickerState, sole:ISole):boolean {
    const newSole = soleFromStandardPickerState(pickerState, {...sole});
    return sole.grade !== newSole.grade ||
        sole.subject !== newSole.subject ||
        !standardsAreEqual(sole.standards ?? [], newSole.standards ?? []);
}
