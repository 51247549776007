import React from 'react';
import {Button, Card, CardActions, CardContent, CardMedia, Grid, makeStyles, Typography} from "@material-ui/core";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {Link as RouterLink, match} from "react-router-dom";
import {useSessionContext} from "../../hooks/useSessionContext";
import {useAppSelector} from "../../redux/reduxHooks";
import {selectPub} from "../../redux/userSlice";
import {HidableComponent} from "../hoc/Hidable";

const useStyles = makeStyles((theme) => ({
    marginAutoContainer: {
        height: `calc(80vh - ${theme.spacing(6)}px - ${theme.mixins.toolbar.minHeight}px - 8px)`
    },
    marginAutoItem: {
        margin: 'auto'
    },
    maxWidthCard: {
        maxWidth: '400px',
        margin: 'auto'
    },
    media: {
        objectFit: 'none'
        // padding: theme.spacing(2)
    }
}));

interface CommunityProps {
    match?: match<{ ringRdn: string }>;
}

const CommunityHome: React.FC<CommunityProps> = (props) => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const pub = useAppSelector(selectPub);
    const ringRdn = props.match?.params.ringRdn;
    const ring = pub?.rings?.find((ring) => ring.rdn === ringRdn);

    useSessionContext([
        {
            callback(): void {
            },
            text: getLabel('ui.sole_and_udl') ?? 'My Community'
        },
        {
            callback(): void {
            },
            text: ring?.name ?? 'My Ring'
        }
    ]);

    return (<Grid container className={classes.marginAutoContainer}>
        <Grid item xs={12} sm={12} md={6} className={classes.marginAutoItem}>

            <Card variant="outlined">
                {/* <CardActionArea>*/}
                <CardMedia className={classes.media} component="img" image={ringRdn === 'us.pa.iu8' ? "/images/logos/iu8logo-small.png" : "/images/logos/lake-erie-west-logo.png"}/>
                <CardContent>
                    <Typography gutterBottom variant="h5" align="center">
                        Welcome to your StartSOLE + UDL!
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                        {ringRdn === 'us.pa.iu8' ? "Pennsylvania Intermediate Units are pleased to be able to share this collaborative Expanding Virtual Learning project between StartSOLE and the Educational Service Center of Lake Erie West has been made possible through grant funding from both the RemotEDx initiatives through the Ohio Department of Education and Philanthropy Ohio. This project utilizes the concepts of Universal Design for Learning to allow teachers to enhance lessons that are accessible for all students." : "This collaborative Expanding Virtual Learning project between StartSOLE and the Educational Service Center of Lake Erie West has been made possible through grant funding from both the RemotEDx initiatives through the Ohio Department of Education and Philanthropy Ohio.  This project utilizes the concepts of Universal Design for Learning to allow teachers to enhance digital lessons."}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {ringRdn === 'us.pa.iu8' ? "Completing each training will award you points towards an ACT 48 certificate.  You can check your total points and current progress on your Profile page." : "To complete the asynchronous trainings, click the view trainings button below.  To schedule virtual support, click the request help button below and a StartSOLE team member will contact you."}
                    </Typography>
                </CardContent>
                {/* </CardActionArea>*/}
                <CardActions>
                    <Grid container justifyContent="center" alignItems="center" spacing={3}>
                        <Grid item>
                            <Button size="small" component={RouterLink} to={ringRdn === 'us.pa.iu8' ? "/community/us.pa.iu8/training" : "/community/us.oh.lakeeriewest/training"}>View
                                Trainings</Button>
                        </Grid>
                        <HidableComponent isVisible={ringRdn === 'us.oh.lakeeriewest'}>
                            <Grid item>
                                <Button size="small"
                                        href="https://docs.google.com/forms/d/e/1FAIpQLSeuqAqDu4WZCy0PHI018D8TTNgx9Zz1YVx2lp9o73jWJB4E_A/viewform"
                                        target="_blank">Request Help</Button>
                            </Grid>
                        </HidableComponent>

                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    </Grid>)
};

export default CommunityHome;
