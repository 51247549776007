import React, {useEffect, useState} from "react";
import {Tag} from "../data/Tag";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {useAppSelector} from "../redux/reduxHooks";
import Parse from "parse";
import Loading from "./LoadingComponent";

interface TagRdnsEditorProps {
    rdns: string[][];
    onChange: (tags: Tag[], index: number) => void;
}

export const TagRdnsEditor: React.FC<TagRdnsEditorProps> = ({rdns, onChange}) => {
    const [tags, setTags] = useState<Tag[][]>([]);
    const [isLoadingTags, setIsLoadingTags] = useState(true);

    useEffect(() => {
        (async () => {
            setIsLoadingTags(true);
            const newTagTags: Tag[][] = [];
            console.log(`rdns: ${JSON.stringify(rdns)}`);
            for (const tagRdns of rdns) {
                const newTags: Tag[] = [];
                for (const tagRdn of tagRdns) {
                    const tag = await Parse.Cloud.run(
                        'tag.getJson',
                        {
                            rdn: tagRdn,
                            options: {}
                        },
                        {
                            sessionToken: Parse.User.current()?.getSessionToken()
                        });
                    newTags.push(tag);
                }
                newTagTags.push(newTags);
            }
            setTags(newTagTags);
            setIsLoadingTags(false);
        })();
    }, []);

    if (isLoadingTags) {
        return <Loading rdn="ui.loading_tags"/>
    } else {
        return (
            <>
                {tags.map((t, index) => {
                    return (<QuestionTagEditor
                        key={`question-tag-editor-${index}`}
                        tags={t}
                        onChange={(changedTags) => {
                            const newTags = [...tags];
                            newTags[index] = changedTags;
                            setTags(newTags);
                            onChange(changedTags, index);
                        }}
                    />);
                })}</>
        )
    }
};


interface QuestionTagEditorProps {
    tags: Tag[];
    onChange: (tags: Tag[]) => void;
}

const QuestionTagEditor: React.FC<QuestionTagEditorProps> = ({tags, onChange}) => {
    const [myTags, setMyTags] = useState(tags);
    const [options, setOptions] = useState<Tag[]>([]);
    const allTags = useAppSelector((state) => state.tags.tags);

    useEffect(() => {
        setOptions([...new Set([...tags, ...allTags])]);
    }, [tags, allTags]);

    return (
        <Autocomplete
            multiple
            id="tags-outlined"
            options={options}
            getOptionLabel={(option) => option.short}
            defaultValue={tags}
            style={{width:'100%'}}
            value={myTags}
            onChange={(e, value) => {
                setMyTags(value);
                onChange(value);
            }}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Tags"
                />
            )}
        />
    );
};

export default QuestionTagEditor;

