import React, {FormEvent, useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useAppDispatch, useAppSelector} from "../../redux/reduxHooks";
import {Redirect} from "react-router-dom";
import Loading from "../LoadingComponent";
import StandardPicker, {allRdn, StandardPickerState} from "../standards/StandardPickerComponent";
import {useSessionContext} from "../../hooks/useSessionContext";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {makeStyles} from "@material-ui/core/styles";
import Error from "../ErrorComponent";
import {SessionContextItem} from "../../redux/sessionSlice";
import {addQuestion, addQuestionReset} from "../../redux/questionAddSlice";

interface AddProps {
    location: {
        state: {
            pickerState?: StandardPickerState;
        };
    };
}

const useStyles = makeStyles({
    headerImage: {
        maxWidth: '100%'
    }
});

const QuestionAdd: React.FC<AddProps> = (props) => {
    const [question, setQuestion] = useState('');
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const [pickerState, setPickerState] = useState<StandardPickerState | undefined>(props.location?.state?.pickerState ?? undefined);
    const [questionTags, setQuestionTags] = useState<string[]>([]);
    const isSaving = useAppSelector((state) => state.questionAdd.isSaving);
    const errorMessage = useAppSelector((state) => state.questionAdd.errorMessage);
    const addedQuestionId = useAppSelector((state) => state.questionAdd.addedQuestionId);
    const [, setSessionContext] = useSessionContext([]);
    const getLabel = useTranslationLabel();

    useEffect(() => {
        return function cleanup() {
            if (addedQuestionId) {
                dispatch(addQuestionReset());
            }
        }
    });

    useEffect(() => {
        const context: SessionContextItem[] = [
            {
                callback(): void {
                },
                text: getLabel('ui.add_a_question')!
            }
        ];
        if (question.length > 0) {
            context.push({
                callback(): void {
                },
                text: question
            });
        }
        setSessionContext(context);
    }, [question]);

    const onChangedStandardPicker = (state: StandardPickerState) => {
        const tags = [state.selectedSubjectRdn ?? '', state.selectedGradeRdn ?? '', ...state.selectedTagRdns].filter((tag) => {
            return tag !== '' && tag !== allRdn
        });
        setQuestionTags(tags);
        setPickerState(state);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(addQuestion(question, questionTags));
    };

    if (isSaving) {
        return (<div>
            <Loading rdn="ui.adding_question"/>
        </div>)
    } else if (errorMessage) {
        return <Error text={errorMessage}/>
    } else if (addedQuestionId) {
        return <Redirect to={`/questions/${addedQuestionId}`}/>
    } else {
        return (<Container component="main" maxWidth="xs">
            <img className={classes.headerImage} src="/images/questions/add-question.svg"/>
            <form onSubmit={handleSubmit} noValidate>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    maxRows={4}
                    id="question"
                    label={getLabel('ui.enter_your_question')}
                    name="question"
                    autoComplete="question"
                    autoFocus
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                />

                <StandardPicker
                    onChange={onChangedStandardPicker}
                    hasSessionContext={false}
                    onlyStandardsWithQuestions={false}
                    standardPickerState={pickerState}/>

                <Button
                    disabled={question.length === 0}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    {getLabel('ui.submit_question')}
                </Button>
            </form>
        </Container>);
    }
};

export default QuestionAdd;
