// @ts-nocheck
// TODO: type this?
import React, {useState} from "react";
import {Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const LineGraph = ({
                       title,
                       dataKey,
                       oxLabel,
                       oyLabel,
                       values,
                       yLimit,
                       labels
                   }) => {
    const [lineProps, setLineProps] = useState(
        labels.reduce(
            (a, {key}) => {
                a[key] = false;
                return a;
            },
            {hover: null}
        )
    );

    const handleLegendMouseEnter = (e) => {
        if (!lineProps[e.dataKey]) {
            setLineProps({...lineProps, hover: e.dataKey});
        }
    };

    const handleLegendMouseLeave = (e) => {
        setLineProps({...lineProps, hover: null});
    };

    const selectLine = (e) => {
        setLineProps({
            ...lineProps,
            [e.dataKey]: !lineProps[e.dataKey],
            hover: null
        });
    };

    return (
       <div>
            <h3>{title}</h3>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={values}
                    margin={{top: 30, right: 30, left: 20, bottom: 5}}
                >
                    <XAxis dataKey={dataKey}>
                        <Label value={oxLabel} position="insideBottomRight" dy={10} dx={20}/>
                    </XAxis>
                    <YAxis type="number" domain={yLimit}>
                        <Label
                            value={oyLabel}
                            position="left"
                            angle={-90}
                            dy={-20}
                            dx={-10}
                        />
                    </YAxis>
                    <Tooltip/>
                    <Legend
                        onClick={selectLine}
                        onMouseOver={handleLegendMouseEnter}
                        onMouseOut={handleLegendMouseLeave}
                    />
                    {labels.map((label, index) => (
                        <Line
                            key={index}
                            dataKey={label.key}
                            stroke={label.color}
                            hide={lineProps[label.key] === true}
                            dot={false}
                            type="monotone"
                            connectNulls={true}
                            strokeWidth={Number(
                                lineProps.hover === label.key || !lineProps.hover ? 1 : 0.1
                            )}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default LineGraph;
