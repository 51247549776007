import {put, select} from "redux-saga/effects";
import Parse from "parse";
import {SoleText} from "../data/SoleText";
import clientTexts from "../shared/clientTexts";
import {setTexts, textLoading, textLoadingFailed, textLoadingFinished, TextState} from "../redux/textSlice";
import {RootState} from "../redux/store";

export const textLoadingFinishedSaga = function* () {
    // const dpState: DataPointsState = yield select((state: AppState) => state.dataPoints);
    // if (dpState.dataPoints.length === 0 && !dpState.isLoading) {
    //     yield put(loadDataPoints());
    // }
};

export const loadTextSaga = function* () {
    const textState: TextState = yield select((state: RootState) => state.text);
    if (!textState.isLoading) {
        yield put(textLoading());

        // Get texts from backend if necessary
        try {
            const remoteUpdatedAt: Date | undefined = yield Parse.Cloud.run('cache.getLatestUpdatedAt',
                {
                    className: 'Text'
                }, {});

            let localUpdatedAt: Date | undefined = undefined;
            const localUpdatedAtStr = localStorage.getItem('updatedAtText') as string;
            if (localUpdatedAtStr) {
                localUpdatedAt = new Date(localUpdatedAtStr);
            }

            const needsDownload = remoteUpdatedAt === undefined
                || localUpdatedAt === undefined
                || localUpdatedAt < remoteUpdatedAt;

            let texts: SoleText[] = [...textState.texts];
            if (needsDownload) {
                const parseTexts: Parse.Object[] = yield Parse.Cloud.run('text.load',
                    {
                        lng: 'en_US',
                        date: new Date(0)
                    });
                texts = parseTexts.map((text) => SoleText.createFromParse(text));

                // Append client texts
                clientTexts.forEach((text) => {
                    const index = texts.findIndex((t) => t.rdn === text.rdn && t.language === text.language);
                    if (index >= 0) {
                        texts[index] = text;
                    } else {
                        texts.push(text);
                    }
                });

                localStorage.setItem('texts', JSON.stringify(texts));

                yield put(setTexts(texts));

                if (remoteUpdatedAt !== undefined) {
                    localStorage.setItem('updatedAtText', remoteUpdatedAt.toISOString());
                }
            }
            yield put(textLoadingFinished());
        } catch (error: any) {
            yield put(textLoadingFailed(error.message));
        }
    }
};
