import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ISole} from "../shared/soleTypes";
import {RootState} from "./store";

export interface SolesState {
    isLoading: boolean;
    errorMessage?: string;
    soles: ISole[];
}

const initialState: SolesState = {
    isLoading: false,
    errorMessage: undefined,
    soles: []
};

export const solesSlice = createSlice({
    name: 'soles',
    initialState,
    reducers: {
        loadSoles: (state) => {
            // will be handled by saga
        },
        solesLoading: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
            state.soles = [];
        },
        solesLoadingFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
            state.soles = [];
        },
        setSoles: (state, action: PayloadAction<ISole[]>) => {
            state.isLoading = false;
            state.errorMessage = undefined;
            state.soles = action.payload;
        },
        deleteSole: (state, action: PayloadAction<string>) => {
            // will be handled by saga
        },
        addSole: (state, action: PayloadAction<string>) => {
            // will be handled by saga
        }
    }
});

export const {loadSoles, solesLoading, solesLoadingFailed, setSoles, deleteSole, addSole} = solesSlice.actions;

export const selectSoles = (state: RootState) => state.soles.soles;
export const selectIsLoadingSoles = (state: RootState) => state.soles.isLoading;
export const selectErrorMessageSoles = (state: RootState) => state.soles.errorMessage;

export default solesSlice.reducer;
