import React, {useState} from "react";
import {Avatar, Box, Button, Paper, Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LocationAutocomplete, {PlaceType} from "./LocationAutocompleteComponent";
import SaveIcon from "@material-ui/icons/Save";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {HidableComponent} from "../hoc/Hidable";
import Error from "../ErrorComponent";
import {useAppDispatch, useAppSelector} from "../../redux/reduxHooks";
import {saveSchool} from "../../redux/userSlice";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1)
        }
    },
    avatar: {
        width: theme.spacing(16),
        height: theme.spacing(16),
        margin: "auto"
    },
    address: {
        padding: '10px',
        maxWidth: '70%',
        backgroundColor: theme.palette.info.light,
        margin: 'auto'
    },
    marginAutoContainer: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        display: 'flex'
    }
}));

const LocationSelector: React.FC = () => {
    const [place, setPlace] = useState<PlaceType | null>(null);
    const school = useAppSelector((state) => state.user.school);
    const errorMessage = useAppSelector((state) => state.user.errorMessage);
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const dispatch = useAppDispatch();

    const onSave = () => {
        if (place) {
            dispatch(saveSchool(place.place_id));
        }
    };

    return (<div>
        <Avatar alt="School Image" src="/images/profile/school.svg" className={classes.avatar}/>

        <Typography variant="body1" gutterBottom align="center">
            {getLabel('ui.profile.location_explanation')}
        </Typography>

        <div className={classes.marginAutoContainer}>
            <Paper className={classes.address} elevation={0}>
                <Typography variant="body1" align="center">{school?.name}</Typography>
                <Typography variant="body1" gutterBottom align="center">{school?.address}</Typography>
            </Paper>
        </div>


        <Box display="flex" mb={3}>
            {/* <Box m={"auto"}>*/}
            <LocationAutocomplete
                onChange={(place: PlaceType | null) => {
                    setPlace(place);
                }}
                update={true}
            />
            {/* </Box>*/}
        </Box>


        <Box display="flex">
            <Box m="auto">
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => onSave()}
                    startIcon={<SaveIcon/>}
                >
                    {getLabel('ui.save')}
                </Button>
            </Box>
        </Box>
        <HidableComponent isVisible={errorMessage !== undefined}>
            <Error text={errorMessage}/>
        </HidableComponent>

    </div>)
};
export default LocationSelector;
