import * as React from 'react';
import {DataGrid, GridCellParams, GridColDef} from '@material-ui/data-grid';
import {IRing} from "../../shared/soleTypes";
import {Link} from "@material-ui/core";

interface RingTableProps {
    rings: IRing[];
}

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 0.7,
        renderCell: (params: GridCellParams) => (<Link href={`rings/${params.row.rdn}`}>
            {params.value}
        </Link>)
    },
    {field: 'schoolCount', headerName: 'Schools', flex: 0.15},
    {field: 'membershipCount', headerName: 'Educators', flex: 0.15},
    {field: 'soleCount', headerName: 'Soles', flex: 0.15}
];

const RingTable: React.FC<RingTableProps> = (props) => {

    const rows = props.rings.map((ring, index) => ({id: index, ...ring}));

    return (<div style={{width: '100%'}}>
        <DataGrid rows={rows} columns={columns} pageSize={100} autoHeight hideFooter checkboxSelection={false} sortModel={[
            {
                field: 'name',
                sort: 'asc'
            }]}/>
    </div>);
};

export default RingTable;
