import React, {useEffect, useState} from "react";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {StandardPickerState} from "../standards/StandardPickerComponent";
import {IQuestion} from "@startsole/startsole-types/src/solejson";
import {ISole} from "../../shared/soleTypes";
import {Button, Container, createStyles, Grid, IconButton, makeStyles, Snackbar, Typography} from "@material-ui/core";
import {useAppDispatch, useAppSelector} from "../../redux/reduxHooks";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {useTranslationLabel} from "../../hooks/useTranslation";
import QuestionResources from "./QuestionResourcesComponent";
import {toggleFavoriteQuestion} from "../../redux/myQuestionsSlice";
import {HidableComponent} from "../hoc/Hidable";
import {selectPub} from "../../redux/userSlice";
import Parse from "parse";
import {selectSoles, setSoles} from "../../redux/solesSlice";

interface QuestionProps {
    question: IQuestion;
    pickerState?: StandardPickerState;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingBottom: theme.spacing(2),
            minWidth: 180,
            textAlign: 'center',
            color: theme.palette.text.secondary,
            whiteSpace: 'nowrap'
        },
        card: {
            maxWidth: '700px',
            paddingTop: theme.spacing(5),
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingBottom: theme.spacing(2)
        },
        alignText: {
            textAlign: 'center',
            marginTop: theme.spacing(3)
        },
        resourceContainer: {
            paddingTop: theme.spacing(4)
        }
    })
);

const SingleQuestion: React.FC<QuestionProps> = ({question, pickerState}) => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();

    const pub = useAppSelector(selectPub);
    const myFavoriteQuestions = useAppSelector((state) => state.myQuestions.myFavoriteQuestions);
    const soles = useAppSelector(selectSoles);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [isFavorite, setIsFavorite] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackbarMessage] = useState<string>('');

    useEffect(() => {
        setIsFavorite(
            myFavoriteQuestions.find((q) => q.id === question.id) !== undefined
        );

    }, [myFavoriteQuestions]);


    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const createQuickstartSole = async () => {
        try {
            const soleId = await Parse.Cloud.run<(param: {question:string}) => string>('webapp.quickstart', {
                question: question.id ?? 'no-question-id'
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });

            const sole = await Parse.Cloud.run<(param: {id:string}) => ISole>('webapp.getSoleJsonById', {
                id: soleId
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });

            const newSoles = [...soles];
            newSoles.unshift(sole);
            dispatch(setSoles(newSoles));
            history.push(`/search-teacher`, {sole: sole})
        } catch (error:any) {
            // ignore error
        }
    };

    const onToggleFavorite = () => {
        if (question.id) {
            const message = !isFavorite ? getLabel('ui.question.added_to_favorites') : getLabel('ui.question.removed_from_favorites');
            dispatch(toggleFavoriteQuestion(question.id));
            setSnackbarMessage(message as string);
            setOpen(true);
        }
    };

    return (<Grid container justifyContent="center">
        <Typography variant="h4" align="center" gutterBottom={true}>
            {question.text}
        </Typography>
        <Grid container spacing={6} justifyContent="center">
            <Grid item className={classes.alignText}>
                <Button
                    variant="contained"
                    size="large"
                    startIcon={<AssignmentIcon/>}
                    component={RouterLink}
                    color="primary"
                    to={{
                        pathname: "/soles/plan",
                        state: {
                            question: question,
                            pickerState: pickerState
                        }
                    }}>
                    {getLabel('ui.question.plan_a_lesson')}
                </Button>
            </Grid>
            <Grid item className={classes.alignText}>
                <Button
                    variant="contained"
                    size="large"
                    startIcon={!isFavorite ? <BookmarkBorderIcon/> : <BookmarkIcon/>}
                    color="primary"
                    onClick={onToggleFavorite}
                >
                    {!isFavorite ? getLabel('ui.favorite') : getLabel('ui.unfavorite')}
                </Button>
            </Grid>
            <Grid item className={classes.alignText}>
                <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    startIcon={<VideoCallIcon/>}
                    component={RouterLink}
                    to={{
                        pathname: '/soles/virtual',
                        search: `?question=${question.id}`
                    }}
                >
                    {getLabel('ui.question.virtual_sole')}
                </Button>
            </Grid>
            <HidableComponent isVisible={(pub?.roles?.includes('tester_search') ?? false) && question.id === 'v2YkxGGFyG'}>
                <Grid item className={classes.alignText}>
                    <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        startIcon={<SearchIcon/>}
                        onClick={() => { createQuickstartSole() }}
                    >
                        {getLabel('ui.search')}
                    </Button>
                </Grid>
            </HidableComponent>
        </Grid>
        <Container maxWidth="md" className={classes.resourceContainer}>
            <QuestionResources question={question}/>
        </Container>
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            open={open}
            autoHideDuration={4000}
            onClose={handleClose}
            message={snackBarMessage}
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
            }
        />
    </Grid>);
};

export default SingleQuestion;
