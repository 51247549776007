import {IQuestion} from "@startsole/startsole-types/src/solejson";
import {SetStateAction, useEffect, useState} from "react";
import Parse from "parse";

const useFetchQuestion = (questionId?: string): [
        IQuestion | undefined, // question
        boolean, // isLoading
        string, // errorMessage
        (questionId: string)=>void, // fetchQuestion
        (value: SetStateAction<IQuestion | undefined>) => void // setQuestion
] => {
    const [question, setQuestion] = useState<IQuestion | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(questionId !== undefined);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        if (questionId) {
            fetchQuestion(questionId).then();
        }
    }, [questionId]);

    const fetchQuestion = async (questionId: string) => {
        try {
            const question = await Parse.Cloud.run('webapp.getQuestionByIdNew', {
                id: questionId
            }, {
                sessionToken: Parse.User.current()?.getSessionToken()
            });
            setIsLoading(false);
            setQuestion(question);
        } catch (error:any) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    };

    return [question, isLoading, errorMessage, fetchQuestion, setQuestion];
};

export default useFetchQuestion;
