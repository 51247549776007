import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {SidebarMenuList} from "./SidebarMenuListComponent";
import {Breadcrumbs, Button, Link, Typography} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import {getRingProps, IRingProps} from "../theme/RingProps";
import {selectSessionContext} from "../redux/sessionSlice";
import {useAppSelector} from "../redux/reduxHooks";
import {selectPub} from "../redux/userSlice";
import {useTranslationLabel} from "../hooks/useTranslation";

const drawerWidth = 240;

const useStyles = makeStyles<Theme, IRingProps>((theme: Theme) => {
        return {
            root: {
                display: 'flex',
                backgroundColor: 'yellow'
            },
            logo: {
                ...theme.mixins.toolbar,
                backgroundImage: (props) => props.logoUrl,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundOrigin: 'content-box',
                padding: theme.spacing(1)
            },
            drawer: {
                [theme.breakpoints.up('md')]: {
                    width: drawerWidth,
                    flexShrink: 0
                }
            },
            miniDrawer: {
                [theme.breakpoints.up('md')]: {
                    flexShrink: 0
                }
            },
            appBar: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth
                }
            },
            menuButton: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none'
                }
            },
            toolbar: theme.mixins.toolbar,
            drawerPaper: {
                width: drawerWidth
            },
            content: {
                flexGrow: 1,
                padding: theme.spacing(3)
            },
            rightToolbar: {
                marginLeft: 'auto'
            },
            lookLikeALink: {
                cursor: 'pointer'
            },
            breadcrumbs: {
                width: '100%'
            }
        };
    }
);

const ResponsiveDrawer: React.FC = () => {
    const pub = useAppSelector(selectPub)!;
    const theme = useTheme();
    const classes = useStyles(getRingProps(pub));
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const sessionContext = useAppSelector(selectSessionContext);
    const getLabel = useTranslationLabel();

    useEffect(() => {
        const setBreadCrumbsWidth = () => {
            let separatorSize = 0;
            const numItems = sessionContext.length;
            if (numItems > 1) {
                const separatorItem = document.querySelectorAll<HTMLElement>('.MuiBreadcrumbs-separator')[0];
                separatorSize = separatorItem.offsetWidth * (numItems - 1) + 1;
            }
            const breadcrumbs = document.getElementById("breadcrumbs");
            const offsetWidth = breadcrumbs?.offsetWidth ?? 0;
            const maxBreadcrumWidth = `${String((offsetWidth - separatorSize) / numItems)}px`;
            for (const e of document.querySelectorAll<HTMLElement>('.breadcrumbsItem')) {
                e.style.maxWidth = maxBreadcrumWidth;
            }
        };

        setBreadCrumbsWidth();
        window.addEventListener('resize', setBreadCrumbsWidth);
        return () => {
            window.removeEventListener('resize', setBreadCrumbsWidth);
        };
    }, [sessionContext]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (<div>
        <Link component={RouterLink} to="/">
            <div className={classes.logo}/>
        </Link>
        <Divider/>
        <SidebarMenuList/>
        <Divider/>
    </div>);

    return (<div>
    <CssBaseline/>
    <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
            >
                <MenuIcon/>
            </IconButton>

            <span className={classes.breadcrumbs} id="breadcrumbs">
                <Breadcrumbs maxItems={3}>
                    {
                        sessionContext.map((item, index) => {
                            return (<Link
                                key={`breadcrumb-${index}`}
                                style={{color: 'white'}}
                                onClick={item.callback}
                                className={classes.lookLikeALink}
                            >
                                <Typography className="breadcrumbsItem" noWrap>{item.text}</Typography>
                            </Link>)
                        })
                    }
                </Breadcrumbs>
            </span>

                <Button
                    className={classes.rightToolbar}
                    color="inherit"
                    component={RouterLink}
                    to="/logout"
                >
                    {getLabel('ui.logout')}
                </Button>
            </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    </div>);
};

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element)
};

export default ResponsiveDrawer;
