import {Tag} from "../data/Tag";
import {IUserPub} from "./soleTypes";
import {useTranslationLabel} from "../hooks/useTranslation";

export const getAllSubjects = (pub: IUserPub): Tag[] => {
    let subjects: Tag[];
    if (pub.rings?.find((ring) => ring.rdn === 'us.pa')) {
        subjects = allSubjectsPa;
    } else if (pub.rings?.find((ring) => ring.rdn === 'co')) {
        subjects = allSubjectsCo;
    } else {
        subjects = allSubjects;
    }

    const result = [...subjects];
    if (pub.rings?.find((ring) => ring.rdn === 'co')) {
        result.unshift({short: 'Todas las materias', rdn: 'all'});
    } else {
        result.unshift({short: 'All Subjects', rdn: 'all'});
    }
    return result;
};

const allSubjects: Tag[] = [
// { short: "AmeriCorps", rdn: "asn.d0000003" },
// { short: "Community Health Worker Certification", rdn:"asn.d0000002" },
    {short: "Business and Administrative Services, Finance and Marketing", rdn: "asn.d0000010", subject:"top.careereducation"},
    {short: "College Now", rdn: "asn.d0000001", subject:"top.careereducation"},
    {short: "Dance", rdn: "asn.d2466234", subject:"top.thearts"},
    {short: "Drama/Theatre", rdn: "asn.d2466454", subject:"top.thearts"},
    {short: "English Language Arts (Common Core)", rdn: "asn.d10003fc", subject:"top.english"},
    {short: "Engineering Content Standards", rdn: "asn.d0000006", subject:"top.engineering"},
    {short: "Education and Training Content Standards", rdn: "asn.d0000007", subject:"top.careereducation"},
    {short: "Financial Literacy", rdn: "asn.d0000011", subject:"top.financialliteracy"},
    {short: "Health Sciences", rdn: "asn.d0000009", subject:"top.careereducation"},
    {short: "Hospitality and Tourism", rdn: "asn.d0000008", subject:"top.careereducation"},
    {short: "HRSA (ACT test)", rdn: "asn.d0000004", subject:"top.health"},
    {short: "Mathematics (Common Core)", rdn: "asn.d10003fb", subject:"top.math"},
    {short: "Music", rdn: "asn.d2482974", subject:"top.thearts"},
    {short: "Next Generation Science Standards", rdn: "asn.d2454348", subject:"top.science"},
    {short: "Physical Education", rdn: "asn.d2716034", subject:"top.physicaleducation"},
    {short: "Science (High School)", rdn: "asn.d2692656", subject:"top.science"},
    {short: "Science (K-8)", rdn: "asn.d2462762", subject:"top.science"},
    {short: "Social Studies (High School)", rdn: "asn.d2463176", subject:"top.socialstudies"},
    {short: "Social Studies (K-8)", rdn: "asn.d2462866", subject:"top.socialstudies"},
    {short: "Technology", rdn: "asn.d2465259", subject:"top.technology"},
    {short: "Visual Arts", rdn: "asn.d2483255", subject:"top.thearts"},
    {short: "World Languages", rdn: "asn.d2483559", subject:"top.foreignlanguage"}
];

// TODO: can we make empty subject documents that are like asn.d000xxxx that are basically just top.careereducation/whatever?  This way selects would still work I think
const allSubjectsCo: Tag[] = [
    {short: "Negocios, Finanzas y Marketing", rdn: "asn.d0000010", subject:"top.careereducation"},
    {short: "Danza", rdn: "asn.d2466234", subject:"top.thearts"},
    {short: "Teatro", rdn: "asn.d2466454", subject:"top.thearts"},
    {short: "Artes del lenguaje inglés", rdn: "asn.d10003fc", subject:"top.english"},
    {short: "Ingeniería", rdn: "asn.d0000006", subject:"top.engineering"},
    {short: "Educación", rdn: "asn.d0000007", subject:"top.careereducation"},
    {short: "Educación financiera", rdn: "asn.d0000011", subject:"top.financialliteracy"},
    {short: "Ciencias de la Salud", rdn: "asn.d0000009", subject:"top.careereducation"},
    {short: "Hospitalidad y Turismo", rdn: "asn.d0000008", subject:"top.careereducation"},
    {short: "Matemáticas", rdn: "asn.d10003fb", subject:"top.math"},
    {short: "Música", rdn: "asn.d2482974", subject:"top.thearts"},
    {short: "Ciencias", rdn: "asn.d2454348", subject:"top.science"},
    {short: "Educación Física", rdn: "asn.d2716034", subject:"top.physicaleducation"},
    {short: "Estudios Sociales", rdn: "asn.d2463176", subject:"top.socialstudies"},
    {short: "Tecnología", rdn: "asn.d2465259", subject:"top.technology"},
    {short: "Artes visuales", rdn: "asn.d2483255", subject:"top.thearts"},
    {short: "Idiomas del mundo", rdn: "asn.d2483559", subject:"top.foreignlanguage"}
];

const allSubjectsPa: Tag[] = [
    {short: "English Language Arts (Common Core)", rdn: "asn.d10003fc", subject:"top.english"},
// { short: "English Language Arts (PreK-5)", rdn: "asn.d2562530" },
// { short: "English Language Arts (6-12)", rdn: "asn.d2562969" },
    {short: "Mathematics (PreK-12)", rdn: "asn.d2563472", subject:"top.math"},
// { short: "Environment and Ecology (PreK-3)", rdn: "asn.d2581951" },
    {short: "Environment and Ecology", rdn: "asn.d2382283", subject:"top.science"},
    {short: "Science and Technology and Engineering Education (PreK-3)", rdn: "asn.d2381762", subject:"top.science"},
    {short: "Science and Technology and Engineering Education (Elementary)", rdn: "asn.d2382902", subject:"top.science"},
    {short: "Science and Technology and Engineering Education (Secondary)", rdn: "asn.d2381498", subject:"top.science"},
    {short: "Next Generation Science Standards", rdn: "asn.d2454348", subject:"top.science"},
    {short: "Arts and Humanities", rdn: "asn.d10002dc", subject:"top.thearts"},
    {short: "Civics and Government (PreK-3)", rdn: "asn.d2384125", subject:"top.civics"},
    {short: "Civics and Government (Elementary)", rdn: "asn.d2384126", subject:"top.civics"},
    {short: "Civics and Government (Secondary)", rdn: "asn.d2384127", subject:"top.civics"},
    {short: "CSTA CS Standards", rdn: "asn.d0000005", subject:"top.technology"},
    {short: "Economics (PreK-3)", rdn: "asn.d2384140", subject:"top.economics"},
    {short: "Economics (Elementary)", rdn: "asn.d2384141", subject:"top.economics"},
    {short: "Economics (Secondary)", rdn: "asn.d2384142", subject:"top.economics"},
    {short: "Geography (PreK-3)", rdn: "asn.d2385459", subject:"top.geography"},
    {short: "Geography (Elementary)", rdn: "asn.d2385476", subject:"top.geography"},
// { short: "Geography (Secondary)", rdn: "asn.d2385492", subject:"top.geography"},
    {short: "History (PreK-3)", rdn: "asn.d2385504", subject:"top.top.historicalunderstanding"},
    {short: "History (Elementary)", rdn: "asn.d2385514", subject:"top.historicalunderstanding"},
    {short: "History (Secondary)", rdn: "asn.d2385533", subject:"top.historicalunderstanding"},
// { short: "Health, Safety & Physical Education", rdn: "asn.d2386177" },
// { short: "Career Education and Work", rdn: "asn.d2400024" },
// { short: "Family and Consumer Sciences", rdn: "asn.d2400029" },
// { short: "Reading for Science and Technical Subjects (6-12)", rdn: "asn.d2529702" },
// { short: "Reading for History and Social Studies (6-12)", rdn: "asn.d2529738" },
    {short: "Writing for History and Social Studies (6-12)", rdn: "asn.d2562464", subject:"top.english"}
// { short: "Writing for Science and Technical Subjects (6-12)", rdn: "asn.d2562497" },
// { short: "Business, Computer and Information Technology", rdn: "asn.d2659715" },
];
