import {IQuestion} from "@startsole/startsole-types/src/solejson";
import {StandardPickerState} from "../standards/StandardPickerComponent";
import React, {useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import {Card, CardActionArea, CardContent, Typography} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import {HidableComponent} from "../hoc/Hidable";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.secondary.main,
            breakInside: 'avoid',
            pageBreakInside: 'avoid',
            webkitColumnBreakInside: 'avoid',
            marginBottom: '12px'
        },
        white: {
            color: 'white'
        },
        searchIcon: {
            color: 'white'
        }
    })
);

interface QuestionCardProps {
    question: IQuestion;
    pickerState?: StandardPickerState;
}

const QuestionCard: React.FC<QuestionCardProps> = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const question = props.question;
    const link = '/question';
    return (<Card variant="elevation"
                 raised={expanded}
                 className={classes.root}
                 onMouseEnter={(e) => {setExpanded(true)}}
                 onMouseLeave={(e) => {setExpanded(false)}}
                 elevation={expanded ? 6 : 0}
    >
        <CardActionArea component={RouterLink} to={{
            pathname: link,
            state: {
                question: question,
                pickerState: props.pickerState
            }
        }}>
            <CardContent>
                <Typography variant="h6" align="center" className={classes.white}>
                    {question.text}
                </Typography>
                <HidableComponent isVisible={question.id === 'v2YkxGGFyG'}>
                    <SearchIcon fontSize="small" className={classes.searchIcon}/>
                </HidableComponent>
            </CardContent>
        </CardActionArea>
    </Card>);
};

export default QuestionCard;
