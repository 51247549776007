import React, {useEffect} from 'react';
import {match, Redirect} from "react-router-dom";
import {Typography} from "@material-ui/core";
import useFetchSole from "../../../hooks/useFetchSole";
import Loading from "../../LoadingComponent";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import useSaveSole from "../../../hooks/useSaveSole";
import Error from "../../ErrorComponent";

interface Identifiable {
    id: string;
}

interface CopyProps {
    match?: match<Identifiable>;
}

/**
 * Takes a SOLE id and retrieves the SOLE
 * Then if it fetches the SOLE creates a new SOLE object with no ID and an updated tag
 * Then saves the new SOLE and redirect as appropriate, displaying loading/copying text while working.
 */
const Copy: React.FC<CopyProps> = (props) => {
    const getLabel = useTranslationLabel();
    const soleId = props.match?.params.id || '';
    const [sole, isLoadingSole, soleErrorMessage, fetchSole] = useFetchSole(undefined);
    const [saveSole, savedSole, isSavingSole, saveSoleErrorMessage] = useSaveSole();


    useEffect(() => {
        if (soleId) {
            fetchSole(soleId);
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (sole) {
                const newSole = {
                    ...sole,
                    tag: sole.tag ? (`${sole.tag}- ${getLabel('ui.copy')}`) : getLabel('ui.copy')
                };
                // remove the id so it gets saved as a new SOLE
                delete newSole.id;
                await saveSole(newSole);
            }
        })();
    }, [sole]);

    if (isLoadingSole) {
        return <Loading rdn="ui.loading"/>
    }
    if (isSavingSole) {
        return <Loading rdn="ui.soles.copying_sole"/>
    } else if (savedSole) {
        return <Redirect to="/soles/"/>
    } else if (soleErrorMessage) {
      return <Error/>
    } else {
        return (<Typography>
            {soleId}
            {sole?.question?.text}
        </Typography>);
    }
};

export default Copy;
