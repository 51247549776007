import React, {useState} from "react";
import {useAppSelector} from "../../redux/reduxHooks";
import {StringConstraints} from "../../data/constraints/Constraints";
import TextField from "@material-ui/core/TextField";
import {selectConstraints} from "../../redux/constraintSlice";

interface StringProps {
    initialValue?: string;
    label: string;
    rdn: string;
    onChange: (value: string | undefined) => void;
    lines?: number;
    constraint?: StringConstraints;
}

const StringComponent: React.FC<StringProps> = (props) => {
    const [value, setValue] = useState<string | undefined>(props.initialValue);
    const constraints = useAppSelector(selectConstraints);

    const constraint = props.constraint ? props.constraint : props.rdn ? constraints.get(props.rdn) : undefined;
    if (constraint instanceof StringConstraints) {
        if (value === undefined) {
            // setValue(constraint.defaultValue);
        }
        return (<TextField
            type="text"
            margin="normal"
            required={constraint.isRequired}
            fullWidth
            id={props.rdn}
            label={props.label}
            name={props.rdn}
            multiline={(props.lines ?? 1) > 1}
            rows={props.lines ?? 1}
            autoFocus={false}
            value={value}
            onChange={(event: any) => {
                let v: string | undefined = undefined;
                if (event.target.value) {
                    v = event.target.value;
                }
                setValue(v);
                props.onChange(v);
            }}
        />);
    } else {
        return <div/>;
    }
};

export default StringComponent;
