import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../TabPanel";
import {useTranslationLabel} from "../../hooks/useTranslation";
import {useAppDispatch, useAppSelector} from "../../redux/reduxHooks";
import QuestionCards from "./QuestionCardsComponent";
import {useSessionContext} from "../../hooks/useSessionContext";
import Error from "../ErrorComponent";
import {loadMyQuestions} from "../../redux/myQuestionsSlice";

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    }
});

const QuestionsMine: React.FC = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const dispatch = useAppDispatch();
    const getLabel = useTranslationLabel();
    const myQuestions = useAppSelector((state) => state.myQuestions.myQuestions);
    const myFavoriteQuestions = useAppSelector((state) => state.myQuestions.myFavoriteQuestions);
    const errorMessage = useAppSelector((state) => state.myQuestions.errorMessage);
    const isLoading = useAppSelector((state) => state.myQuestions.isLoading);
    useSessionContext([
        {
            callback(): void { },
            text: getLabel('ui.my_questions')!
        }
    ]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(loadMyQuestions());
    }, []);

    const renderMyQuestions = () => {
        if (myQuestions && myQuestions.length > 0) {
            return <QuestionCards questions={myQuestions}/>;
        } else if (isLoading) {
            return <div/>;
        } else if (errorMessage) {
            return <Error text={errorMessage}/>
        }
    };

    const renderMyFavoriteQuestions = () => {
        if (myFavoriteQuestions && myFavoriteQuestions.length > 0) {
            return <QuestionCards questions={myFavoriteQuestions}/>;
        } else if (isLoading) {
            return <div/>;
        } else if (errorMessage) {
            return <Error text={errorMessage}/>
        }
    };

    return (<div className={classes.root}>
        <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
        >
            <Tab label={ getLabel('ui.questions.mine') }/>
            <Tab label={ getLabel('ui.questions.saved') }/>
        </Tabs>
        <TabPanel value={value} index={0}>
            {renderMyQuestions()}
        </TabPanel>
        <TabPanel value={value} index={1}>
            {renderMyFavoriteQuestions()}
        </TabPanel>
    </div>)
};

export default QuestionsMine;
