import {SoleText} from "../data/SoleText";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import clientTexts from "../shared/clientTexts";

export interface TextState {
    isLoading: boolean;
    errorMessage?: string;
    texts: SoleText[];
}

let texts: SoleText[] = [];

// Check if texts are stored in the local storage
const textsJson = localStorage.getItem('texts') as string;
if (textsJson) {
    try {
        texts = JSON.parse(textsJson);
    } catch (error: any) {
        // ignore error
    }
}

// Append client texts
clientTexts.forEach((text) => {
    const index = texts.findIndex((t) => t.rdn === text.rdn && t.language === text.language);
    if (index >= 0) {
        texts[index] = text;
    } else {
        texts.push(text);
    }
});

const initialState: TextState = {
    isLoading: false,
    errorMessage: undefined,
    texts: texts
};

export const textSlice = createSlice({
    name: 'text',
    initialState,
    reducers: {
        loadText: (state) => {
            // handled by saga
        },
        textLoading: (state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        },
        textLoadingFailed: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        },
        textLoadingFinished: (state) => {
            state.isLoading = false;
        },
        setTexts: (state, action: PayloadAction<SoleText[]>) => {
            state.isLoading = false;
            state.errorMessage = undefined;
            state.texts = action.payload;
        }
    }
});

export const {loadText, textLoading, textLoadingFailed, textLoadingFinished, setTexts} = textSlice.actions;
export const selectTexts = (state: RootState): SoleText[] => state.text.texts;

export default textSlice.reducer;
