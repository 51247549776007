import React, {useEffect} from "react";
import {match} from "react-router-dom";
import useFetchSole from "../../../hooks/useFetchSole";
import Loading from "../../LoadingComponent";
import Error from "../../ErrorComponent";
import PlanReflect from "./PlanReflectComponent";
import {SoleStep} from "./SoleStepper";

interface Props {
    steps: SoleStep[];
    match: match<{ id: string }>;
}

const SoleLoader: React.FC<Props> = (props) => {
    const [sole, isLoadingSole, soleErrorMessage, fetchSole] = useFetchSole(undefined);

    useEffect(() => {
        const soleId = props.match.params.id ?? "";
        if (soleId.length > 0) {
            fetchSole(soleId);
        }
    }, []);

    if (isLoadingSole) {
        return <Loading rdn="ui.loading"/>;
    } else if (soleErrorMessage) {
        return <Error text={soleErrorMessage}/>
    } else if (sole !== undefined) {
        return <PlanReflect steps={props.steps} sole={sole}/>
    } else {
        return <div/>;
    }
};

export default SoleLoader;
